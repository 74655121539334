import styled, { css } from 'styled-components';

import { base, screens, colors } from '../../../../styles';

export const ContentPersonal = styled.div`
    max-width: ${base * 78}px;
`;

export const InfoTitle = styled.div`
    max-width: ${base * 25}px;
    font-size: 32px;
    color: #000000;
    span{
        margin-right: ${base - 6}px;
        &:last-child{
            color: ${colors.primary_color};;
        }
    }
    @media (max-width: ${screens.screen_xs_max}) {
      margin-bottom: 24px;
    }
`;

export const InfoMain = styled.div`
    max-height: ${base * 53.75}px;
    padding: 46px;
    background: #FFFFFF;
    box-shadow: 1px 1px 32px rgba(0, 0, 0, 0.05);
    border-radius: ${base - 6}px;
    @media (max-width: ${screens.screen_xs_max}) {
      max-height: ${base * 90.75}px;
      padding: 24px;
    }
`;

export const Header = styled.div`
    margin-bottom: 28px;
`;

export const UserAvatar = styled.div`
    height: 42px;
    width: 42px;
    background-color: ${colors.primary_gray};
    background: url(${props => props.avatar});
    background-size: cover;
    background-position: 50%;
    margin: auto;
    margin-bottom: 12px;
    height: 154px;
    width: 154px;
    border-radius: 50%;
    margin-top: -64px;
    margin-bottom: 32px;
    box-shadow: 0 0 20px rgba(0, 81, 130, 0.06);
    border: 1px solid #f5f5f5;
`;

export const OptionProfile = styled.div`
    display: flex;
    justify-content: center;
`;

export const DownloadAvatar = styled.div`
    width: 32px;
    height: 32px;
    background: url(${require('../../../../assets/icons/baseline-cloud_upload-24px.svg')});
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 1px solid ${colors.primary_red};
    border-radius: 50%;
    margin-right: ${base + 4}px;
    input[type="file"] {
        width: 32px;
        height: 32px;
        opacity: 0;
        cursor: pointer;
    }
`;

export const DeleteAvatar = styled.div`
    width: 32px;
    height: 32px;
    background: url(${require('../../../../assets/icons/Frame2.4.svg')});
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 1px solid ${colors.primary_red};
    border-radius: 50%;
`;

export const Main = styled.div`
    display: flex;
    @media (max-width: ${screens.screen_xs_max}) {
      flex-direction: column;
    }
`;

export const InputBorder = styled.div`
    border-radius: 6px;
    border: 1px solid #9B9B9B;

    ${props => !props.valid && css`
        border: 1px solid ${colors.primary_red};
    `}
`;

export const CommonBlock = styled.div`
    width: 100%;
    div {
        margin-bottom: 36px;
        background-color: white;
        border: 1px solid ${colors.white_smoke};
        border-radius: 12px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    input{
        box-sizing: border-box;
        font-size: 18px;
        border-radius: 12px;
    }
`;

export const LeftBlockInfo = styled(CommonBlock)`
    margin-right: 40px;
    @media (max-width: ${screens.screen_xs_max}) {
      margin-bottom: 36px;
    }
`;

export const RightBlockInfo = styled.div`
    width: 100%;
    div{
        margin-bottom: 36px;
        background-color: white;
        border: 1px solid ${colors.white_smoke};
        border-radius: 12px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    input{
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 18px;
    }
`;

export const TitleInfoProgile = styled.p`
    font-size: 14px;
    color: #ACACAC;
    margin-bottom: ${base - 6}px;
`;

export const InfoHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 46px;
    @media (max-width: ${screens.screen_xs_max}) {
      flex-direction: column;
    }
`;

export const PopupSaveResult = styled.div`
    min-width: 324px;
    max-width: 594px;
    div{
        position: fixed;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;
        transition: bottom .3s ease-in-out;
        bottom: 20px;
        border-radius: 8px;
        font-size: 20px;
    }
`;
