import styled from 'styled-components';

import { base } from '../../styles';

export const LogoWrapper = styled.div`
  height: 42px;
  cursor: pointer;
  background-image: url(${require('../../assets/icons/logista.png')});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;

`;
