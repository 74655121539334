import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const Box = styled.form`
  height: calc(100vh - 127px);
  width: 400px;
  background-color: #fff;
  padding: 20px 0;
  margin-top: 87px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100vw;
  }
  ${props => props.modaleWindow && css`
    padding: 0;
    margin-top: 24px;
    align-items: center;
  `}
`;

export const Top = styled.div`
  display: flex;
  padding: 0 36px;
  border-left: 5px solid ${colors.primary_red};
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`;

export const ListBlock = styled.div`
  height: 322px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const TextArea = styled.textarea`
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: 8px;
  font-size: 12px;
  color: ${colors.light_gray};
  padding: 12px 16px;
  resize: none;
  outline: none;
  width: calc(100% - 32px);
  height: 84px;
`;

export const InfoBlock = styled.div`
  margin-top: 18px;
  border: 1px solid ${colors.primary_red};
  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  padding: 3px 40px;
  position: relative;
  flex-direction: row;
  display: flex;
  p {
    font-size: 18px;
    color: ${colors.black};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0.75;
  }
  ${props => props.withoutHidden && css`
    display: block;
    p {
      overflow: visible;
      white-space: normal;
    }
  `}
  &:hover {
    background-color: ${colors.white_smoke};
  }
`;

export const Bottom = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 28px;
  width: 100%;
  padding: 0 32px;
  width: calc(100% - 64px);
  button {
    display: inline;
    margin-top: 12px;
    margin-right: 12px;
  }
  button:last-child {
    margin-right: 0;
  }
  @media (max-width: ${screens.screen_xs_max})  {
    bottom: 64px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FullInfo = styled.p`
  display: none;
  position: absolute;
`;

export const Tabs = styled.p`
  flex-direction: row;
  padding: 0 40px;
`;

export const Tab = styled.button`
  margin-right: 72px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: ${colors.light_gray};
  outline: none;
  &:hover {
    color: ${colors.primary_color};
  }
  &:active {
    color: ${colors.primary_color};
    outline: none;
    border: none;
  }
  ${props => props.active && css`
    color: ${colors.primary_color};
  `}
`;

export const FromList = styled.div`
  padding: 0 40px;
  margin: 18px 0 18px;
`;

export const Content = styled.div`
  height: calc(80% - 78px);
  overflow-y: auto;
  position: absolute;
  width: 100%;
  ${props => props.theme.needPadding && css`
    padding-left: ${base * 37}px;
  `}
  right: 0;
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 32px;
  font-weight: 300;
  ${props => props.smallParagraph && css`
    font-size: 18px;
    color: ${colors.light_gray};
    padding: 0 40px 2px 40px;
  `}
`;
