import styled from 'styled-components';

import { base, colors } from '../../styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  font-weight: 900;
`;

export const InputMargin = styled.div`
  margin-bottom: 20px;
  width: 100%;
  border-radius: 0;
`;

export const LoginP = styled.p`
  color: ${colors.light_gray};
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 12px;
  margin-left: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 32px;
  box-shadow: 0 1px 0 0 rgba(0,120,255,.0375), 0 6px 16px 0 rgba(0,73,164,.055);

  input {
    border-radius: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    background-color: ${colors.white_smoke};
    padding-left: 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
  width: 100%;

  button {
    transition: all 0.3s ease-in-out;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    &:hover {
      box-shadow: 0 0 12px rgba(30, 171, 255, 0.5);
    }
  }

  @media (max-width: 800px) {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const FormHeading = styled.h4`
  margin: 0;
  color: ${colors.black};
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
  margin-top: -3px;
`;
