/**
 * @flow
 */

import React from 'react';

import {
  SelectItem,
} from './SelectDropDownItemStyled';

type SelectDropDownItemPropsType = {
    link?: string,
    value?: string,
    title?: string,
    clickHandler: (any) => any,
    selectOption: () => void,
};

export default function SelectDropDownItem(props: SelectDropDownItemPropsType) {
  return (
    <SelectItem
      link={props.link}
      value={props.value}
      onClick={() => {
        props.clickHandler();
        props.selectOption();
      }}
    >
      {props.title}
    </SelectItem>
  );
}

SelectDropDownItem.defaultProps = {
  link: null,
  value: null,
  title: null,
};
