import React from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {
  Calendar,
  Button,
  Input,
  Popup,
  ModaleWindow,
  Rating,
  SelectStatuses,
} from '../../../components';

import IconYesNoView from './../../../components/IconYesNo/IconYesNoView';

import {PopupMessage} from '../Directories/DirectoriesStyled';

import {
  Form,
  ControlContainer,
  FiltersContainer,
  TableContainer,
  CalendarContainer,
  TitleContainer,
  TitleButton,
  FilterButton,
  CalendarButton,
  InputDate,
  InputSearch,
  InputTable,
  FiltersValueContainer,
  FiltersValueTitle,
  FiltersValue,
  FiltersValueDelete,
  DateFiltersValueDelete,
  Select,
  ValueOptions,
  OptionValues,
  MobileInputSearch,
  Heading,
  Loaders,
  ExpandButtonWrapper,
  IconButtonWrapper,
  Textarea,
  ModalContentWrapper,
} from './RequestTableStyles';

type RequestTablePropsType = {
  availableFields: Object,
  viewCalendar: boolean,
  showRatingModal: boolean,
  isReviewSending: boolean,
  ratingReview: String,
  clickCalendar: () => void,
  setViewCalendar: (any) => void,
  changeDateRange: (any) => void,
  dateFilters: string,
  dateFiltersActive: boolean,
  deleteDateFilter: () => void,
  filters: Object,
  applyFilters: () => void,
  changeFilters: (any, any) => void,
  selectedFiltersValue: (any, any) => void,
  deleteFilter: (any) => void,
  search: (any) => void,
  selectedRequests: Array<String>,
  typeFields: Object,
  showAutocompleteValues: Object,
  autocompleteValues: Object,
  getExcel: (any) => void,
  addRequest: () => void,
  deleteRequests: () => void,
  cancel: () => void,
  filtersActivePoint: boolean,
  isLoading: boolean,
  expand: boolean,
  setExpand: (boolean) => void,
  copyRequest: (string) => void,
  access: Object,
  setRating: (String, Number) => void,
  setRatingReview: (String) => void,
  readOnlyRating: () => void,
  sendReview: (any) => void,
  closeReviewModal: () => void,
  selectStatus: (any) => void,
  rangeDate: Array,
  setRangeDate: (any) => void,
  postRequest: (any) => void,
};

const statusBar = {
  statusPanels: [
    {
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "left"
    },
    {
      statusPanel: "agTotalRowCountComponent",
      align: "center"
    },
    { statusPanel: "agFilteredRowCountComponent" },
    { statusPanel: "agSelectedRowCountComponent" },
    { statusPanel: "agAggregationComponent" }
  ]
};

const localeText = {
  // for filter panel
  loadingOoo: 'Загрузка...',
  to: 'до',
  of: 'из',
  // for set filter
  selectAll: 'Выбрать все',
  searchOoo: 'Поиск...',
  // for number filter and text filter
  filterOoo: 'Фильтровать...',
  applyFilter: 'Применить фильтр',
  resetFilter: 'Сбросить фильтр',
  clearFilter: 'Очистить фильтр',
  equals: 'Равно',
  notEqual: 'Не равно',
  // for number filter
  lessThan: 'Меньше чем',
  greaterThan: 'Больше чем',
  lessThanOrEqual: 'Меньше чем или равно',
  greaterThanOrEqual: 'Больше чем или равно',
  inRange: 'В диапазоне',
  // for text filter
  contains: 'Содержит',
  notContains: 'Не содержит',
  startsWith: 'Начинается с',
  endsWith: 'Заканчивается чем',
  // filter conditions
  andCondition: 'И',
  orCondition: 'ИЛИ',
  // enterprise menu aggregation and status bar
  sum: 'Сумма',
  min: 'Минимум',
  max: 'Максимум',
  none: 'Нет',
  count: 'Количество',
  average: 'Среднее',
  filteredRows: 'Отфильтрованно строк',
  selectedRows: 'Выбрано строк',
  totalRows: 'Всего строк',
  totalAndFilteredRows: 'Строк',
  // other
  noRowsToShow: 'Нет данных...',
};

export default function RequestTableView(props: RequestTablePropsType) {
  const filters = [];
  const autocompleteValues = {};
  let dateFilters = '';
  // генерация автокомплитов фильтров
  Object.keys(props.autocompleteValues).forEach((field) => {
    autocompleteValues[field] = [];
    props.autocompleteValues[field].forEach((value) => {
      let data = '';
      Object.keys(value).forEach((key) => {
        if (key !== '_id') {
          if (key !== 'id') {
            if (value[key]) {
              if (!Array.isArray(value[key])) {
                data += value[key];
                data += ' ';
              }
            }
          }
        }
      });
      autocompleteValues[field].push(
        <OptionValues
          key={value.id || value._id}
          onClick={() => props.selectedFiltersValue(field, value)}
        >
          {data}
        </OptionValues>,
      );
    });
  });
  // генерация строки фильтрации
  if (Object.keys(props.filters).length !== 0) {
    Object.keys(props.filters).forEach((field) => {
      switch (props.typeFields[field].type) {
        case 'input': {
          let inputTextValue = '';
          if (props.filters[field]) {
            if (props.typeFields[field].inputType === 'date') {
              const dateSplit = props.filters[field].split('-');
              if (dateSplit.length === 3) {
                inputTextValue = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
              }
            } else if (props.typeFields[field].inputType === 'number') {
              inputTextValue = `${props.filters[field]}`.replace(',', '.') || '';
            } else if (field === 'weight' || field === 'numberSeats') {
              inputTextValue = props.filters[field] || '';
            }
          }
          filters.push(
            <FiltersValueContainer key={field}
               style={{width: 'auto'}}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Input
                  type="text"
                  value={inputTextValue}
                  changed={(value) => {
                    if (props.typeFields[field].inputType === 'date') {
                      const [year, month, day] = value.split('-');
                      value = `${day}-${month}-${year}`;
                    }
                    props.changeFilters(field, value);
                  }}
                  disabled={props.typeFields[field].inputType === 'date' || props.typeFields[field].inputType === 'number'}
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'select': {
          let data = props.filters[field];
          const options = [];
          if (!data) {
            data = '';
          }
          props.typeFields[field].values.forEach((value) => {
            options.push(<option key={value}>{value}</option>);
          });
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Select
                  defaultValue={data}
                  onChange={event => props.changeFilters(field, event.target.value)}
                >
                  {options}
                </Select>
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'autocomplete': {
          let data = '';
          if (props.filters[field]) {
            if (typeof props.filters[field] === 'object') {
              Object.keys(props.filters[field]).forEach((key) => {
                if (key !== '_id') {
                  if (key !== 'id') {
                    if (!Array.isArray(props.filters[field][key])) {
                      data += props.filters[field][key];
                      data += ' ';
                    }
                  }
                }
              });
            } else {
              data = props.filters[field];
            }
          }
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Input
                  type="text"
                  value={data}
                  changed={value => props.changeFilters(field, value)}
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
                {(props.showAutocompleteValues[field])
                  ? (
                    <ValueOptions>
                      {(props.autocompleteValues[field]) ? autocompleteValues[field] : null}
                    </ValueOptions>
                  )
                  : null}
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'checkbox': {
          const data = props.filters[field];
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <input
                  type="checkbox"
                  checked={data}
                  onChange={event => props.changeFilters(field, event.target.checked)}
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
                {(props.showAutocompleteValues[field])
                  ? (
                    <ValueOptions>
                      {(props.autocompleteValues[field]) ? autocompleteValues[field] : null}
                    </ValueOptions>
                  )
                  : null}
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        default:
          break;
      }
    });
  }
  // генерация строки диапазона дат
  if (props.dateFilters !== '') {
    try {
      const date = JSON.parse(props.dateFilters);
      date[0] = date[0].split('-');
      date[1] = date[1].split('-');
      dateFilters = `${date[0][2]}.${date[0][1]}.${date[0][0]}`;
      dateFilters += ' - ';
      dateFilters += `${date[1][2]}.${date[1][1]}.${date[1][0]}`;
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Form className="main-dashboard-form">
      <ControlContainer>
        <TitleContainer>
          <Heading>Заявки</Heading>
          {props.access.directory.requests.insert ? (
            <TitleButton>
              <Button
                circle
                clicked={() => {
                  props.addRequest();
                  }
                }
                plus
                title="Добавить"
              />
            </TitleButton>
          ) : null}
          {Object.keys(props.filters).length ? (
            <TitleButton filtersActivePoint={props.filtersActivePoint}>
              <Button
                circle
                clicked={() => props.applyFilters()}
                title="Фильтрация"
              >
                <img
                  style={{ width: '14px' }}
                  src={require('../../../assets/icons/filters.svg')}
                  alt="filters"
                />
              </Button>
            </TitleButton>
          ) : null}
          {props.access.directory.requests.delete && props.selectedRequests.length ? (
            <TitleButton>
              <Button
                circle
                clicked={() => props.deleteRequests()}
                title="Удалить"
              >
                <img
                  style={{ width: '22px' }}
                  src={require('../../../assets/icons/delete-white.svg')}
                  alt="delete"
                />
              </Button>
            </TitleButton>
          ) : null}
          {(props.selectedRequests.length || Object.keys(props.filters).length) ? (
            <TitleButton>
              <Button
                circle
                clicked={() => {
                  props.cancel();
                  const elementTable = document.querySelector('.ag-theme-balham');
                  elementTable.style.height = '';
                }
              }
                title="Закрыть"
              >
                <img
                  style={{ width: '12px' }}
                  src={require('../../../assets/icons/close-white.svg')}
                  alt="cancel"
                />
              </Button>
            </TitleButton>
          ) : null}
        </TitleContainer>
        {props.access && props.access.directory && props.access.directory.requests && props.access.directory.requests.export ? (
          <TitleContainer style={{ margin: '0 auto', paddingLeft: '0px' }}>
            <IconButtonWrapper>
              <TitleButton>
                <Button
                  circle
                  clicked={() => props.getExcel()}
                  title="Выгрузить"
                >
                  <img
                    style={{ width: '12px' }}
                    src={require('../../../assets/icons/export.svg')}
                    alt="export"
                  />
                </Button>
              </TitleButton>
              <TitleButton>
                <Button
                  circle
                  clicked={() => props.getExcel(true)}
                  title="Выгрузить дебет"
                >
                  <img
                    style={{ width: '14px' }}
                    src={require('../../../assets/icons/debit-down.png')}
                    alt="export"
                  />
                </Button>
              </TitleButton>
              {(props.selectedRequests.length) ? (
                <TitleButton>
                  <Button
                    circle
                    clicked={() => props.setShowEmailsModal(true)}
                    title="Выгрузка по ЖД"
                  >
                    <img
                      style={{ width: '12px' }}
                      src={require('../../../assets/icons/export.svg')}
                      alt="unloading"
                    />
                  </Button>
                </TitleButton>
              ) : null}
            </IconButtonWrapper>
          </TitleContainer>
        ) : <div style={{ margin: '0 auto' }}></div>}
        <div style={{ position: 'relative' }}>
          <CalendarButton filterActive={props.dateFiltersActive}>
            <Button
              mode="white"
              clicked={() => props.clickCalendar()}
              title="Календарь"
            >
              Фильтр по дате
            </Button>
          </CalendarButton>
          {props.viewCalendar ? (
            <CalendarContainer>
              <Calendar
                eventOnOk={props.changeDateRange}
                showToday={false}
                showClear={false}
                selectedValue={props.rangeDate}
                onSelect={props.setRangeDate}
              />
            </CalendarContainer>
          ) : null}
        </div>
        {props.dateFilters ? (
          <InputDate>
            <Input
              type="text"
              value={dateFilters}
              changed={() => props.setViewCalendar(true)}
              active={() => props.setViewCalendar(true)}
            />
            <DateFiltersValueDelete
              onClick={() => {
                props.deleteDateFilter();
                props.setRangeDate([]);
              }}
              src={require('../../../assets/icons/close-default.svg')}
              alt="delete"
            />
          </InputDate>
        ) : null}
        <SelectStatuses onChange={value => props.selectStatus(value)} />
        <InputSearch>
          <Input
            type="search"
            placeholder="Поиск"
            search="true"
            changed={value => props.search(value)}
          />
        </InputSearch>
        <ExpandButtonWrapper>
          <Button
            mode="white"
            clicked={() => {
              props.setExpand(!props.expand);
              const element = document.querySelector('.main-dashboard');
              const scrollArea = document.querySelector('.scroll-area');
              const content = document.querySelector('.content-area');
              const elementForm = document.querySelector('.main-dashboard-form');
              if (element && elementForm && element.classList.contains('hidden')) {
                if (element && elementForm) {
                  element.classList.remove('hidden');
                  elementForm.classList.remove('hidden');
                }
                if (scrollArea) {
                  scrollArea.style.height = `${window.innerHeight - 225}px`;
                }
                if (content) {
                  content.style.width = 'calc(100% - 160px)';
                  content.style.marginTop = '32px';
                }
              } else {
                if (element && elementForm) {
                  element.classList.add('hidden');
                  elementForm.classList.add('hidden');
                }
                if (scrollArea) {
                  scrollArea.style.height = `${window.innerHeight - 110}px`;
                }
                if (content) {
                  content.style.width = '100%';
                  content.style.marginTop = '0';
                }
              }
            }}
          >
            {!props.expand ? (
              <img
                style={{ width: '12px' }}
                src={require('../../../assets/icons/expand.svg')}
                alt="calendar"
              />
            ) : (
              <img
                style={{ width: '12px' }}
                src={require('../../../assets/icons/despand.svg')}
                alt="calendar"
              />
            )}
          </Button>
        </ExpandButtonWrapper>
      </ControlContainer>
      <MobileInputSearch>
        <Input
          type="search"
          placeholder="Поиск"
          search="true"
          changed={value => props.search(value)}
        />
      </MobileInputSearch>
      {Object.keys(props.filters).length ? (
        <FiltersContainer className='filter-container'>
          {filters}
        </FiltersContainer>
      ) : null}
      <TableContainer className="ag-theme-balham">
        {
          props.isLoading
            ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                }}
              >
                <Loaders />
              </div>
            )
            : (
              <AgGridReact
                columnDefs={props.tableHeader}
                defaultColDef={{
                  sortable: true,
                  width: 150,
                  suppressMenu: true,
                }}
                floatingFilter={true}
                localeText={localeText}
                rowData={props.tableData}
                suppressAutoSize={true}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                enableRangeSelection={true}
                statusBar={statusBar}
                animateRows={true}
                onGridReady={props.onGridReady}
                onCellClicked={props.onCellClicked}
                onCellDoubleClicked={props.onCellDoubleClicked}
                onSortChanged={props.onSortChanged}
                onRowSelected={props.onRowSelected}
                onCellValueChanged={props.onCellValueChanged}
                getRowNodeId={data => data.id}
                onBodyScroll={props.onBodyScroll}
                getContextMenuItems={props.getContextMenuItems}
                onDragStopped={props.onDragStopped}
                frameworkComponents={{
                  inputDate: params =>
                    <InputTable>
                      <Input
                        type='date'
                        value={params.value}
                        changed={value => props.editRequest(params.node.data.id, params.colDef.field, value)}
                      />
                    </InputTable>,
                  inputTime: params =>
                    <Input
                      type='time'
                      value={params.value}
                      changed={value => props.editRequest(params.node.data.id, params.colDef.field, value)}
                    />,
                  rating: params =>
                    <Rating
                      {...params}
                      setRating={props.setRating}
                      readOnlyRating={props.readOnlyRating}
                    />,
                  iconYesNo: params =>
                    <IconYesNoView
                      value={params.value}
                      params={params}
                    />,
                }}
              />
            )
          }
      </TableContainer>
      {props.loadingExcel ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Loaders />
        </div>
      ) : null}
      <PopupMessage show={props.delPopup}>
        <Popup status="Warning">
          <p>Вы точно хотите удалить заявку №{props.numberDelete}?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.deleteRequests(props.idDelete);
                props.removeHandlerClear();
              }}
            >
              Да
            </button>
            <button
              type="button"
              onClick={() => props.removeHandlerClear()}
            >
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      <PopupMessage show={props.copyH}>
        <Popup status="Warning">
          <p>Вы точно хотите создать копию заявки №{props.numberCopy}?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.copyRequest(props.idCopy, props.positionCopy);
                props.copyHandlerClear();
              }}
            >
              Да
            </button>
            <button
              type="button"
              onClick={() => props.copyHandlerClear()}
            >
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      {props.showRatingModal ? (
        <ModaleWindow
          closeAction={props.closeReviewModal}
          width={300}
        >
          <ModalContentWrapper>
            <Textarea
              placeholder="Расскажите, почему вы поставили именно такую оценку..."
              rows={10}
              value={props.ratingReview}
              onChange={({ target }) => {
                props.setRatingReview(target.value);
              }}
            />
            <div style={{ maxWidth: '90%' }}>
              <p style={{ marginBottom: '15px' }}>
                Информация этого сообщения конфиденциальная и ее получит исключительно руководство ООО "Амикс групп"
              </p>
            </div>
            {
              props.isReviewSending
                ? (
                  <Loaders />
                ) : (
                  <Button
                    clicked={props.sendReview}
                  >
                    Отправить
                    </Button>
                )
            }
          </ModalContentWrapper>
        </ModaleWindow>
      ) : null}
      {props.showEmailsModal ? (
        <ModaleWindow
          closeAction={props.closeEmailsModal}
          width={300}
        >
          <ModalContentWrapper>
            <Textarea
              placeholder="Укажите дополнительные email адреса через запятую, на которые придут копии писем (не обязательно для заполнения)"
              rows={10}
              value={props.stringEmails}
              onChange={({ target }) => props.setStringEmails(target.value)}
            />
            {
              props.isEmailsSending
                ? (
                  <Loaders />
                ) : (
                  <Button
                    clicked={props.postRequest}
                  >
                    Отправить
                    </Button>
                )
            }
          </ModalContentWrapper>
        </ModaleWindow>
      ) : null}
    </Form>
  );
};
