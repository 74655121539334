import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import NavBarView from './NavBarView';

export default compose(
  connect(
    state => ({
      token: state.auth.token,
      showNewRequest: state.dashboard.showNewRequest,
      access: state.auth.userAccesses,
    }),
  ),
  withHandlers({
    onClick: props => () => {
      console.log(props);
    },
  }),
)(NavBarView);
