import styled from 'styled-components';

import { base, colors } from '../../../styles';

export const SelectItem = styled.div`
    color: #9b9b9b;
    padding: 6px  0;
    &:hover{
        color: ${colors.primary_color};
    }
`;
