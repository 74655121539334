import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const DropdownWraper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  outline: none;
  position: relative;
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownOptions = styled.div`
  position: absolute;
  top: 46px;
  right: 0;
  width: 100%;
  min-width: 140px;
  box-shadow: 0 1px 0 0 rgba(0,120,255,.0375), 0 6px 16px 0 rgba(0,73,164,.055);
  background: ${colors.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 100;
`;

export const UserName = styled.div`
  color: ${colors.black};
  opacity: 0.5;
  align-self: center;
  margin-right: 12px;
  margin-left: 8px;
  font-size: ${base * 1.3}px;

  @media(max-width: ${screens.screen_xs_max}){
    display: none;
  }
`;

export const UserAvatar = styled.div`
  width: 38px;
  height: 38px;
  overflow: hidden;
  border-radius: 50%;
  ${props => props.avatar && css`
    background: url(${props.avatar});
    background-position: 50%;
    background-size: cover;
    background-color: ${colors.primary_gray};
  `}
`;

export const DropDownMask = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
`;
