import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

export const Form = styled.div`
  width: 100%;
  font-size: 18px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 300;
`;

export const TitleButton = styled.div`
    margin-right: auto;
    margin-left: 52px;

  ${props => props.white && css`
    margin-right: 0;
    margin-left: 0;

    @media (max-width: ${screens.screen_xs_max}) {
      display: none;
    }
  `}
`;

export const Loader = styled.div`
  display: none;
  transform: translateZ(1px);
  margin-left: 20px;
  display: inline-block;
  width: ${base + 4}px;
  height: ${base + 4}px;
  border-radius: 50%;
  background: #2E83EC;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  opacity: 0.65;
  @keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
`;

export const InfoBlockTotalWrapper = styled.div`
  max-width: 100%;
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${screens.screen_2xs_min}) {
    justify-content: space-between;
  }

  @media (max-width: ${screens.screen_3xs_min}) {
    flex-direction: row;
    justify-content: center;
  }

  .slick-slider {
    padding: 6px 0;
  }
  .slick-slide {
    padding: 6px 0;
  }
  .slick-slider {
    width: 750px;
  }
`;

export const MobileTitleButton = styled.div`
  display: none;
  margin-bottom: 32px;

  button {
    background-color: ${colors.white};
    color: ${colors.primary_color};
    border: 1px solid ${colors.primary_color};
  }

  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
  }
`;

export const HistoryContainer = styled.div`
  background-color: ${colors.primary_gray};
  width: 40%;
  padding: 14px 0;
  min-height: 100%;
  @media (max-width: ${screens.screen_md_max}) {
    width: 100%;
  }
`;

export const RequestWrapper = styled.div`
  width: 60%;
  @media (max-width: ${screens.screen_md_max}) {
    width: 100%;
  }
`;

export const ContainerRequest = styled.div`
  display: flex;
  @media (max-width: ${screens.screen_md_max}) {
    flex-direction: column;
  }
`;
