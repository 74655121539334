import styled from 'styled-components';

import { colors, screens } from '../../../styles';

export const Content = styled.div``;

export const UserName = styled.span`
  color: ${colors.primary_color};
`;

export const WidgetWrapper = styled.div`
  max-width: 70vw;
  background-color: ${colors.white};
  height: 60vh;
  border-radius: 12px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 16px 0 rgba(31, 171, 255, 0.07);
  position: relative;
  overflow: hidden;
  margin: 12px;
  
  &:after {
    content: '';
    background-color: rgba(31, 171, 255, 0.75);
    border-radius: 50%;
    position: absolute;
    bottom: -100px;
    right: -100px;
    width: 150px;
    height: 150px;
  }

  @media (max-width: ${screens.screen_md_mid}) {
    height: auto;
    padding: 24px;
    div {
      div {
        width: 100%;
        padding: 0 24px;
      }
    }
  }

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 0px;
  }
`;

export const Greeting = styled.div`
  margin-bottom: 18px;
`;

export const ChartWrapper = styled.div`
  margin: 12px 0;
`;

export const WidgetTitle = styled.h4`
  color: ${colors.primary_red};
  font-size: 24px;
  margin-bottom: 24px;
  @media (max-width: ${screens.screen_md_mid}) {
    text-align: center;
  }
  @media (max-width: ${screens.screen_xs_max}) {
    font-size: 18px;
  }
`;
