/**
 * @flow
 */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  DashboardLayout, Popup,
} from '../../components';

import {
  Main,
  Users,
  Directories,
  ChangePassword,
  PersonalInfo,
  CustColumns,
  RequsetForm,
  RequestsTable,
  Request,
  RequestChangeRole,
} from '../Content';

import {
  PopupStatusResult,
} from './DashboardMainStyled';

type DashboardMainPropsType = {
  user: any,
  logoutHandler: (any) => void,
  openProfileHandler: (any) => void,
  openDashboardLogo: (any) => void,
  checkStatus: (any) => void,
  openRequestFormHandler: () => void,
};

export default function DashboardMain(props: DashboardMainPropsType) {
  return (
    <DashboardLayout
      user={props.user}
      logoutHandler={() => props.logoutHandler()}
      openProfileHandler={() => props.openProfileHandler()}
      openDashboardLogo={() => props.openDashboardLogo()}
      openRequestFormHandler={() => props.openRequestFormHandler()}
    >
      <Switch>
        {/* users */}
        <Route exact path="/dashboard/users" component={Users} />
        <Route exact path="/dashboard/users/changeRole" component={RequestChangeRole} />
        <Route path="/dashboard/users/:role" component={Users} />
        {/* directories */}
        <Route exact path="/dashboard/directories" component={Directories} />
        <Route path="/dashboard/directories/:directory" component={Directories} />
        {/* profile */}
        <Route exace path="/dashboard/profile/custColumns" component={CustColumns} />
        <Route exact path="/dashboard/profile/changePassword" component={ChangePassword} />
        <Route exact path="/dashboard/profile/personalInfo" component={PersonalInfo} />
        {/* request form */}
        <Route exact path="/dashboard/requests/create" component={RequsetForm} />
        <Route exact path="/dashboard/requests/edit/:id" component={RequsetForm} />
        {/* requests table */}
        <Route exact path="/dashboard/requests" component={RequestsTable} />
        {/* request view */}
        <Route path="/dashboard/requests/view/:request" component={Request} />
        {/* default */}
        <Route path="/dashboard" component={Main} />
      </Switch>
      <PopupStatusResult>
        {props.checkStatus.statusTime
          && (
            <Popup
              status={props.checkStatus.statusCheck}
              message={props.checkStatus.messageBox}
            />
          )
        }
      </PopupStatusResult>
    </DashboardLayout>
  );
}
