import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

export const Form = styled.div`
  width: 100%;
  height: 90%;
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-left: -51px;
  }
  @media (max-width: ${screens.screen_2xs_max}) {
    width: calc(100vw - 2 * ${base}px);
  }

  .ag-theme-balham .ag-header {
    font-family: 'Source Sans Pro', sans-serif;
  }
  /*Скрыть лицензию AG-GRID*/
  .ag-watermark {
    display: none;
  }
  /*Скрыть скроллинг колонки с номером*/
  .ag-horizontal-left-spacer {
    visibility: hidden;
  }

  .ag-theme-balham .ag-cell {
    border: 0px solid transparent;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${base * 1}px;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-top: -14px;
  }
  @media (max-width: ${screens.screen_2xs_max}) {
    flex-wrap: wrap;
  }
`;

export const Heading = styled.h1`
  font-size: 36px;
  font-weight: 100;
  margin-right: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 12px 0px;

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 0 0 12px 0px;
  }
`;

export const TitleButton = styled.div`
  margin-right: 15px;
  position: relative;
  ${props => props.filtersActivePoint && css`
    &:before {
      content: '•';
      position: absolute;
      color: #ea3000;
      top: -21px;
      left: 9px;
      font-size: 20px;
    }
  `}
`;

export const FilterButton = styled.div`
  margin-right: 15px;
  position: relative;
  & > button {
      padding: 6px 12px;
      & > p {
        font-size: 10px;
      }
  }
`;

export const ExpandButtonWrapper = styled.div`
  padding-left: 12px;
  
  & > button {
    background-color: ${colors.primary_color};
  }
`;

export const CalendarContainer = styled.div`
  display: block;
  position: absolute;
  z-index: 15;
  top: 45px;
  left: 0px;

  @media (max-width: ${screens.screen_md_mid}) {
    top: 132px;
    left: 10px;
  }
`;

export const CalendarButton = styled.div`
  margin-left: auto;
  margin-right: 15px;
  position: relative;

  ${props => props.filterActive && css`
    &:before {
      content: '•';
      position: absolute;
      color: #ea3000;
      top: -21px;
      left: 9px;
      font-size: 20px;
    }
  `}

  @media (max-width: ${screens.screen_xs_max}) {
    margin-right: 3px;
  }

`;

export const InputDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-right: 12px;

  input, div {
    height: 36px;
    width: ${base * 14}px;
  }
`;

export const InputTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 12px;
  margin-right: 12px;

  input, div {
    height: 100%;
  }
`;

export const InputSearch = styled.div`
  width: 220px;

  & input {
    height: 42px;
  }

  @media (max-width: ${screens.screen_md_min}) {
    display: none;
  }
`;

export const MobileInputSearch = styled.div`
  display: none;
  width: 220px;
  padding: 0 0 12px 0;

  @media (max-width: ${screens.screen_md_min}) {
    display: block;
  }

  @media (max-width: ${screens.screen_2xs_max}) {
    width: 100%;
  }
`;

export const FiltersContainer = styled.div`
  background: ${colors.white};
  margin: 0 -45px 0 -51px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 0 82px;

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 10px 0 0 10px;
    margin: 0 -100px 0 0;
  }
`;

export const TableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 30px;
  font-size: 12px;

  @media (max-width: ${screens.screen_sm_mid}) {
    height: calc(100vh - 19 * ${base}px);
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    height: calc(100vh - 22 * ${base}px);
  }
`;

export const FiltersValueContainer = styled.div`
  width: 150px;
  margin: 0 10px 10px 0;
`;

export const FiltersValueTitle = styled.div`
  margin: 0 0 2px 0;
  color: ${colors.light_gray};
  font-size: 10px;
`;

export const FiltersValue = styled.div`
  position: relative;

  input {
    margin-top: 4px;
    padding: 8px; 14px;
    font-size: 10px;
  }
`;

export const FiltersValueDelete = styled.img`
  width: 10px;
  position: absolute;
  top: 6px;
  right: 4px;
  outline: none;
  cursor: pointer;
`;

export const DateFiltersValueDelete = styled.img`
  width: 12px;
  height: 12px;
  position: relative;
  right: 18px;
  outline: none;
  cursor: pointer;

  @media (max-width: ${screens.screen_md_mid}) {
  }
`;

export const Select = styled.select`
  width: 100%;
  margin-top: 4px;
  font-size: 10px;
  outline: none;
  padding: 0 12px;
  border: none;
  border-radius: 8px;
  background-color: ${colors.white_smoke};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const ValueOptions = styled.div`
  position: absolute;
  z-index: 100;
  width: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 5px;
  background-color: ${colors.white};
  border: 1px solid ${colors.history_gray};
  border-radius: 8px;
`;

export const OptionValues = styled.div`
  padding: 3px 5px 3px 5px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary_color};
  }

  &:active {
    color: ${colors.primary_color};
  }
`;

export const Loaders = styled.div`
  display: none;
  transform: translateZ(1px);
  margin-left: 20px;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background:  #1eabff;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  opacity: 0.65;
  @keyframes lds-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }
`;

export const IconButtonWrapper = styled.div`
  font-size: 18px;
  display: flex;
`;

export const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Textarea = styled.textarea`
  width: 90%;
  height: 75%;
  border: 1px solid #dadada;
  padding: 8px;
  margin: 16px 0;
  outline: none;
  resize: none;
`;
