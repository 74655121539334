/**
 * @flow
 */

import React from 'react';

import Loader from './LoaderStyled';

export default function Logo() {
  return <Loader />;
}
