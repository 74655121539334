import * as actionTypes from '../actions/actionTypes';

const initialState = {
  usersInfoFirstName: null,
  usersInfoLastName: null,
  usersInfoPassword: null,
  usersInfoPasswordR: null,
  usersInfoLogin: null,
  usersInfoPhoneNumber: null,
  usersInfoEmail: null,
  usersInfoPosition: null,
  usersInfoAvatar: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_USERINFO_FIRSTNAME_DATA:
      return {
        ...state,
        usersInfoFirstName: action.payload,
      };
    case actionTypes.SAVE_USERINFO_LASTNAME_DATA:
      return {
        ...state,
        usersInfoLastName: action.payload,
      };
    case actionTypes.SAVE_USERINFO_LOGIN_DATA:
      return {
        ...state,
        usersInfoLogin: action.payload,
      };
    case actionTypes.SAVE_USERINFO_PHONE_NUMBER_DATA:
      return {
        ...state,
        usersInfoPhoneNumber: action.payload,
      };
    case actionTypes.SAVE_USERINFO_EMAIL_DATA:
      return {
        ...state,
        usersInfoEmail: action.payload,
      };
    case actionTypes.SAVE_USERINFO_POSITION_DATA:
      return {
        ...state,
        usersInfoPosition: action.payload,
      };
    case actionTypes.SAVE_USERINFO_AVATAR_DATA:
      return {
        ...state,
        usersInfoAvatar: action.payload,
      };
    case actionTypes.SAVE_USERINFO_PASSWORD_DATA:
      return {
        ...state,
        usersInfoPassword: action.payload,
      };
    case actionTypes.SAVE_USERINFO_PASSWORD_REPEAT_DATA:
      return {
        ...state,
        usersInfoPasswordR: action.payload,
      };
    default:
      return state;
  }
}
