import React from 'react';
import Downshift from 'downshift';

import {
  List,
  Item,
  Button,
  DeleteButton,
  Container,
  Input,
  Mark,
  InputMark,
} from './SelectStyles';

type SelectViewType = {
  onChange: () => void,
};

const statusList = [
  { value: 'Предварительное', color: '#A9D08E' },
  { value: 'В пути', color: '#FFFF00' },
  { value: 'Прибыл', color: '#da9d0a' },
  { value: 'Выдан клиенту', color: '#0ada35' },
  { value: 'Прогон', color: '#F4B084' },
  { value: 'Закрыта', color: '#808080' },
  { value: 'Вывоз', color: '#F58223' },
];

export default function SelectView(props: SelectViewType) {
  return (
    <Downshift
      onChange={selection => (selection ? props.onChange(selection.value) : props.onChange(null))}
      itemToString={item => (item ? item.value : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        clearSelection,
        inputValue,
      }) => (
        <Container {...getRootProps()}>
          <Input
            {...getInputProps({
              type: 'text',
              disabled: true,
              placeholder: 'Выберите статус',
            })}
          />
          {selectedItem && <InputMark color={selectedItem.color} />}
          {inputValue && <DeleteButton onClick={clearSelection} />}
          {isOpen && (
            <List {...getMenuProps()}>
              {statusList.map((item, index) => (
                <Item
                  {...getItemProps({
                    key: item,
                    item,
                    style: {
                      color:
                        highlightedIndex === index ? '#da350a' : '#9b9b9b',
                      fontWeight: selectedItem === item ? 'bold' : 'normal',
                    },
                  })}
                >
                  <Mark color={item.color} />
                  {item.value}
                </Item>
              ))}
            </List>
          )}
          <Button {...getToggleButtonProps()} isOpen={isOpen} />
        </Container>
      )}
    </Downshift>
  );
}
