/**
 * @flow
 */
import React from 'react';

import {
  Checkbox,
  Button,
  Popup,
  Loader,
  Input,
} from '../../../../components';

import {
  MainPage,
  TitleCust,
  CustColumnsMain,
  NameColumns,
  CheckboxContainer,
  HeaderTitle,
  PopupCustTitle,
  ColumnsSection,
  CheckBoxWrapper,
  SectionTitleWrapper,
  InputSearchBlockContainer,
  InputSearchBlock,
} from './CustColumnsStyled';

type CustColumnsPropsType = {
  isLoading: boolean,
  filteredCustList: Array<Object>,
  setCheckValue: (any) => void,
  saveCustColumnsData: (any) => void,
  custSaved: any,
  selectAllInSection: (string, boolean) => void,
};

const renderCustList = (custList, setCheckValue, selectAllInSection) => (custList.map(item => (
  <ColumnsSection key={item.name}>
    <SectionTitleWrapper>
      <NameColumns>{item.title}</NameColumns>
      <Checkbox
        message="Выбрать все"
        name={item.name}
        onClickItem={(value) => { selectAllInSection(item.name, value); }}
      />
    </SectionTitleWrapper>
    <CheckboxContainer>
      {item.items.map(checkbox => (
        <CheckBoxWrapper key={checkbox.name}>
          <Checkbox
            message={checkbox.title}
            value={checkbox.value}
            name={checkbox.name}
            onClickItem={() => { setCheckValue(checkbox.name); }}
          />
        </CheckBoxWrapper>
      ))}
    </CheckboxContainer>
  </ColumnsSection>
)));

export default function CustColumns(props: CustColumnsPropsType) {
  return (
    <MainPage>
      <HeaderTitle>
        <TitleCust>
          <span>Настройка</span>
          <span>колонок</span>
        </TitleCust>
        <Button
          clicked={() => {
            props.saveCustColumnsData();
          }}
        >
        Сохранить
        </Button>
      </HeaderTitle>
      <CustColumnsMain>
        <p>Настройте видимость колонок  для раздела Заявки </p>
        <InputSearchBlockContainer>
          <InputSearchBlock>
            <Input
              type="search"
              placeholder="Поиск"
              search="true"
              value={props.searchValue}
              changed={value => {
                props.setSearchValue(value);
                props.searchItems(value);
              }}
            />
          </InputSearchBlock>
        </InputSearchBlockContainer>
        {
          props.isLoading
            ? <Loader />
            : renderCustList(props.filteredCustList, props.setCheckValue, props.selectAllInSection)
        }
      </CustColumnsMain>
      <PopupCustTitle>
        {props.custSaved && (<Popup status="Success">Данные сохранены</Popup>)}
      </PopupCustTitle>
    </MainPage>
  );
}
