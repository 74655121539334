/**
 * @flow
 */
import React from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import {
  Form,
  TitleContainer,
  Title,
  TitleButton,
  MobileTitleButton,
  HistoryContainer,
  RequestWrapper,
  ContainerRequest,
  Loader,
  InfoBlockTotalWrapper,
} from './RequestStyles';

import {
  Request,
  History,
  HistoryItem,
  Button,
  InfoBlockTotal,
} from '../../../components';

type RequestPropsType = {
  availableFields: Object,
  isLoading: Boolean,
  request: Object,
  historyRequest: Array<any>,
  payments: Array<Object>,
  addRequest: () => void,
  back: () => void,
  getExcel: () => void,
  changeDateHistory: () => void,
  editRequest: () => void,
  deleteRequest: () => void,
  scrollEvent: () => void,
  access: Object,
  hasMore: boolean,
  loadMore: () => void,
};

const WINDOW_HEIGHT = window.innerHeight;

export default function RequestFormView(props: RequestPropsType) {
  // формирование истории заявки
  const history = [];
  if (props.access.directory.requestsHistory.findAll) {
    props.historyRequest.forEach((element, index) => {
      const date = moment(element.date).format('DD-MM-YYYY');
      const time = moment(element.date).format('HH:mm:ss');
      const lastItem = index === props.historyRequest.length - 1;
      let previousValue = '';
      let nextValue = '';
      if (!element.isObject) {
        previousValue = element.oldValue;
        nextValue = element.newValue;
      } else {
        if (!element.oldValue || !element.newValue) return;
        const preObj = JSON.parse(element.oldValue);
        const nextObj = JSON.parse(element.newValue);
        Object.keys(preObj).forEach((field) => {
          if (field !== '_id') {
            if (preObj[field]) {
              if (!Array.isArray(preObj[field])) {
                previousValue += preObj[field];
              }
            }
          }
        });
        Object.keys(nextObj).forEach((field) => {
          if (field !== '_id') {
            if (nextObj[field]) {
              if (!Array.isArray(nextObj[field])) {
                nextValue += nextObj[field];
              }
            }
          }
        });
        if (previousValue === '') {
          previousValue = null;
        }
      }
      if (element.field === 'Дата загрузки') {
        if (previousValue) {
          previousValue = previousValue.slice(0, 10);
        }
        if (nextValue) {
          nextValue = nextValue.slice(0, 10);
        }
      }
      history.push(
        <HistoryItem
          key={element._id}
          date={date}
          time={time}
          userName={`${element.userID.firstName} ${element.userID.lastName}`}
          inputName={element.field}
          previousValue={previousValue}
          nextValue={nextValue}
          lastItem={lastItem}
        />,
      );
    });
  }

  if (props.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}
      >
        <Loader className="lds-circle" />
      </div>
    );
  }

  return (
    <Form onWheel={() => props.scrollEvent()} className="form-request">
      <TitleContainer>
        <Title>Заявки</Title>
        {(props.access.directory.requests.insert)
          ? (
            <TitleButton>
              <Button circle clicked={() => props.addRequest()} plus />
            </TitleButton>
          ) : null
        }
        <TitleButton white>
          <Button mode="white" clicked={() => { props.back(); }}>Вернуться</Button>
        </TitleButton>
      </TitleContainer>
      <MobileTitleButton>
        <Button clicked={() => props.back()}>Вернуться</Button>
      </MobileTitleButton>
      <ContainerRequest>
        <RequestWrapper>
          {
            props.payments.payment
            && props.payments.payment.length > 0
            && (
              <>
                <h3>Платежи</h3>
                <InfoBlockTotalWrapper>
                  {props.payments.payment.map(payment => (
                    <InfoBlockTotal
                      key={payment.id}
                      number={payment.paymentNumber}
                      date={payment.date}
                      amount={+payment.amount}
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )
          }
          {
            props.payments.containerAgentPayment
            && props.payments.containerAgentPayment.length > 0
            && (
              <>
                <h3>Контейнерный агент</h3>
                <InfoBlockTotalWrapper>
                  {props.payments.containerAgentPayment.map(payment => (
                    <InfoBlockTotal
                      number={payment.paymentNumber}
                      date={payment.date}
                      amount={+payment.amount}
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )
          }
          {
            props.request.buh_amountMore
            && props.request.buh_amountMore.length > 0
            && (
              <>
                <h3>Дополнительные суммы</h3>
                <InfoBlockTotalWrapper>
                  {props.request.buh_amountMore.map(item => (
                    <InfoBlockTotal
                      number={item.accountNumber}
                      date={item.date.slice(0, 10)}
                      amount={+item.amount}
                      comment={item.comment}
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )
          }
          <Request
            columnTitles={props.availableFields}
            data={props.request}
            export={props.getExcel}
            edit={props.editRequest}
            delete={props.deleteRequest}
          />
        </RequestWrapper>
        {(props.access.directory.requestsHistory.findAll)
          ? (
            <HistoryContainer>
              <History
                requestNumber={props.request.number}
                changeDateHistory={props.changeDateHistory}
              >
                <div style={{ overflow: 'auto', height: `${WINDOW_HEIGHT - 282}px` }}>
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={(p) => {
                      if (props.historyRequest.length !== 0) {
                        props.loadMore(p);
                      }
                    }}
                    hasMore={props.hasMore}
                    useWindow={false}
                    ref={props.infinitScrollerRef}
                  >
                    {history}
                  </InfiniteScroll>
                </div>
              </History>
            </HistoryContainer>
          ) : null
        }
      </ContainerRequest>
    </Form>
  );
}
