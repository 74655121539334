import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const SelectDropDownWraper = styled.div`
    font-size: 12px;
    cursor: pointer;
    height: ${base * 3.6}px;
    position: relative;
    outline: none;
`;

export const SelectDropDownHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border: 1px solid ${colors.primary_white_gray};
    padding: 0 12px;
    margin-bottom: ${base - 4}px;
    border-radius: ${base - 4}px;
    box-sizing: border-box;
`;

export const SelectName = styled.div`
    color: ${colors.black};
    font-size: 16px;
    ${props => props.isPlaceholder && css`
        color: #8e8e8e;
    `}
`;

export const SelectArrowUp = styled.div`
    background-image: url(${require('../../assets/icons/drop-down.svg')});
    width: 12px;
    opacity: 0.75;
    background-size: contain;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
`;

export const SelectArrowDown = styled.div`
    background-image: url(${require('../../assets/icons/drop-down-up.svg')});
    width: 12px;
    opacity: 0.75;
    background-size: contain;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
`;

export const SelectDropDownOptions = styled.div`
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    margin-top: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 20px #efefef;
    padding: 6px 10px;
    z-index: 100;
    max-height: 300px;
    overflow: auto;
    @media (max-width: ${screens.screen_xs_max}) {
      width: 100%;
    }
`;

export const SelectDropDownMask = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
`;
