import styled, { css } from 'styled-components';

export const Popup = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 24px 48px;
    border-radius: 6px;
    box-shadow: 0 10px 20px #efefef;
    z-index: 5000;
    ${props => props.status === 'Warning' && css`
        display: block;
        text-align: center;
        color: black;
        opacity: 0.85;
        background-color: #fff;
    `}

    ${props => props.status === 'WarningLoader' && css`
        color: black;
        opacity: 0.85;
        background-color: #fff;
    `}

    ${props => props.status === 'Info' && css`
        color: #154080;
        background-color: #d0e4fc;
    `}
    ${props => props.status === 'Success' && css`
        color: #2a552a;
        background-color: #d9ecdb;
    `}
    ${props => props.status === 'Error' && css`
        color: #692326;
        background-color: #f3d8da;
    `}
`;

export const Loader = styled.div`
    display: none;
    border: 8px solid #dadada;
    border-top: 8px solid #656565;
    border-radius: 50%;
    opacity: 0.5;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
    margin-left: 20px;

    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
