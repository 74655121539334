import styled from 'styled-components';

export const PopupStatusResult = styled.div`
    min-width: 324px;
    max-width: 594px;
    div {
        position: fixed;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom .3s ease-in-out;
        bottom: 20px;
    }
`;
