import React from 'react';

import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import SelectDropDownView from './SelectDropDownView';
import SelectDropDownItem from './SelectDropDownItem/SelectDropDownItemContainer';

export default compose(
  withState('toggledOn', 'toggle', false),
  withState('currentState', 'setCurrentState', ''),
  withHandlers({
    toggle: ({ toggle }) => () => toggle(current => !current),
    convertToArray: props => () => React.Children.toArray(props.children),
  }),
  lifecycle({
    componentDidMount() {
      let { children } = this.props;

      React.Children.forEach(children, (child) => {
        if (child.type !== SelectDropDownItem) {
          throw new Error('Dropdown children should be of type `SelectDropdownItem`.');
        }
      });

      const isArray = Array.isArray(children);

      if (!isArray) {
        children = this.props.convertToArray();
      }
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.skip && !this.props.skip) {
        this.props.setCurrentState('');
      }
    },
  }),
)(SelectDropDownView);
