export const base = 14;

export const colors = {
  black: '#1e1e1e',
  white: '#fff',
  white_d: '#fafafa',
  light_gray: '#b3b3b3',
  primary_color: '#1eabff',
  primary_red: '#1eabff',
  primary_gray: '#f3f6f7',
  history_gray: '#aeaeae',
  white_smoke: '#f2f2f2',
  primary_white_gray: '#dedede',
  pink_light: 'rgba(255,128,171,0.4)',
  purple_light: 'rgba(193, 134, 228,0.4)',
  green_light: 'rgba(37, 204, 165,0.4)',
  gray_dark: 'rgba(105, 105, 105)',
  red_dark: 'rgba(175, 35, 0)',
};

export const screens = {
  screen_lg_min: '1300px',
  screen_md_max: '1299px',
  screen_md_mid: '1024px',
  screen_md_min: '1000px',
  screen_sm_max: '999px',
  screen_sm_mid: '860px',
  screen_sm_min: '760px',
  screen_xs_max: '759px',
  screen_xs_min: '640px',
  screen_2xs_max: '639px',
  screen_2xs_min: '480px',
  screen_3xs_max: '479px',
  screen_3xs_min: '360px',
  screen_4xs_max: '359px',
  screen_4xs_min: '0px',
};
