import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';


export const InputMaskAndCalendar = styled.div`
    ${props => props.lastCalendar && css`
        .rc-calendar {
            top: -400px;
        }
    `}
`;

export const CalendarMask = styled.div`
    position: fixed;
    width: 95%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
`;
