import {
  apiGet,
} from '../ApiService';

export default {
  getApiName() {
    return 'directories';
  },

  getDirectories() {
    return apiGet('/directories')
      .then(response => response.data.docs.map(directory => ({
        id: directory._id,
        name: directory.title,
        url: directory.url,
      })))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/directories?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
