import * as actionTypes from './actionTypes';

export function saveUserInfoFirstNameData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_FIRSTNAME_DATA,
    payload: data,
  };
}

export function saveUserInfoLastNameData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_LASTNAME_DATA,
    payload: data,
  };
}

export function saveUserInfoLoginData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_LOGIN_DATA,
    payload: data,
  };
}

export function saveUserInfoPhoneNumberData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_PHONE_NUMBER_DATA,
    payload: data,
  };
}

export function saveUserInfoEmailData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_EMAIL_DATA,
    payload: data,
  };
}

export function saveUserInfoPositionData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_POSITION_DATA,
    payload: data,
  };
}

export function saveUserInfoAvatarData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_AVATAR_DATA,
    payload: data,
  };
}

export function saveUserInfoPasswordData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_PASSWORD_DATA,
    payload: data,
  };
}

export function saveUserInfoPasswordRepeatData(data) {
  return {
    type: actionTypes.SAVE_USERINFO_PASSWORD_REPEAT_DATA,
    payload: data,
  };
}
