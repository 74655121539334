import { compose, withHandlers, withState } from 'recompose';

import CalendarView from './CalendarView';

export default compose(
  withState('dateRange', 'setDateRange', []),
  withHandlers({
    eventOnOk: props => (arr) => {
      props.setDateRange(arr);
      props.eventOnOk(arr);
    },
  }),
)(CalendarView);
