import { combineReducers } from 'redux';

import authReducer from './authReducer';
import usersReducer from './usersReducer';
import dashboardReducer from './dashboardReducer';
import requestReducer from './requestReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  dashboard: dashboardReducer,
  request: requestReducer,
});
