/**
 * @flow
 */

import React from 'react';

import {
  SelectDropDownWraper,
  SelectDropDownHeader,
  SelectName,
  SelectArrowUp,
  SelectArrowDown,
  SelectDropDownOptions,
  SelectDropDownMask,
} from './SelectDropDownStyled';

type SelectDropDownPropsType = {
  clicked: () => void,
  children: any,
  toggledOn: (any) => void,
  toggle: string,
  placeholder?: string,
  convertToArray: (any) => Array<any>,
  currentState: string,
  selectedValue?: string,
  setCurrentState: (any) => void,
};

export default function SelectDropDown(props: SelectDropDownPropsType) {
  let { children } = props;
  const isArray = Array.isArray(children);

  if (!isArray) {
    children = props.convertToArray();
  }

  const newChild = React.Children.map(children, child => React.cloneElement(child,
    { clickHandler: () => { props.setCurrentState(child.props.title); } }));

  return (
    <SelectDropDownWraper
      onClick={(props.clicked) ? props.clicked : null}
      onKeyDown={(e) => {
        if (e.key === 'Tab') {
          props.clicked();
          props.toggle();
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Tab') {
          props.clicked();
          props.toggle();
        }
      }}
      tabIndex='0'
    >
      <SelectDropDownHeader onClick={props.toggle}>
        <SelectName
          isPlaceholder={props.currentState === '' && !props.selectedValue}
        >
          { props.currentState || props.selectedValue || props.placeholder }
        </SelectName>
        {props.toggledOn ? <SelectArrowDown /> : <SelectArrowUp /> }
      </SelectDropDownHeader>
      {props.toggledOn && <SelectDropDownOptions onClick={props.toggle}>{ newChild }</SelectDropDownOptions>}
      {props.toggledOn && <SelectDropDownMask onClick={props.toggle} />}
    </SelectDropDownWraper>
  );
}

SelectDropDown.defaultProps = {
  selectedValue: '',
  placeholder: 'Выберите пункт',
};
