import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { socket, connection as socketConnection } from '../../../services/socket';

// view
import RequestFromView from './RequestFormView';

// actions
import { saveUserAccesses } from '../../../store/actions/auth';
import {
  setSecondaryBarItems,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
  setNewRequest,
} from '../../../store/actions/dashboard';

// services
import PayerService from '../../../services/PayerService';
import CounterpartyService from '../../../services/CounterpartyService';
import ShipperService from '../../../services/ShipperService';
import ConsigneeService from '../../../services/ConsigneeService';
import AutoagentService from '../../../services/AutoagentService';
import StationService from '../../../services/StationService';
import LoadService from '../../../services/LoadService';
import TerminalService from '../../../services/TerminalService';
import PaymentsService from '../../../services/PaymentsService';
import CitiesService from '../../../services/CitiesService';
import RailcarrierService from '../../../services/RailcarrierService';
import { RequestsService, UserPayersService } from '../../../services';
import { numberWithCommas } from '../../../helpers/RoundingNumbers';

const FIELDS = {
  number: {},
  state: {
    name: 'Предварительное',
  },
  stopSheet: {},
  dateLoad: {},
  timeLoad: {},
  typeSend: {},
  typeContainer: {},
  typeElivery: {},
  numberContainer: {},
  numberSeal: {},
  payer: {},
  destinationCity: {},
  shipper: {},
  consignee: {},
  railCarrier: {},
  railCarrierStatus: {},
  agentAutoLoad: {},
  ownerContainer: {},
  agentAutoCall: {},
  agentAutoCallStatus: {},
  companyMoversUnloading: {},
  companyMoversLoading: {},
  companyNameForwarder: {},
  addressLoading: {},
  addressUnloading: {},
  stationDeparture: {},
  stationDestination: {},
  loadActual: {},
  loadDocumented: {},
  weight: {},
  numberSeats: {},
  driver: {},
  driverOld: {},
  car: {},
  trailer: {},
  terminalStaging: {},
  instructionsDelivery: {},
  releases: {},
  company: {},
  manager: {},
  security: {},
  warming: {},
  warmingCompany: {},
  forwarderLoading: {},
  forwarderUnloading: {},
  movers: {},
  companyMovers: {},
  veterinaryCertificate: {},
  loadFastening: {},
  agentDocuments: {},
  returnOfDocumentsFromUnloading: {},
  dateArrival: {},
  dateIssue: {},
  plannedIssueDate: {},
  tracking: {},
  railwayInvoices: {},
  loading: {},
  loadingTN: {},
  unloadingTN: {},
  act: {},
  od: {},
  shipment: {},
  customerApplicationNumber: {},
  additionalServices: {},
  buh_amount: {},
  buh_carrier: {},
  buh_autoBeforeLoading: {},
  buh_forMKAD: {},
  buh_forMKAD_rate: {},
  buh_forMKAD_km: {},
  buh_containerAgent: {},
  buh_aboveTheNorm: {},
  buh_aboveTheNorm_rate: {},
  buh_aboveTheNorm_day: {},
  buh_forwarderOnLoading: {},
  buh_moversOnLoading: {},
  buh_moversAtUnloading: {},
  buh_veterinaryCertificate: {},
  buh_loadFastening: {},
  buh_warming: {},
  buh_additionalServices: {},
  buh_exportAgent: {},
  buh_overload_rate: {},
  buh_overload_ton: {},
  buh_overload: {},
  buh_simpleToUnload_rate: {},
  buh_simpleToUnload_hour: {},
  buh_simpleToUnload: {},
  buh_plain_rate: {},
  buh_plain_hour: {},
  buh_plain: {},
  buh_storageAtTheDestinationStation_rate: {},
  buh_storageAtTheDestinationStation_day: {},
  buh_storageAtTheDestinationStation: {},
  buh_seal: {},
  buh_bills: {},
  buh_agentToClient: {},
  buh_managerial: {},
  buh_agentSuppliers: {},
  buh_OBN: {},
  buh_total: {},
  buh_gasketMaterial: {},
  customerAccountNumber: {},
  customerAccountAmount: {},
  customerAccountDate: {},
  carrierAccount: {},
  accountsAutoServices: {},
  containerAgentAccount: {},
  exportAgentAccount: {},
  moversAccountOnLoading: {},
  moversAccountOnUnloading: {},
  accountSecuringCargo: {},
  billCargoWeatherization: {},
  moversAtUnloading: {},
  moversOnLoading: {},
  scheduledIssueTime: {},
  documentStatus: {},
  timeIssue: {},
  timofeevo: {},
  actualDateDispatch: {},
  plannedDispatchDate: {},
  timurovo: {},
  balance: {},
  exportAgentEurosib: {},
  containerAccessory: {},
  additionalLoadingAddress: {},
  cargoCode: {},
  payers: [{ name: '', id: 1, data: '' }],
  customerAccountNumberStatus: {},
  carrierAccountStatus: {},
  accountsAutoServicesStatus: {},
  containerAgentAccountStatus: {},
  exportAgentAccountStatus: {},
  moversAccountOnLoadingStatus: {},
  moversAccountOnUnloadingStatus: {},
  accountSecuringCargoStatus: {},
  billCargoWeatherizationStatus: {},
  accountStorageAtDestinationStation: {},
  accountStorageAtDestinationStationStatus: {},
  accountSimpleToUnload: {},
  accountSimpleToUnloadStatus: {},
  accountOverload: {},
  accountOverloadStatus: {},
  accountAboveTheNorm: {},
  accountAboveTheNormStatus: {},
  buh_amountMore: [],
};
const PAYMENT_FIELDS = [{
  amount: '',
  paymentNumber: '',
  date: '',
  type: 'payment',
  id: 1,
},
{
  amount: '',
  paymentNumber: '',
  date: '',
  type: 'containerAgentPayment',
  id: 2,
},
];
export default compose(
  connect(
    state => ({
      user: state.auth.currentUser,
      accesses: state.auth.userAccesses,
      checkStatus: state.dashboard.checkStatusPopup,
    }),
    dispatch => ({
      saveUserAccesses: data => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
      setNewRequest: item => dispatch(setNewRequest(item)),
    }),
  ),
  withState('autocompleteValues', 'setAutocompleteValues', {}),
  withState('edit', 'setEdit', false),
  withState('total', 'setTotal', null),
  withState('activePayersField', 'setActivePayersField', null),
  withState('fieldsValue', 'setFieldsValue', { ...FIELDS }),
  withState('changedPaymentFields', 'setChangedPaymentFields', []),
  withState('payments', 'setPayments', PAYMENT_FIELDS.map(item => {
    return {
      ...item,
    };
  })),
  withState('addedPaymentsField', 'setAddedPaymentField', []),
  withState('containerAgentPaymentCounter', 'setContainerAgentPaymentCounter', null),
  withState('paymentCounterAmount', 'setPaymentCounterAmount', null),
  withState('buhCounterAmountMore', 'setBuhCounterAmountMore', null),
  withState('fieldsWithErrors', 'setFieldsWithErrors', []),
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    calculation: props => (field, oldVal = 0, newVal = 0) => {
      if (field === 'buh_containerAgent' && newVal === '-') {
        return;
      }
      if (field === 'buh_simpleToUnload_rate' ||
        field === 'buh_simpleToUnload_hour' ||
        field === 'buh_plain_rate' ||
        field === 'buh_plain_hour' ||
        field === 'buh_storageAtTheDestinationStation_rate' ||
        field === 'buh_storageAtTheDestinationStation_day' ||
        field === 'buh_forMKAD_rate' ||
        field === 'buh_forMKAD_km' ||
        field === 'buh_aboveTheNorm_rate' ||
        field === 'buh_aboveTheNorm_day' ||
        field === 'buh_overload_rate' ||
        field === 'buh_overload_ton') {
          return;
        }
      const currentFieldsValue = { ...props.fieldsValue };
      if (currentFieldsValue.buh_total) {
        let total = 0;
        if (field === 'buh_amount') {
          total = Number(props.total) - Number(oldVal) + Number(newVal);
        } else {
          total = Number(props.total) - Number(newVal) + Number(oldVal === '-' ? '0' : oldVal);
        }
        props.setTotal(total.toFixed(2));
      }
    },
  }),
  withHandlers({
    updateInitOperation: props => requestId => {
      return RequestsService.getRequestById(requestId)
        .then(result => {
          Object.keys(result.doc).forEach(field => {
            if (field === 'payers') {
              const listPayers = result.doc[field].map((item, index) => {
                return { id: index + 1, name: item.name, data: item._id }
              });
              result.doc[field] = listPayers.length > 0 ? listPayers : [{ name: '', id: 1, data: ''}];
              return;
            }
            if (field === 'buh_amountMore') {
              const listBuhAmountMore = result.doc[field].map((item, index) => {
                return { id: index + 1, amount: item.amount, comment: item.comment, date: item.date, accountNumber: item.accountNumber, status: item.status }
              });
              result.doc[field] = listBuhAmountMore.length > 0 ? listBuhAmountMore : [];
              return;
            };
            if (typeof result.doc[field] === 'object' && result.doc[field]) {
              result.doc[field] = {...result.doc[field], name: result.doc[field].data};
            } else {
              result.doc[field] = {name: result.doc[field]};
            };
          });
          props.setEdit(true);
          props.setFieldsValue(result.doc, () => {
            if (result.doc.buh_total && result.doc.buh_total.name) {
              props.setTotal(result.doc.buh_total.name);
            } else {
              props.setTotal(0);
            };
          });
          if (props.accesses.directory.payments.findAll) {
            return PaymentsService.getById(result.doc._id.name);
          }
        })
        .then(paymentsData => {
          if (paymentsData && paymentsData.length !== 0) {
            const copyPayments = [].concat(paymentsData);
            const paymentsList = paymentsData.filter(payment => payment.type === 'payment');
            const containerList = paymentsData.filter(payment => payment.type === 'containerAgentPayment');
            let counterPayment = 0, counterContainer = 0;
            paymentsList.forEach(item => counterPayment = Number(counterPayment) + Number(item.amount));
            containerList.forEach(item => counterContainer = Number(counterContainer) + Number(item.amount));
            if (counterContainer === 0) {
              copyPayments.push({
                amount: '',
                paymentNumber: '',
                date: '',
                type: 'containerAgentPayment',
                id: 2,
              });
            };
            if (counterPayment === 0) {
              copyPayments.push({
                amount: '',
                paymentNumber: '',
                date: '',
                type: 'payment',
                id: 1,
              });
            };
            props.setContainerAgentPaymentCounter(counterContainer);
            props.setPaymentCounterAmount(counterPayment);
            props.setPayments(copyPayments);
            return;
          } else {
            return props.setPayments(PAYMENT_FIELDS.map(item => {
              return {
                ...item,
              };
            }));
          };
        })
        .catch(error => props.functionCheckTime('Error', error.message));
    },
    skipDependencies: props => (entity) => {
      const currentFieldsValue = { ...props.fieldsValue };

      switch (entity) {
        case 'payer':
          currentFieldsValue.consignee.name = '';
          currentFieldsValue.shipper.name = '';
          currentFieldsValue.addressLoading.name = '';
          currentFieldsValue.addressUnloading.name = '';
          currentFieldsValue.manager.name = '';
          break;
        case 'shipper':
          currentFieldsValue.addressLoading.name = '';
          break;
        case 'consignee':
          currentFieldsValue.addressUnloading.name = '';
          break;
        case 'agentAutoLoad':
          currentFieldsValue.car.name = '';
          currentFieldsValue.driver.name = '';
          currentFieldsValue.trailer.name = '';
          break;
        default:
          return;
      }
      props.setFieldsValue(currentFieldsValue);
    },
  }),
  withHandlers({
    saveHandler: props => (body, autoSave = false) => {
      let id;
      return RequestsService.addRequest(body)
        .then(response => {
          if (response.result._id) {
            id = response.result._id;
          } else {
            throw new Error('Не был получен id заявки');
          };
          return Promise.all(
            props.changedPaymentFields.map(item => PaymentsService.addPayment({
              ...item,
              request: response.result._id,
            }))
          );
        })
        .then(() => {
          if (autoSave) {
            props.history.replace(`/dashboard/requests/edit/${id}`);
            props.setChangedPaymentFields([]);
            return props.updateInitOperation(id);
          } else {
            if (props.accesses.directory.requests.findAll) {
              props.setSecondaryBarItems([]);
              return props.history.push('/dashboard/requests/');
            } else {
              props.setSecondaryBarItems([]);
              return props.history.push('/dashboard');
            };
          };
        })
        .catch(error => {
          throw new Error(error.message);
        });
    },
    updateHandler: props => body => {
      const requestId = props.location.pathname.split('/').pop();
      const requestField = [];
      const addedFields = [];
      return RequestsService.updateRequest(requestId, body)
        .then(() => {
          if (props.changedPaymentFields.length !== 0) {
            props.changedPaymentFields.forEach(item => {
              if (typeof item.id !== 'string') {
                addedFields.push({...item, request: requestId })
              } else {
                requestField.push(item);
              }
            })
          }
        })
        .then(() => {
          if (addedFields.length !== 0) {
            Promise.all(addedFields.map(item => PaymentsService.addPayment(item)))
              .then(() => PaymentsService.getById(requestId))
              .then(paymentsData => {
                if (paymentsData.length !== 0) {
                  const copyPayments = [].concat(paymentsData);
                  const paymentsList = paymentsData.filter(payment => payment.type === 'payment');
                  const containerList = paymentsData.filter(payment => payment.type === 'containerAgentPayment');
                  let counterPayment = 0, counterContainer = 0;
                  paymentsList.forEach(item => counterPayment = Number(counterPayment) + Number(item.amount));
                  containerList.forEach(item => counterContainer = Number(counterContainer) + Number(item.amount));
                  if (counterContainer === 0) {
                    copyPayments.push({
                      amount: '',
                      paymentNumber: '',
                      date: '',
                      type: 'containerAgentPayment',
                      id: 2,
                    });
                  };
                  if (counterPayment === 0) {
                    copyPayments.push({
                      amount: '',
                      paymentNumber: '',
                      date: '',
                      type: 'payment',
                      id: 1,
                    });
                  };
                  props.setContainerAgentPaymentCounter(counterContainer);
                  props.setPaymentCounterAmount(counterPayment);
                  props.setPayments(copyPayments);
              }})
              .catch(error => props.functionCheckTime('Error', error.message));
            }
        })
        .then(() => {
          if (requestField.length !== 0) {
            Promise.all(requestField.map(item => PaymentsService.updatePayment(item.id, item)));
          }
        })
        .then(() => props.setChangedPaymentFields([]))
        .catch(error => {
          throw new Error(error.message);
        });
    },
    selectedFieldValue: props => (field, value) => {
      const previousValue = props.fieldsValue;
      const currentFieldsValue = props.fieldsValue;
      currentFieldsValue[field] = value;
      if (field === 'payer' && value) {
        if (previousValue.payer.id !== currentFieldsValue.payer.id) {
          props.skipDependencies(field);
        };
        return UserPayersService.getLinkedUsers({payer: value.id})
          .then(data => {
            if (data.length) {
              const index = data.findIndex(item => item.roleID.rightName === 'salesman' || item.roleID.rightName === 'transactionSalesman');
              if (index !== -1) {
                currentFieldsValue.manager = data[index];
              };
            };
            return;
          })
          .catch(error => console.warn('[API GET USERS PAYERS]', error))
          .finally(() => {
            return props.setFieldsValue(currentFieldsValue, () => {
              props.setAutocompleteValues({});
            });
          });
      } else {
        return props.setFieldsValue(currentFieldsValue, () => {
          props.skipDependencies(field);
          props.setAutocompleteValues({});
        });
      };
    },
    selectPayersField: props => (value, id) => {
      const currentPayer = props.fieldsValue.payers.find(item => item.id === id);
      const filterPayer = props.fieldsValue.payers.filter(item => item.id !== id);
      const changedValue = [...filterPayer, { ...currentPayer, name: value.name, data: value.id }].sort((a, b) => a.id - b.id);
      props.setFieldsValue({...props.fieldsValue, payers: changedValue});
    },
    changePayersField: props => async (value, id) => {
      props.setActivePayersField(id);
      const currentPayer = props.fieldsValue.payers.find(item => item.id === id);
      const filterPayer = props.fieldsValue.payers.filter(item => item.id !== id);
      const changedValue = [...filterPayer, { ...currentPayer, name: value }].sort((a, b) => a.id - b.id);
      props.setFieldsValue({...props.fieldsValue, payers: changedValue});
      const result = await PayerService.searchItem({ search: value, limit: 3 });
      props.setAutocompleteValues({ payers: result });

      const fieldsWithErrorsFiltered = props.fieldsWithErrors.filter(item => item !== `payers${id}`);
      props.setFieldsWithErrors(fieldsWithErrorsFiltered);
      if (value && !result.length) {
        props.setFieldsWithErrors([...fieldsWithErrorsFiltered, `payers${id}`]);
      }
    },
    changeField: props => async (field, value, type) => {
      const currentFieldsValue = { ...props.fieldsValue };
      if (field.includes('buh_')) {
        props.calculation(field, currentFieldsValue[field].name, value);
      }
      currentFieldsValue[field].name = value;
      props.setFieldsValue(currentFieldsValue);

      if (type !== 'autocomplete') {
        return;
      }
      let currentService = null;
      let dependencies = [];
      switch (field) {
        case 'payer':
          if (!value) {
            props.skipDependencies(field);
          }
          currentService = PayerService;
          break;
        case 'consignee':
          if (value === '') {
            props.skipDependencies(field);
          }
          if (props.fieldsValue.payer.id) {
            const obj = await PayerService.getById(props.fieldsValue.payer.id);
            if (obj.relations[0].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          // currentService = ConsigneeService;
          break;
        case 'shipper':
          if (value === '') {
            props.skipDependencies(field);
          }
          if (props.fieldsValue.payer.id) {
            const obj = await PayerService.getById(props.fieldsValue.payer.id);
            if (obj.relations[1].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[1].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[1].items;
              }
            }
          }
          // currentService = ShipperService;
          break;
        case 'agentAutoLoad':
          if (value === '') {
            props.skipDependencies(field);
          }
          currentService = AutoagentService;
          break;
        case 'agentAutoCall':
        case 'exportAgentEurosib':
        case 'ownerContainer':
        case 'company':
        case 'companyMovers':
        case 'companyMoversUnloading':
        case 'companyMoversLoading':
        case 'companyNameForwarder':
        case 'warmingCompany':
        case 'forwarderLoading':
        case 'forwarderUnloading':
          currentService = CounterpartyService;
          break;
        case 'railCarrier':
          currentService = RailcarrierService;
          break;
        case 'addressLoading':
          if (props.fieldsValue.shipper.id) {
            const obj = await ShipperService.getById(props.fieldsValue.shipper.id);
            if (obj.relations[0].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          break;
        case 'addressUnloading':
          if (props.fieldsValue.consignee.id) {
            const obj = await ConsigneeService.getById(props.fieldsValue.consignee.id);
            if (obj.relations[0].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          // currentService = AddressService;
          break;
        case 'stationDeparture':
        case 'stationDestination':
          currentService = StationService;
          break;
        case 'destinationCity':
          currentService = CitiesService;
          break;
        case 'driver':
          if (props.fieldsValue.agentAutoLoad.id) {
            const obj = await AutoagentService.getById(props.fieldsValue.agentAutoLoad.id);
            const items = obj.relations[0].items.map(item => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value) {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = DriverService;
          break;
        case 'car':
          if (props.fieldsValue.agentAutoLoad.id) {
            const obj = await AutoagentService.getById(props.fieldsValue.agentAutoLoad.id);
            const items = obj.relations[1].items.map(item => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value) {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = CarService;
          break;
        case 'trailer':
          if (props.fieldsValue.agentAutoLoad.id) {
            const obj = await AutoagentService.getById(props.fieldsValue.agentAutoLoad.id);
            const items = obj.relations[2].items.map(item => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value) {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = TrailerService;
          break;
        case 'terminalStaging':
          currentService = TerminalService;
          break;
        case 'manager':
          if (props.fieldsValue.payer.id) {
            const result = await UserPayersService.getLinkedUsers({ payer: props.fieldsValue.payer.id });
            if (result.length > 0) {
              dependencies = result.map(item => ({
                ...item,
                id: item._id,
                name: `${item.firstName} ${item.lastName}`,
              }));
            }
          }
          break;
        case 'loadActual':
        case 'loadDocumented':
          currentService = LoadService;
          break;
        default:
          console.log('default service');
          break;
      }
      const result = (currentService)
        ? await currentService.searchItem({ search: value || '', limit: 3 })
        : dependencies;
      const autocompleteValues = {};
      autocompleteValues[field] = result;
      props.setAutocompleteValues(autocompleteValues);

      let dependenciesFields = [];
      if (!value) {
        switch (field) {
          case 'payer':
            dependenciesFields = ['consignee', 'shipper', 'addressLoading', 'addressUnloading', 'manager',];
            break;
          case 'shipper':
            dependenciesFields = ['addressLoading',];
            break;
          case 'consignee':
            dependenciesFields = ['addressUnloading',];
            break;
          case 'agentAutoLoad':
            dependenciesFields = ['car', 'driver', 'trailer',];
            break;
        }
      }

      const fieldsWithErrorsFiltered = props.fieldsWithErrors.filter(item => item !== field && !dependenciesFields.includes(item));
      props.setFieldsWithErrors(fieldsWithErrorsFiltered);
      if (value && autocompleteValues[field] && !autocompleteValues[field].length) {
        props.setFieldsWithErrors([...fieldsWithErrorsFiltered, field]);
      }
    },
    changePaymentFields: props => (field, value, id, type) => {
      const currentPayment = props.payments.find(item => item.id === id && item.type === type);
      const filterPayments = props.changedPaymentFields.filter(item => item.id !== id);
      props.setChangedPaymentFields([...filterPayments, {...currentPayment, [field]: value }]);
      const modifiedFields = props.payments.map(item => {
        if (item.id === id && item.type === type) {
          return { ...item, [field]: value }
        } else {
          return item;
        }
      });
      props.setPayments(modifiedFields);
    },
    clearAutocompleteValues: props => () => {
      if (Object.keys(props.autocompleteValues).length) {
        props.setAutocompleteValues({});
      }
    },
    autoCreate: props => () => {
      props.setEdit(true);
    },
    changeBuhAmountMoreField: props => (field, value, id) => {
      const currentBuhAmount = props.fieldsValue.buh_amountMore.find(item => item.id === id);
      const filterBuhAmount = props.fieldsValue.buh_amountMore.filter(item => item.id !== id);
      const changedValue = [...filterBuhAmount, {...currentBuhAmount, [field]: value}].sort((a, b) => a.id - b.id);
      if (field === 'amount') {
        props.calculation('buh_amount', currentBuhAmount.amount ? +currentBuhAmount.amount : 0, +value);
      }
      props.setFieldsValue({...props.fieldsValue, buh_amountMore: changedValue});
    },
  }),

  lifecycle({
    componentDidMount() {
      socketConnection();
      const pathname = this.props.location.pathname.split('/');
      RequestsService.getAvailableFields()
        .then(accessRoot => {
          Object.keys(accessRoot).forEach((item) => {
            accessRoot[item] = true;
          });
          this.props.saveUserAccesses({
            directory: this.props.accesses.directory,
            requests: accessRoot,
          });
          if (pathname[3] === 'edit') {
            this.props.updateInitOperation(pathname[4]);
          } else {
            Object.keys(FIELDS).forEach(key => {
              if (key === 'state') {
                FIELDS[key] = {name: 'Предварительное'};
              } else if (key === 'payers') {
                FIELDS[key] = [{ name: '', id: 1, data: "" }];
              } else if (key === 'buh_amountMore') {
                FIELDS[key] = [];
              } else if (key === 'companyAmixgroup') {
                FIELDS[key] = {name: true};
              } else {
                FIELDS[key] = {};
              };
            });
            this.props.setPayments(PAYMENT_FIELDS.map(item => {
              return {
                ...item,
              };
            }));
            this.props.setFieldsValue({...FIELDS}, () => {
              this.props.calculation();
            });
          };
          // this.props.setNewRequest(true);
        })
        .catch(error => this.props.functionCheckTime('Error', error.message));
        socket.on('table', ({ event, _id }) => {
          // if (event === 'update' && pathname[4] === _id) {
          //   this.props.updateInitOperation(_id);
          // }
          if (event === 'delete' && pathname[4] === _id) {
            this.props.history.push('/dashboard/requests');
            this.props.functionCheckTime('Success', 'Заявка была удалена другим пользователем')
          }
        });
    },
    componentWillUnmount() {
      socket.close();
      // this.props.setNewRequest(true);
      this.props.setSecondaryBarItems([]);
      this.props.setFieldsValue(FIELDS);
      this.props.setPayments(PAYMENT_FIELDS.map(item => {
        return {
          ...item,
        };
      }));
    },
  }),
)(RequestFromView);
