/**
 * @flow
 */
import React from 'react';
import { ThemeProvider } from 'styled-components';

import SideBarItem from './SideBarItem/SideBarItemContainer';
import {
  SideBarWrapper,
  SideBarExpander,
  Primary,
  SecondaryBar,
  SideBarLogo,
} from './SideBarStyled';

type SideBarViewPropsType = {
  isOpen?: boolean,
  secondaryBarItems: any,
  activeSecondaryItemHandler: (string) => void,
  activeSecondaryItem?: Object,
  secondaryBarHandler: (string) => void,
  menuOpenHandler: (any) => void,
  activeItem?: string,
  user: Object,
  access: Object,
  toggleLoad: Boolean,
};

export default function SideBarView(props: SideBarViewPropsType) {
  const theme = {
    isOpen: props.isOpen,
  };
  let secondaryItems = null;

  if (props.secondaryBarItems.length > 0) {
    secondaryItems = props.secondaryBarItems.map(item => (
      <SideBarItem
        name={item.name}
        key={item.id}
        id={item.id}
        clicked={() => props.activeSecondaryItemHandler(item)}
        active={props.activeSecondaryItem && props.activeSecondaryItem.id === item.id}
      />
    ));
  }

  return (
    <ThemeProvider theme={theme}>
      <SideBarWrapper className="side-bar">
        <Primary className="side-bar-primary">
          <SideBarLogo logoPath={require('../../assets/icons/logista.png')} />
          {props.access.directory.requests.findAll && (
            <SideBarItem
              icon={
                props.activeItem === 'requests'
                  ? require('../../assets/icons/assignment_active.svg')
                  : require('../../assets/icons/assignment.svg')
              }
              name="Заявки"
              clicked={() => props.secondaryBarHandler('requests')}
              active={props.activeItem === 'requests'}
              toggleLoad={props.toggleLoad}
            />
          )}
          {props.access.directory.directories.findAll && (
            <SideBarItem
              icon={
                props.activeItem === 'directories'
                  ? require('../../assets/icons/folder_active.svg')
                  : require('../../assets/icons/folder.svg')
              }
              name="Базы данных"
              clicked={() => props.secondaryBarHandler('directories')}
              active={props.activeItem === 'directories'}
              toggleLoad={props.toggleLoad}
            />
          )}
          {props.access.directory.roles.findAll &&
          props.access.directory.users.findAll ? (
            <SideBarItem
              icon={
                props.activeItem === 'users'
                  ? require('../../assets/icons/users_active.svg')
                  : require('../../assets/icons/users.svg')
              }
              name="Пользователи"
              clicked={() => props.secondaryBarHandler('users')}
              active={props.activeItem === 'users'}
              toggleLoad={props.toggleLoad}
            />
          ) : null}
          <SideBarExpander onClick={props.menuOpenHandler}>
            <div
              style={
                (!props.isOpen && { width: '90px', overflow: 'hidden' }) || null
              }>
              <SideBarItem
                icon={
                  props.isOpen
                    ? require('../../assets/icons/expander_active.svg')
                    : require('../../assets/icons/expander.svg')
                }
                toggleLoad={props.toggleLoad}
              />
            </div>
          </SideBarExpander>
        </Primary>
        {secondaryItems && <SecondaryBar>{secondaryItems}</SecondaryBar>}
      </SideBarWrapper>
    </ThemeProvider>
  );
}

SideBarView.defaultProps = {
  isOpen: false,
  activeItem: '',
  activeSecondaryItem: {},
};
