import {
  compose,
  withState,
  withHandlers,
  lifecycle,
  withProps,
} from 'recompose';
import { socket, connection as socketConnection } from '../../../services/socket';
import { connect } from 'react-redux';

// view
import RequestView from './RequestView';

// services
import {
  RequestsService,
  RequestsHistoryService,
  PaymentsService,
  UserService,
} from '../../../services';

// actions
import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
} from '../../../store/actions/dashboard';

import {
  saveUserAccesses,
} from '../../../store/actions/auth';

import { REQUEST_FIELDS } from '../../../constants';

const containerDeparts = [];

let infinitScrollRef = null;

export default compose(
  connect(
    state => ({
      activeSidebarItem: state.dashboard.activeItem,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      access: state.auth.userAccesses,
      checkStatus: state.dashboard.checkStatusPopup,
    }),
    dispatch => ({
      saveUserAccesses: data => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      setActiveSecondaryItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
    }),
  ),
  withState('request', 'setRequest', {}),
  withState('availableFields', 'setAvailableFields', {}),
  withState('historyRequest', 'setHistoryRequest', []),
  withState('isLoading', 'setIsLoading', false),
  withState('payments', 'setPayments', {}),
  withState('hasMore', 'setHasMore', true),
  withState('dateRange', 'setDateRange', null),
  withState('pages', 'setPages', 1),
  withProps({
    infinitScrollerRef: (ref) => { infinitScrollRef = ref; },
  }),
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    initData: props => async (id, withSocket = false) => {
      if (!withSocket) props.setIsLoading(true);
      UserService.getRight()
        .then((item) => {
          props.saveUserAccesses({
            directory: item.result.directories,
            requests: item.result.requests,
          });
        })
        .catch(err => console.log('err', err));
      // get request from API
      try {
        const request = await RequestsService.getRequestById(id);
        props.setRequest(request.doc);
        props.setAvailableFields(request.headers);
      } catch (err) {
        console.log(err);
      }
      // get history from API
      try {
        if (props.access.directory.requestsHistory.findAll) {
          const result = await RequestsHistoryService.getAllRequestsHistory({ request: id });
          props.setHistoryRequest(result.history);
          props.setPages(result.pages);
        }
      } catch (err) {
        console.log(err);
      }
      // get request payments
      try {
        if (props.access.directory.payments.findAll) {
          const paymentsResponse = await PaymentsService.getById(id);

          const payments = {};

          paymentsResponse.forEach((payment) => {
            if (!(payment.type in payments)) {
              payments[payment.type] = [];
            }
            payments[payment.type].push(payment);
          });
          props.setPayments(payments);
        }
      } catch (error) {
        console.log('Request payments error', error);
      }
      if (!withSocket) props.setIsLoading(false);
    },
    addRequest: props => () => {
      props.history.push('/dashboard/requests/create');
    },
    back: props => () => {
      props.history.push('/dashboard/requests');
    },
    getExcel: props => async () => {
      try {
        const response = await RequestsService.getRequestsExcel({ id: `["${props.request._id}"]` });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err);
      }
    },
    changeDateHistory: props => async (dateRange) => {
      // get request history from API
      try {
        props.setHistoryRequest([]);
        props.setPages(1);
        props.setDateRange(dateRange);
        props.setHasMore(true);
        infinitScrollRef.pageLoaded = 0;
        if (props.request._id) {
          const result = await RequestsHistoryService.getAllRequestsHistory({
            request: props.request._id,
            daterange: dateRange,
          });
          props.setHistoryRequest(result.history);
          props.setPages(result.pages);
        }
      } catch (err) {
        console.log(err);
      }
    },
    editRequest: props => () => {
      props.history.push(`/dashboard/requests/edit/${props.request._id}`);
    },
    deleteRequest: props => async () => {
      try {
        await RequestsService.deleteRequest(props.request._id);
        props.history.push('/dashboard/requests');
        props.functionCheckTime('Success', 'Успешно удалено');
      } catch (err) {
        console.log(err);
      }
    },
    scrollEvent: props => async () => {
      containerDeparts.forEach((item) => {
        if (window.event.layerY > item.lenghtTop) {
          props.setActiveSecondaryItem(item.data);
        }
      });
    },
    loadMore: props => async (p) => {
      try {
        if (p > props.pages) {
          props.setHasMore(false);
          return;
        }
        const pathname = props.location.pathname.split('/');
        const result = await RequestsHistoryService.getAllRequestsHistoryWithPages({
          request: pathname[4],
          page: p,
          daterange: props.dateRange,
        });
        props.setHistoryRequest([...props.historyRequest, ...result.history]);
      } catch (error) {
        console.log('error', error);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      socketConnection();
      const pathname = this.props.location.pathname.split('/');
      await this.props.initData(pathname[4]);

      const requests = this.props.access.requests;

      const customerservice = REQUEST_FIELDS[0].items.map(item => item.name);
      const shipmentdepartment = REQUEST_FIELDS[1].items.map(item => item.name);
      const autodepartment = REQUEST_FIELDS[2].items.map(item => item.name);
      const documentdepartment = REQUEST_FIELDS[3].items.map(item => item.name);
      const financialdepartment = REQUEST_FIELDS[4].items.map(item => item.name);
      const billnumbers = REQUEST_FIELDS[5].items.map(item => item.name);

      const hasCustomerservice = customerservice.some(item => requests[item]);
      const hasShipmentdepartment = shipmentdepartment.some(item => requests[item]);
      const hasAutodepartment = autodepartment.some(item => requests[item]);
      const hasDocumentdepartment = documentdepartment.some(item => requests[item]);
      const hasFinancialdepartment = financialdepartment.some(item => requests[item]);
      const hasBillnumbers = billnumbers.some(item => requests[item]);

      let LIST_OF_DEPARTMENTS = [];

      if (hasCustomerservice) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1001',
          name: 'Отдел обслуживания клиентов',
          url: `${pathname[4]}#customerservice`,
        }];
      }
      if (hasShipmentdepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1002',
          name: 'Отдел отправки',
          url: `${pathname[4]}#shipmentdepartment`,
        }];
      }
      if (hasAutodepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1003',
          name: 'Автоотдел',
          url: `${pathname[4]}#autodepartment`,
        }];
      }
      if (hasDocumentdepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1004',
          name: 'Отдел вывоз в регионы/документы',
          url: `${pathname[4]}#documentdepartment`,
        }];
      }
      if (hasFinancialdepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1005',
          name: 'Финансовый отдел',
          url: `${pathname[4]}#financialdepartment`,
        }];
      }
      if (hasBillnumbers) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1006',
          name: 'Номера счетов',
          url: `${pathname[4]}#billnumbers`,
        }];
      }

      // формирование меню
      this.props.setSecondaryBarItems(LIST_OF_DEPARTMENTS);

      LIST_OF_DEPARTMENTS.forEach((item) => {
        const hashDeparts = item.url.split('#');
        if (hashDeparts.length === 2) {
          const hash = hashDeparts.pop();
          const element = document.querySelector(`#${hash}`);
          if (element) {
            containerDeparts.push({
              data: item,
              lenghtTop: element.offsetTop,
            });
          }
        }
      });

      this.props.setActiveSecondarySidebarItem({
        id: '1001',
        name: 'Основная информация',
        url: `${pathname[4]}#basic`,
      });
      socket.on('table', ({ event, _id }) => {
        if (event === 'update' && pathname[4] === _id) {
          this.props.initData(_id, true);
        }
        if (event === 'delete' && pathname[4] === _id) {
          this.props.history.push('/dashboard/requests');
          this.props.functionCheckTime('Success', 'Успешно удалено');
        }
      });
    },
    componentWillUnmount() {
      socket.close();
    },
  }),
)(RequestView);
