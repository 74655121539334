import styled from 'styled-components';

import { base, colors } from '../../styles';

export const LinkedItemHover = styled.div`
    position: absolute;
    font-size: 16px;
    border-radius: 8px;
    width: ${base * 31}px;
    margin-right: ${base * 1.3}px;
    top: -6px;
    left: -${base * 32}px;
    background-color: ${colors.white};
    &>span{
        display: none;
        padding:12px 0 12px 24px;
    }
`;

export const LinkedItemDefolt = styled.div`
    display: flex;
    align-items: center;
    padding-right: 38px;
    cursor: pointer;
    &>span{
        font-size: 18px;
        text-overflow: ellipsis;
        opacity: 0.75;
        white-space: nowrap;
        overflow: hidden;
    }
    &>button{
        width: 24px;
        height: 32px;
        padding: 0 16px;
        background: url(${require('../../assets/icons/close-hover.svg')});
        background-size: 30%;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        margin: 0;
    }
    &:hover>button{
        opacity: 1;
        background: url(${require('../../assets/icons/close-hover.svg')});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30%;
    }
`;

export const LinkedContainer = styled.div`
    position: relative;
    &:hover{
        ${props => props.theme.value}
        background-color: ${colors.white_smoke};
        ${LinkedItemHover} {
            box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
            &>span{
                display: block;
            }
        }
    }
`;
