import styled from 'styled-components';

import { base, colors, screens } from '../../styles';

export const NavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  height: 38px;
  width: calc(100% - 48px);
  box-shadow: 10px 0px 12px 0px rgba(0, 0, 0, 0.08);
  padding: 12px 24px;
  position: fixed;
  z-index: 101;
  height: ${base * 4.5}px;

  & > .nav-bar-child {
    display: flex;
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  width: 100px;
  height: 32px;
  cursor: pointer;
  background-image: url(${require('../../assets/icons/logista.png')});
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 42px;

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  margin-right: 12px;
  & > button{
    margin-right: 12px;
  }
`;

export const MobileNavbar = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: ${screens.screen_xs_max}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AddButtonWrapper = styled.div`
  margin-right: 24px;
`;

// only mobile
export const HideComponent = styled.div`
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;
