/**
 * @flow
 */

import React from 'react';
import Calendar from 'rc-calendar';
import ruRu from 'rc-calendar/lib/locale/ru_RU';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { InputComponent, InputWrapper } from '../Input/InputStyled';

import {
    InputMaskAndCalendar,
    CalendarMask,
} from './InputMaskAndCalendarStyled';
import {
    CalendarContainer,
} from '../RequestForm/RequestFormStyles';

type InputViewPropsType = {
    mask: String,
    formatChars: Object,
    value: any,
    valueCalendar: any,
    placeholder?: string,
    changed: (any) => void,
    paymentDateCalendar: (any) => void,
    setPaymentDateCalendar: (any) => void,
    formatStr: String,
    onFocusChanged: (any) => void,
    onChangeCalendar: (any) => void,
    onClearCalendar: (any) => void,
    id?: string,
};

export default function InputView(props: InputViewPropsType) {
  return (
    <InputMaskAndCalendar lastCalendar={props.lastCalendar}>
        <InputMask
            mask="12-39-9999"
            formatChars={{
              1: '[0-3]',
              2: '[0-9]',
              3: '[0-1]',
              9: '[0-9]',
            }}
            value={props.value
                ? moment(props.value).format('DD-MM-YYYY')
                : null
              }
            onChange={(event) => {
                props.changed(event);
                if (event.target.value.indexOf('_') === -1 && event.target.value.length > 0) {
                    props.setPaymentDateCalendar(false);
                } else {
                    props.setPaymentDateCalendar(true);
                }
            }}
            placeholder={props.placeholder}
            onFocus={()=> {
                props.onFocusChanged();
                props.setPaymentDateCalendar(true);
            }}
        >
            {inputProps => (
                <InputWrapper>
                    <InputComponent
                        {...inputProps}
                        valid
                        readOnly={props.readOnly}
                        id={props.id}
                    />
                </InputWrapper>
            )}
        </InputMask>

        {props.paymentDateCalendar
            && (
                <CalendarContainer onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setPaymentDateCalendar(false);
                    }
                  }}>
                    <Calendar
                        // onChange={(value) => {
                        //     props.onChangeCalendar(value, props.setPaymentDateCalendar);
                        // }}
                        onSelect={value => {
                            props.onSelectChanged(value);
                            props.setPaymentDateCalendar(false);
                        }}
                        onClear={() => {

                            props.setPaymentDateCalendar(false);
                        }}
                        format={props.formatStr}
                        value={props.valueCalendar}
                        locale={ruRu}
                        
                    />
                </CalendarContainer>
                    )
        }
        {props.paymentDateCalendar && <CalendarMask onClick={() => props.setPaymentDateCalendar(false)}></CalendarMask>}
    </InputMaskAndCalendar>
  );
}

InputView.defaultProps = {
};
