import styled from 'styled-components';
import { base, colors } from '../../styles';

export const Container = styled.div`
  position: relative;
  margin-right: 12px;
`;


export const Input = styled.input`
  font-size: 12px;
  height: 42px;
  width: 220px;
  border: 1px solid ${colors.primary_white_gray};
  padding: 0 12px;
  border-radius: ${base - 4}px;
  box-sizing: border-box;
  background-color: transparent;

  &::placeholder {
    color: #8e8e8e;
  }
`;

export const Button = styled.button`
  height: 42px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: ${({ isOpen }) => (isOpen ? `url(${require('../../assets/icons/drop-down-up.svg')})` : `url(${require('../../assets/icons/drop-down.svg')})`)};
  background-position: center right 19px;
  background-repeat: no-repeat;
  background-size: 9px;
`;

export const List = styled.div`
  position: absolute;
  top: calc(42px + 8px);
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid ${colors.primary_white_gray};
  padding: 6px 10px;
  z-index: 8;
`;

export const Item = styled.div`
  padding: 3px 0;
  color: #9b9b9b;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.button`
  height: ${base * 3.6}px;
  width: ${base * 3.6}px;
  position: absolute;
  top: 0;
  right: ${base * 3.6}px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: url(${require('../../assets/icons/close-default.svg')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 9px;
  z-index: 10;
`;

export const Mark = styled.p`
  width: 3px;
  height: 23px;
  background-color: ${({ color }) => color};
  margin-right: 5px;
`;

export const InputMark = styled.p`
  position: absolute;
  width: 6px;
  height: 43px;
  top: 0;
  left: 0;
  background-color:  ${({ color }) => color};
  border-radius: 9px 0 0 9px;
`;
