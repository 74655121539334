/**
 * @flow
 */
import React from 'react';

import {
  Checkbox,
  Button,
  SelectDropDown,
  SelectDropDownItem,
  Input,
} from '../../../components';

import {
  MainPage,
  TitleCust,
  CustColumnsMain,
  NameColumns,
  CheckboxContainer,
  HeaderTitle,
  PopupCustTitle,
  ColumnsSection,
  CheckBoxWrapper,
  SectionTitleWrapper,
} from '../Profile/CustColumns/CustColumnsStyled';

import {
  ContentSelect,
} from './RequestChangeRoleStyled';

type RequestChangeRolePropsType = {
    listRole: Array<Object>,
    accessRoles: Array<Object>,
    openRoleEdit?: Boolean,
    roles: any,
    roleIDAccessRightName: (any) => void,
    listRequest: Array<Object>,
    listRequestAccess: Array<Object>,
    setCheckValueRequest: (any) => void,
    setCheckValueRole: (any) => void,
    saveData: (any) => void,
    idState: any,
    urlState: any,
    addRole: (any) => void,
    addRoleSection?: Boolean,
    setInputRoleName: (any) => void,
    setInputRoleAddress: (any) => void,
    saveBtn: Boolean,
    protectionState: Boolean,
    createBtn: Boolean,
    inputRoleName: any,
    inputRoleAddress: any,
    deleteRole: (any) => void,
    createData: (any) => void,
    skipDropDown: Boolean,
    inputRoleAddress: String,
};

const checkAvailableCheckbox = (urlState, item, checkbox) => {
  return (urlState === 'client' || urlState === 'provider')
    && (checkbox === 'insert' || checkbox === 'update')
    && item === 'requests';
};

const renderListRole = (listRole, setCheckValueRole, accessRoles, urlState) => (Object.keys(listRole).map(item => (
  <div key={item}>
    <SectionTitleWrapper>
      <NameColumns>{listRole[item].translation}</NameColumns>
    </SectionTitleWrapper>
    <CheckboxContainer>
      {Object.keys(listRole[item].methods).map(checkbox => (
        <CheckBoxWrapper key={checkbox}>
          <Checkbox
            message={listRole[item].methods[checkbox]}
            value={accessRoles[item][checkbox]}
            name={checkbox}
            onClickItem={() => { setCheckValueRole(item, accessRoles[item][checkbox], checkbox); }}
            disabled={checkAvailableCheckbox(urlState, item, checkbox)}
          />
        </CheckBoxWrapper>
      ))}
    </CheckboxContainer>
  </div>
)));

const renderListRequest = (listRequest, setCheckValueRequest, listRequestAccess) => (Object.keys(listRequest).map(item => (
  <CheckBoxWrapper key={item}>
    <Checkbox
      message={listRequest[item]}
      value={listRequestAccess[item]}
      name={item}
      onClickItem={() => { setCheckValueRequest(item); }}
    />
  </CheckBoxWrapper>
)));

const regular = new RegExp(/^\w{0,}$/);
export default function RequestChangeRoleView(props: RequestChangeRolePropsType) {
  return (
    <MainPage>
      <HeaderTitle>
        <TitleCust>
          <span>Настройка</span>
          <span>роли</span>
        </TitleCust>
        {props.saveBtn
          ? (
            <div style={{ display: 'flex' }}>
              {(props.access.directory.roles.update)
                ? (
                  <div style={{ marginRight: '24px' }}>
                    <Button
                      clicked={() => {
                        props.saveData(props.idState, props.inputRoleName, props.urlState);
                      }}
                    >
                      Сохранить
                    </Button>
                  </div>
                ) : null
              }
              {(props.access.directory.roles.delete)
                ? (
                  <Button
                    clicked={() => {
                      props.deleteRole(props.idState);
                    }}
                    disabled={props.protectionState}
                  >
                  Удалить
                  </Button>
                ) : null
              }
            </div>
          )
          : null
        }
        {(props.createBtn && props.access.directory.roles.insert)
          ? (
            <Button
              clicked={() => {
                props.createData(props.inputRoleName, props.inputRoleAddress);
              }}
            >
              Создать
            </Button>
          )
          : null
        }
      </HeaderTitle>
      <CustColumnsMain>
        <p>Настройте привелегии для ролей </p>
        <ContentSelect>
          <SelectDropDown
            placeholder="Выберите должность"
            skip={props.skipDropDown}
          >
            {
            props.roles.map(item => (
              <SelectDropDownItem
                key={item.id}
                title={item.name}
                selectOption={() => {
                  props.roleIDAccessRightName(item.id, item.name, item.url, item.protection);
                }}
              />
            ))
          }
            {(props.access.directory.roles.insert)
              ? (
                <SelectDropDownItem
                  title="Добавить новую роль"
                  selectOption={() => {
                    props.addRole();
                  }}
                />
              ) : (
                <SelectDropDownItem
                  title=""
                  selectOption={() => {
                    console.log('click');
                  }}
                />
              )
          }
          </SelectDropDown>
        </ContentSelect>
        {props.addRoleSection
          ? (
            <div>
              <ContentSelect>
                <Input
                  type="text"
                  placeholder="Введите название новой роли"
                  value={props.inputRoleName}
                  changed={(value) => { props.setInputRoleName(value); }}
                />
              </ContentSelect>
              <ContentSelect>
                <Input
                  type="text"
                  placeholder="Введите адресное название роли"
                  value={props.inputRoleAddress}
                  changed={(value) => {
                    if (regular.test(value)) { props.setInputRoleAddress(value); }
                  }}
                />
              </ContentSelect>
            </div>
          )
          : null
        }
        {props.saveBtn
          ? (
            <ContentSelect>
              <Input
                type="text"
                placeholder="Введите адресное название роли"
                value={props.inputRoleName}
                changed={(value) => { props.setInputRoleName(value); }}
              />
            </ContentSelect>
          )
          : null
        }
        {props.openRoleEdit
          ? (
            <ColumnsSection>
              {renderListRole(props.listRole, props.setCheckValueRole, props.accessRoles, props.urlState)}
              <SectionTitleWrapper>
                <NameColumns>Настройка доступа к заявкам</NameColumns>
              </SectionTitleWrapper>
              <CheckboxContainer>
                {renderListRequest(props.listRequest, props.setCheckValueRequest, props.listRequestAccess)}
              </CheckboxContainer>
            </ColumnsSection>
          )
          : null
        }
      </CustColumnsMain>
    </MainPage>
  );
}

RequestChangeRoleView.defaultProps = {
  openRoleEdit: false,
  addRoleSection: false,
};
