/**
 * @flow
 */

import React from 'react';

import {
  Button,
} from '../index';

import {
  Paragraph,
  AddItem,
  Container,
} from './PayerInfoBlockStyled';

type InfoBlockPropsType = {
  title: string,
  addItem: (any) => void,
  items: Array<string>,
};

export default function InfoBlock(props: InfoBlockPropsType) {
  return (
    <Container>
      <AddItem>
        <Paragraph smallParagraph>{props.title}</Paragraph>
        {
          props.addItem && (
            <Button circle clicked={() => props.addItem()}>
              <img
                style={{ width: '8px', transform: 'rotate(45deg)' }}
                src={require('../../assets/icons/close-hover.svg')}
                alt="plus"
                transform="rotate(45deg)"
              />
            </Button>
          )
        }
      </AddItem>
      {props.items}
    </Container>
  );
}
