import React from 'react';
import Downshift from 'downshift';

import {
  List,
  Item,
  Button,
  Container,
  Input,
} from './SelectStyles';

type SelectViewType = {
  onChange: () => void,
  list: Array<any>,
  placeholder: string,
  itemValue: object,
  autocomplete: boolean,
  onInputValueChange: () => void,
  disabled: Boolean,
  itemName?: string,
  fieldsWithErrors?: Array<string>,
};

export default function SelectView(props: SelectViewType) {
  return (
    <Downshift
      onChange={selection => props.onChange(selection)}
      itemToString={item => (item ? item.name : '')}
      inputValue={props.itemValue.name || ''}
      selectedItem={props.itemValue}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        inputValue,
        openMenu,
      }) => (
        <Container {...getRootProps()}>
          <Input
            {...getInputProps({
              type: 'text',
              placeholder: props.placeholder,
              disabled: props.disabled,
              onFocus() {
                openMenu();
                props.onInputValueChange(inputValue);
              },
              onChange(e) {
                props.onInputValueChange(e.target.value);
              },
            })}
            autocomplete={props.autocomplete}
            itemName={props.itemName}
            fieldsWithErrors={props.fieldsWithErrors}  
            id={props.itemName}          
          />
          {isOpen && (
            <List {...getMenuProps()}>
              {props.list.map((item, index) => (
                <Item
                  {...getItemProps({
                    key: index,
                    item,
                    style: {
                      color: highlightedIndex === index ? '#da350a' : '#9b9b9b',
                      fontWeight: selectedItem.name === item.name || item.name.includes('создать справочник') ? 'bold' : 'normal',
                      fontSize: item.name.includes('создать справочник') ? '14px' : '12px',
                    },
                  })}
                >
                  {item.name}
                </Item>
              ))}
            </List>
          )}
          {!props.autocomplete && <Button {...getToggleButtonProps()} isOpen={isOpen} tabIndex="-1" />}
        </Container>
      )}
    </Downshift>
  );
}
