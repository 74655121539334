/**
 * @flow
 */
import React from 'react';

import {
  TimeZoneContainer,
  CityName,
  CityTime,
} from './TimeZoneStyled';

type TimeZonePropsType = {
  cityName: string,
  time: string,
};

export default function TimeZoneView(props: TimeZonePropsType) {
  return (
    <TimeZoneContainer>
      <CityName>{props.cityName}</CityName>
      <CityTime>{props.time}</CityTime>
    </TimeZoneContainer>
  );
}
