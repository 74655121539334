import React from 'react';
import onClickOutside from "react-onclickoutside";

import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import { connect } from 'react-redux';

import {
  setToggleDrop,
} from '../../store/actions/dashboard';

import DropdownView from './DropdownView';
import DropdownItem from './DropdownItem/DropdownItemContainer';

export default compose(
  connect(
    state => ({
      toggleDrop: state.dashboard.toggleDrop,
    }),
    dispatch => ({
      setToggleDrop: item => dispatch(setToggleDrop(item)),
    }),
  ),
  withState('toggledOn', 'toggle', false),
  withHandlers({
    toggle: ({ toggle }) => () => toggle(current => !current),
    convertToArray: props => () => React.Children.toArray(props.children),
    handleClickOutside: props => () => {
      props.setToggleDrop(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      let { children } = this.props;

      React.Children.forEach(children, (child) => {
        if (child.type !== DropdownItem) {
          throw new Error('Dropdown children should be of type `DropdownItem`.');
        }
      });

      const isArray = Array.isArray(children);

      if (!isArray) {
        children = this.props.convertToArray();
      }
    },
  }),
  onClickOutside,
)(DropdownView);
