import styled, { css } from 'styled-components';

import { base, screens } from '../../styles';

export const PageTitle = styled.h1`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 36px;
  color: black;
  font-weight: 300;

  ${props => props.dashboard && css`
    margin-bottom: 0;
  `};

  @media (max-width: ${screens.screen_sm_max}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${screens.screen_xs_max}) {
    font-size: 24px;
  }

`;
