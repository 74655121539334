import { compose, withState, lifecycle } from 'recompose';

import CheckboxView from './CheckboxView';

export default compose(
  withState('status', 'setCheck', false),
  lifecycle({
    componentDidMount() {
      if (this.props.value) {
        this.props.setCheck(true);
      }
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value) {
        this.props.setCheck(nextProps.value);
      }
    },
  }),
)(CheckboxView);
