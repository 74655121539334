/**
 * @flow
 */

import React from 'react';

import {
  StampItem,
} from './StampFilterItemStyled';

type StampFilterItemPropsType = {
    link?: string,
    value?: string,
    title?: string,
    clickItemF: (any) => any,
};

export default function StampFilterItem(props: StampFilterItemPropsType) {
  return (
    <StampItem
      link={props.link}
      value={props.value}
      onClick={props.clickItemF}
    >
      {props.title}
    </StampItem>
  );
}

StampFilterItem.defaultProps = {
  link: null,
  value: null,
  title: null,
};
