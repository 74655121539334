import styled, { css } from 'styled-components';

import { base, screens, colors } from '../../styles';

export const Page = styled.div`
  margin: 0;
  width: 100%;
  position: relative;

  @media (max-width: ${screens.screen_xs_max}) {
    transition: transform .3s ease-in-out;
    ${props => props.theme.openMenu && css`
      transform: translateX(90px);
    `}
  }
`;

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  padding-top: 87px;
`;

export const SideBarPage = styled.div`
  height: 100%;
  position: sticky;
  top: 0;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-top: -${base * 5.6}px;
  }
`;

export const ContentArea = styled.div`
  width: calc(100% - 160px);
  height: auto;
  min-height: calc(100vh - 84px);
  box-sizing: border-box;
  margin-top: 32px;
  transition: margin-left .3s ease-in-out;
  ${(props) => {
    let styles = null;
    if (props.theme.openMenu && props.theme.secondaryBar.length > 0) {
      styles = css`margin-left: 460px; width: calc(100% - 502px);`;
    } else if (props.theme.openMenu) {
      styles = css`margin-left: 246px; width: calc(100% - 284px);`;
    } else if (props.theme.secondaryBar.length > 0) {
      styles = css`margin-left: 338px; width: calc(100% - 382px);`;
    } else {
      styles = css`margin-left: 114px;`;
    }
    return styles;
  }}
  position: relative;
  overflow: hidden;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-left: 0;
    padding: 24px 64px 0;
  }
`;

export const MobileViewSidebar = styled.span`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    margin-bottom: 12px;
    overflow: hidden;
    width: ${base * 19}px;
  }
`;
