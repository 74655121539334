/**
 * @flow
 */

import React from 'react';

import {
  DropdownWraper,
  UserName,
  DropdownHeader,
  DropdownOptions,
} from './DropdownStyled';

type DropdownPropsType = {
  avatar: string,
  name: string,
  children: any,
  toggledOn: (any) => void,
  toggle: string,
  convertToArray: (any) => Array<any>,
  toggleDrop: Boolean,
};

export default function Dropdown(props: DropdownPropsType) {
  let { children } = props;
  const isArray = Array.isArray(children);

  if (!isArray) {
    children = props.convertToArray();
  }

  return (
    <DropdownWraper>
      <DropdownHeader onClick={() => props.setToggleDrop(true)}>
        <UserName>{props.name}</UserName>
        <img
          style={{
            marginLeft: '10px',
            height: '5.5px',
          }}
          src={require('../../assets/icons/dropdown-arrow.svg')}
          alt="arrow"
        />
      </DropdownHeader>
      {props.toggleDrop && <DropdownOptions onClick={() => props.setToggleDrop(false)}>{ children }</DropdownOptions>}
    </DropdownWraper>
  );
}
