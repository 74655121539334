import {compose, withState, withHandlers, lifecycle} from 'recompose';
import {connect} from 'react-redux';
import moment from 'moment';

import {socket, connection as socketConnection} from '../../../services/socket';

import {saveUserAccesses} from '../../../store/actions/auth';
import {changeShowRatingModal, setLastIndexRowRequest} from '../../../store/actions/request';
import {BUH_TOTAL_INPUT, ROLES_NAME} from '../../../constants';
import {colors} from '../../../styles';

import {
  AddressService,
  AutoagentService,
  CarService,
  ConsigneeService,
  CounterpartyService,
  DriverService,
  LoadService,
  PayerService,
  RequestsService,
  ShipperService,
  StationService,
  TerminalService,
  TrailerService,
  UserService,
  CitiesService,
} from '../../../services';

import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setExpand,
  setNewRequest,
  checkStatusPopup,
} from '../../../store/actions/dashboard';

import RequestTableView from './RequestsTableView';

import {numberWithCommas} from '../../../helpers/RoundingNumbers';

export default compose(
  connect(
    state => ({
      activeSidebarItem: state.dashboard.activeItem,
      user: state.auth.currentUser,
      expand: state.dashboard.expand,
      access: state.auth.userAccesses,
      showRatingModal: state.request.showRatingModal,
      lastIndexRowRequest: state.request.lastIndexRowRequest,
    }),
    dispatch => ({
      saveUserAccesses: data => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      setExpand: state => dispatch(setExpand(state)),
      setNewRequest: item => dispatch(setNewRequest(item)),
      changeShowRatingModal: data => dispatch(changeShowRatingModal(data)),
      checkStatusPopup: data => dispatch(checkStatusPopup(data)),
      setLastIndexRowRequest: data => dispatch(setLastIndexRowRequest(data)),
    }),
  ),

  //Отвечает за отображение лоадинга
  withState('isLoading', 'setIsLoading', true),
  //Отвечает за состояние подгрузки данныъ
  withState('isDownload', 'setIsDownload', false),
  //Сколько получать данных на 1 странице
  withState('countRecords', 'setCountRecords', 100),
  //Текущая сатраница
  withState('page', 'setPage', 1),
  //Расшифровка полей
  withState('availableFields', 'setAvailableFields', {}),
  //Хранится хеадер в формате AG-GRID
  withState('tableHeader', 'setTableHeader', []),
  //Хранятся данные для таблицы
  withState('tableData', 'setTableData', []),
  //Хранятся параметры AG-GRID
  withState('tableParams', 'setTableParams', null),
  //Структура фильтрации таблицы
  withState('filters', 'setFilters', {}),
  //Состояние фильтра (применен или нет)
  withState('filtersActive', 'setFiltersActive', false),
  //Состояние фильтра для отображения точки над иконкой (применен или нет)
  withState('filtersActivePoint', 'setFiltersActivePoint', false),
  //Значение полнотекстового поиска
  withState('searchValue', 'setSearchValue', ''),
  //Значение фильтра по статусу
  withState('selectedStatus', 'setSelectedStatus', ''),
  //Таймер обновления таблицы, исходя из значения полнотекстового поиска
  withState('timerState', 'setTimerState', ''),
  //параметр фильтра по диапазону дат
  withState('dateFilters', 'setDateFilters', ''),
  withState('rangeDate', 'setRangeDate', []),
  //Статус фильтрации по дате создания
  withState('dateFiltersActive', 'setDateFiltersActive', false),
  //Состояние отображения календаря, в фильтре по диапазону дат
  withState('viewCalendar', 'setViewCalendar', false),
  //Типы полей
  withState('typeFields', 'setTypeFields', {
    number: {
      type: 'input',
      inputType: 'number',
    },
    state: {
      type: 'select',
      values: ['Предварительное', 'В пути', 'Прибыл',
        'Выдан клиенту', 'Прогон', 'Закрыта', 'Вывоз'],
    },
    dateLoad: {
      type: 'input',
      inputType: 'date',
    },
    plannedIssueDate: {
      type: 'input',
      inputType: 'date',
    },
    timeLoad: {
      type: 'input',
      inputType: 'text',
    },
    typeSend: {
      type: 'select',
      values: ['Контейнерная', 'Авто', 'Вагонная', 'Сборка'],
    },
    typeContainer: {
      type: 'select',
      values: ['20FT', '20FT(HC)', '40FT', '40FT(HC)', '40FT(HC)PW'],
    },
    typeElivery: {
      type: 'select',
      values: ['До двери', 'До станции', 'Вывоз'],
    },
    numberContainer: {
      type: 'input',
      inputType: 'text',
    },
    stopSheet: {
      type: 'checkbox',
    },
    numberSeal: {
      type: 'input',
      inputType: 'text',
    },
    payer: {
      type: 'autocomplete',
      service: PayerService,
    },
    shipper: {
      type: 'autocomplete',
      service: ShipperService,
    },
    consignee: {
      type: 'autocomplete',
      service: ConsigneeService,
    },
    railCarrier: {
      type: 'autocomplete',
      service: ConsigneeService,
    },
    railCarrierStatus: {
      type: 'checkbox',
    },
    agentAutoLoad: {
      type: 'autocomplete',
      service: AutoagentService,
    },
    ownerContainer: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    agentAutoCall: {
      type: 'autocomplete',
      service: AutoagentService,
    },
    exportAgentEurosib: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    agentAutoCallStatus: {
      type: 'checkbox',
    },
    addressLoading: {
      type: 'autocomplete',
      service: AddressService,
    },
    addressUnloading: {
      type: 'autocomplete',
      service: AddressService,
    },
    stationDeparture: {
      type: 'autocomplete',
      service: StationService,
    },
    destinationCity: {
      type: 'autocomplete',
      service: CitiesService,
    },
    stationDestination: {
      type: 'autocomplete',
      service: StationService,
    },
    loadActual: {
      type: 'autocomplete',
      service: LoadService,
    },
    loadDocumented: {
      type: 'autocomplete',
      service: LoadService,
    },
    cargoCode: {
      type: 'input',
      inputType: 'text',
    },
    weight: {
      type: 'input',
      inputType: 'text',
    },
    numberSeats: {
      type: 'input',
      inputType: 'text',
    },
    driver: {
      type: 'autocomplete',
      service: DriverService,
    },
    companyMoversUnloading: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    companyMoversLoading: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    containerAccessory: {
      type: 'select',
      values: ['Клиент', 'Евросиб'],
    },
    companyNameForwarder: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    car: {
      type: 'autocomplete',
      service: CarService,
    },
    trailer: {
      type: 'autocomplete',
      service: TrailerService,
    },
    terminalStaging: {
      type: 'autocomplete',
      service: TerminalService,
    },
    instructionsDelivery: {
      type: 'input',
      inputType: 'text',
    },
    releases: {
      type: 'input',
      inputType: 'text',
    },
    company: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    manager: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    security: {
      type: 'checkbox',
    },
    warming: {
      type: 'input',
      inputType: 'text',
    },
    warmingCompany: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    forwarderLoading: {
      type: 'checkbox',
    },
    forwarderUnloading: {
      type: 'checkbox',
    },
    movers: {
      type: 'input',
      inputType: 'text',
    },
    companyMovers: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    veterinaryCertificate: {
      type: 'checkbox',
    },
    loadFastening: {
      type: 'checkbox',
    },
    agentDocuments: {
      type: 'input',
      inputType: 'text',
    },
    returnOfDocumentsFromUnloading: {
      type: 'select',
      values: ['Вернуть в офис', 'Вернуть в офис 2 адр.', 'Вернуть в офис 3 адр.',
        'Сканы есть', 'Неполный комплект', 'Документы в офисе', 'Оригиналы в офисе',
        'Документы передали', 'Документы передали/оригиналы в офисе',
        'Документы передали/сканы есть', 'Забрал сам клиент'],
    },
    dateArrival: {
      type: 'input',
      inputType: 'date',
    },
    dateIssue: {
      type: 'input',
      inputType: 'date',
    },
    tracking: {
      type: 'input',
      inputType: 'text',
    },
    railwayInvoices: {
      type: 'checkbox',
    },
    loading: {
      type: 'checkbox',
    },
    loadingTN: {
      type: 'checkbox',
    },
    unloadingTN: {
      type: 'checkbox',
    },
    act: {
      type: 'checkbox',
    },
    od: {
      type: 'input',
      inputType: 'text',
    },
    shipment: {
      type: 'input',
      inputType: 'text',
    },
    customerApplicationNumber: {
      type: 'input',
      inputType: 'text',
    },
    additionalServices: {
      type: 'input',
      inputType: 'text',
    },
    buh_amount: {
      type: 'input',
      inputType: 'number',
    },
    buh_carrier: {
      type: 'input',
      inputType: 'number',
    },
    buh_autoBeforeLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD_km: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD: {
      type: 'input',
      inputType: 'number',
    },
    additionalLoadingAddress: {
      type: 'input',
      inputType: 'text',
    },
    buh_plain_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_plain_hour: {
      type: 'input',
      inputType: 'number',
    },
    buh_plain: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload_ton: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload: {
      type: 'input',
      inputType: 'number',
    },
    buh_containerAgent: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm_day: {
      type: 'input',
      inputType: 'number',
    },
    buh_forwarderOnLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_moversOnLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_moversAtUnloading: {
      type: 'input',
      inputType: 'number',
    },
    buh_veterinaryCertificate: {
      type: 'input',
      inputType: 'number',
    },
    buh_loadFastening: {
      type: 'input',
      inputType: 'number',
    },
    buh_warming: {
      type: 'input',
      inputType: 'number',
    },
    buh_additionalServices: {
      type: 'input',
      inputType: 'number',
    },
    buh_exportAgent: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload_hour: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation_day: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation: {
      type: 'input',
      inputType: 'number',
    },
    buh_seal: {
      type: 'input',
      inputType: 'number',
    },
    buh_bills: {
      type: 'input',
      inputType: 'number',
    },
    buh_agentToClient: {
      type: 'input',
      inputType: 'number',
    },
    buh_managerial: {
      type: 'input',
      inputType: 'number',
    },
    buh_agentSuppliers: {
      type: 'input',
      inputType: 'number',
    },
    buh_OBN: {
      type: 'input',
      inputType: 'number',
    },
    buh_total: {
      type: 'input',
      inputType: 'number',
    },
    buh_gasketMaterial: {
      type: 'input',
      inputType: 'number',
    },
    customerAccountNumber: {
      type: 'input',
      inputType: 'text',
    },
    customerAccountAmount: {
      type: 'input',
      inputType: 'text',
    },
    customerAccountDate: {
      type: 'input',
      inputType: 'date',
    },
    carrierAccount: {
      type: 'input',
      inputType: 'text',
    },
    accountsAutoServices: {
      type: 'input',
      inputType: 'text',
    },
    containerAgentAccount: {
      type: 'input',
      inputType: 'text',
    },
    exportAgentAccount: {
      type: 'input',
      inputType: 'text',
    },
    moversAccountOnLoading: {
      type: 'input',
      inputType: 'text',
    },
    moversAccountOnUnloading: {
      type: 'input',
      inputType: 'text',
    },
    accountSecuringCargo: {
      type: 'input',
      inputType: 'text',
    },
    billCargoWeatherization: {
      type: 'input',
      inputType: 'text',
    },
    moversOnLoading: {
      type: 'checkbox',
    },
    moversAtUnloading: {
      type: 'checkbox',
    },
    scheduledIssueTime: {
      type: 'input',
      inputType: 'text',
    },
    documentStatus: {
      type: 'select',
      values: ['Сканы есть', 'Документы в офисе', 'Оригиналы в офисе', 'Документы передали', 'Документы передали/оригиналы в офисе', 'Документы передали/сканы есть', 'Документы забрал сам клиент'],
    },
    timeIssue: {
      type: 'input',
      inputType: 'text',
    },
    plannedDispatchDate: {
      type: 'input',
      inputType: 'date',
    },
    actualDateDispatch: {
      type: 'input',
      inputType: 'date',
    },
    buh_pay: {
      type: 'input',
      inputType: 'number',
    },
    buh_containerAgentPayment: {
      type: 'input',
      inputType: 'number',
    },
    balance: {
      type: 'input',
      inputType: 'number',
    },
  }),
  //Хранятся выбранные заявки
  withState('selectedRequests', 'setSelectedRequests', []),
  //Статус загрузки excel файла
  withState('loadingExcel', 'setLoadingExcel', false),
  //Статус копирования заявки
  withState('copyH', 'setCopyH', false),
  //Хранится id копируемой заявки
  withState('idCopy', 'setIdCopy', ''),
  //Хранится номер копируемой заявки
  withState('numberCopy', 'setNumberCopy', ''),
  //Хранится позиция копируемой заявки
  withState('positionCopy', 'setPositionCopy', 0),
  //Статус удаления заявки
  withState('delPopup', 'setDelPop', false),
  //Хранится id удаляемой заявки
  withState('idDelete', 'setIdDelete', ''),
  //Хранится номер удаляемой заявки
  withState('numberDelete', 'setNumberD', ''),
  withState('showAutocompleteValues', 'setShowAutocompleteValues', {}),
  withState('autocompleteValues', 'setAutocompleteValues', {}),
  //Хранится текст отзыва
  withState('ratingReview', 'setRatingReview', ''),
  withState('requestToRating', 'setRequestToRating', null),
  withState('isReviewSending', 'setIsReviewSending', false),
  //emails
  withState('showEmailsModal', 'setShowEmailsModal', false),
  withState('isEmailsSending', 'setIsEmailsSending', false),
  withState('stringEmails', 'setStringEmails', ''),
  withState('orderColumnsString', 'setOrderColumnsString', ''),
  //Служебные функции
  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
    closeReviewModal: props => () => {
      props.setRatingReview('');
      props.setIsReviewSending(false);
      props.setRequestToRating(null);
      props.changeShowRatingModal(false);
    },
    closeEmailsModal: props => () => {
      props.setStringEmails('');
      props.setIsEmailsSending(false);
      props.setShowEmailsModal(false);
    },
    keyCreatorObject: props => (params) => params.value.name ? params.value.name : 'Не заполнено',
    keyCreatorObjectAddress: props => (params) => params.value.title ? params.value.title : 'Не заполнено',
    keyCreatorArrayObjects: props => (params) => params.value.length > 0 ? params.value.map(item => item.name).join(',') : 'Не заполнено',
    keyCreatorBoolean: props => (params) => params.value ? 'Да' : 'Нет',
    keyCreator: props => (params) => params.value ? params.value : 'Не заполнено',
    getAdditionalData: props => (rowsDataOrigin) => {
      const rowsData = rowsDataOrigin.map(item => {
        item.buh_amountMore.forEach((elem, index) => {
          // add data buh_amountMore
          item[`buh_amountMoreAccountNumber${index + 1}`] = elem.accountNumber;
          item[`buh_amountMoreAmount${index + 1}`] = elem.amount;
          item[`buh_amountMoreDate${index + 1}`] = elem.date;
          item[`buh_amountMoreComment${index + 1}`] = elem.comment;
        });
        return item;
      });
      return rowsData;
    },
    getMaxCountColumns: props => (user) => {
      return RequestsService.getMaxCountColumns()
        .then(data => ({countColumnsAmountMore: data.result.amountMore, user}))
        .catch(error => {
          props.functionCheckTime('Error', error.message);
          throw new Error(error);
        });
    },
    getWidthColumn: props => (params) => {
      let widthColumn = 150;
      switch (params) {
        case 'stopSheet':
        case 'loading':
        case 'rating':
          widthColumn = 90;
          break;
        case 'dateLoad':
        case 'loadingTN':
        case 'unloadingTN':
        case 'act':
        case 'timeIssue':
        case 'railwayInvoices':
          widthColumn = 110;
          break;
        case 'companyAmixgroup':
        case 'typeSend':
        case 'loadFastening':
        case 'timeLoad':
          widthColumn = 120;
          break;
        case 'scheduledIssueTime':
        case 'buh_exportAgent':
        case 'buh_loadFastening':
        case 'buh_agentSuppliers':
        case 'additionalServices':
          widthColumn = 160;
          break;
        case 'railCarrierStatus':
        case 'ownerContainer':
        case 'buh_simpleToUnload_hour':
        case 'buh_gasketMaterial':
        case 'exportAgentEurosib':
        case 'accountSimpleToUnload':
          widthColumn = 170;
          break;
        case 'companyNameForwarder':
        case 'containerAgentAccount':
        case 'documentStatus':
        case 'buh_simpleToUnload':
        case 'moversAccountOnLoading':
        case 'moversAccountOnUnloading':
        case 'buh_simpleToUnload_rate':
        case 'buh_moversOnLoading':
          widthColumn = 180;
          break;
        case 'veterinaryCertificate':
        case 'containerAccessory':
        case 'buh_moversAtUnloading':
          widthColumn = 190;
          break;
        case 'buh_forwarderOnLoading':
        case 'buh_additionalServices':
        case 'plannedIssueDate':
        case 'dateArrival':
        case 'plannedDispatchDate':
        case 'actualDateDispatch':
        case 'dateIssue':
        case 'customerAccountDate':
          widthColumn = 200;
            break;
        case 'instructionsDelivery':
        case 'additionalLoadingAddress':
          widthColumn = 210;
          break;
        case 'companyMoversLoading':
        case 'companyMoversUnloading':
        case 'returnOfDocumentsFromUnloading':
        case 'buh_containerAgentPayment':
        case 'buh_veterinaryCertificate':
        case 'customerAccountNumberStatus':
        case 'carrierAccountStatus':
        case 'accountsAutoServicesStatus':
        case 'containerAgentAccountStatus':
        case 'exportAgentAccountStatus':
        case 'moversAccountOnLoadingStatus':
        case 'moversAccountOnUnloadingStatus':
        case 'accountSecuringCargoStatus':
        case 'billCargoWeatherizationStatus':
          widthColumn = 230;
          break;
        case 'buh_storageAtTheDestinationStation_rate':
        case 'buh_storageAtTheDestinationStation_day':
        case 'buh_storageAtTheDestinationStation':
        case 'accountStorageAtDestinationStation':
          widthColumn = 250;
          break;
      }
      if (params.includes('buh_amountMore')) {
        widthColumn = 180;
      }
      return widthColumn;
    },
  }),
  //Функции, связанные с пользователем
  withHandlers({
    getRight: props => () => {
      return UserService.getRight()
        .then(item => {
          props.saveUserAccesses({
            directory: item.result.directories,
            requests: item.result.requests,
          }, () => {
            return;
          });
        })
        .catch(error => {
          console.warn('[API]-[GET RIGHT]', error);
          throw new Error(error);
        });
    },
    getUser: props => () => {
      return UserService.getUserById(props.user._id)
        .then(user => user)
        .catch(error => {
          console.warn('[API]-[GET USER]', error);
          throw new Error(error);
        });
    },
  }),
  //Функции для таблицы AG-GRID
  withHandlers({
    getData: props => page => {
      const query = {};
      const sorting = props.tableParams ? props.tableParams.api.getSortModel() : [];
      //check sorting params
      if (sorting.length) {
        query.sort = '';
        sorting.forEach(item => query.sort += `${item.colId}:${item.sort === 'asc' ? 'dn' : 'up'};`);
      };
      //check filters values
      if (props.filtersActive) {
        Object.keys(props.filters).forEach(key => {
          if (typeof props.filters[key] === 'object') {
            if (props.typeFields[key].type === 'input') {
              if (props.typeFields[key].inputType === 'number') {
                query[key] = `${props.filters[key].begin};${props.filters[key].end}`;
              } else {
                query[key] = props.filters[key].id || props.filters[key]._id;
              };
            } else {
              query[key] = props.filters[key].id || props.filters[key]._id;
            };
          } else {
            query[key] = props.filters[key];
          };
        });
      };
      //check search value
      if (props.searchValue) {
        query.search = props.searchValue;
      };
      //check status value
      if (props.selectedStatus) {
        query.state = JSON.stringify(props.selectedStatus);
      }
      //check date filter
      if (props.dateFiltersActive) {
        query.daterange = props.dateFilters;
      };
      return RequestsService.getAllRequests(page, props.countRecords, query)
        .then(response => {
          return {
            success: true,
            rows: response.data,
            lastRow: response.records.all,
          };
        })
        .catch(error => {
          console.warn('[API]-[GET REQUESTS FROM API]', error);
          return {
            success: false,
          };
        });
    },
    addRow: props => (id, position) => {
      if (!props.tableParams.api.getRowNode(id)) {
        return RequestsService.getRequestByIdForTable(id)
          .then(data => props.tableParams.api.updateRowData({
            add: [data],
            addIndex: position ? position : 0,
          }))
          .catch(error => console.warn('[API]-[GET REQUEST FROM API]', error));
      } else {
        return;
      };
    },
    replacementRow: props => id => {
      return RequestsService.getRequestByIdForTable(id)
        .then(data => {
          const node = props.tableParams.api.getRowNode(id);
          if (node) {
            const index = node.rowIndex;
            props.tableParams.api.updateRowData({
              remove: [node],
            });
            return props.tableParams.api.updateRowData({
              add: [data],
              addIndex: index,
            });
          };
          return;
        })
        .catch(error => {
          console.warn('[API]-[GET REQUEST FROM API]', error);
          return;
        });
    },
    deleteRows: props => ids => {
      const arraysDelete = [];
      ids.forEach(item => {
        let node = props.tableParams.api.getRowNode(item);
        if (node) {
          arraysDelete.push(node);
        };
      });
      if (arraysDelete.length) {
        return props.tableParams.api.updateRowData({
          remove: arraysDelete,
        });
      };
      return;
    },
  }),
  //Initial функции
  withHandlers({
    initHeader: props => () => {
      let availableFields;
      return RequestsService.getAvailableFields()
        .then(data => {
          availableFields = data;
          props.setAvailableFields(data);
          return props.getUser();
        })
        .then(user => {
          return props.getMaxCountColumns(user);
        })
        .then((data) => {
          const user = data.user;
          const countColumnsAmountMore = data.countColumnsAmountMore;
          let header = {};
          user.fieldsRequests.forEach(item => {
            if (item !== 'number' && item !== 'buh_amountMore' && availableFields[item]) {
              header[item] = availableFields[item];
              // create columns buh_amountMore
              if (item === 'buh_amount' && user.fieldsRequests.includes('buh_amountMore') && availableFields['buh_amountMore']) {
                for (let i = 1; i <= countColumnsAmountMore; i++) {
                  header[`buh_amountMoreAccountNumber${i}`] =  `Номер счета для суммы ${i}`;
                  header[`buh_amountMoreAmount${i}`] =  `Cумма ${i}`;
                  header[`buh_amountMoreDate${i}`] =  `Дата счета суммы ${i}`;
                  header[`buh_amountMoreComment${i}`] =  `Комментарий к сумме ${i}`;
                }
              }
            };
          });
          props.setTableHeader([
            {
              headerName: 'Номер',
              field: 'number',
              pinned: 'left',
              checkboxSelection: true,
              headerCheckboxSelection: true,
              filter: false,
              width: 105,
              cellStyle(params) {
                if (params.data) {
                  let color = '#A9D08E';
                  let backgroundColor = '';
                  switch (params.data.state) {
                    case 'Предварительное':
                      color = '#A9D08E';
                      break;
                    case 'В пути':
                      color = '#FFFF00';
                      break;
                    case 'Прибыл':
                      color = '#da9d0a';
                      break;
                    case 'Выдан клиенту':
                      color = '#0ada35';
                      break;
                    case 'Прогон':
                      color = '#F4B084';
                      break;
                    case 'Закрыта':
                      color = '#808080';
                      break;
                    case 'Вывоз':
                      color = '#F58223';
                      break;
                    default:
                      color = '#A9D08E';
                      break;
                  };
                  if (params.data && params.data.buh_total !== undefined 
                      && params.data.buh_total !== null && params.data.buh_total < 0) {
                    backgroundColor = colors.pink_light;
                  };  
                  if (params.data && params.data.stopSheet) {
                    backgroundColor = colors.purple_light;
                  }
                  return {
                    overflow: 'hiden',
                    borderLeft: `5px solid ${color}`,
                    backgroundColor: `${backgroundColor}`,
                  };
                };
              },
            },
            ...Object.keys(header).map(item => ({
              headerName: header[item],
              field: item,
              filter: item === 'additionalServices' || item === 'tracking' || item === 'customerApplicationNumber' ||
                item === 'od' || item === 'shipment' || item === 'warming' ||
                item === 'cargoCode' || item === 'instructionsDelivery' || item === 'releases' ||
                item === 'numberContainer' || item === 'numberSeal' || 
                item === 'timofeevo' || item === 'timurovo' || item === 'additionalLoadingAddress' ||
                item === 'customerAccountNumber' || item === 'carrierAccount' || item === 'accountsAutoServices' ||
                item === 'containerAgentAccount' || item === 'exportAgentAccount' || item === 'moversAccountOnLoading' ||
                item === 'moversAccountOnUnloading' || item === 'accountSecuringCargo' || item === 'billCargoWeatherization' ||
                item === 'accountStorageAtDestinationStation' || item === 'accountSimpleToUnload' ||
                item === 'accountOverload' || item === 'accountAboveTheNorm' ||
                item === 'timeLoad' || item === 'scheduledIssueTime' ||
                item === 'timeIssue' ? 'agTextColumnFilter' :              
                item === 'dateArrival' || item === 'dateIssue' || item === 'customerAccountDate' || item === 'plannedDispatchDate' ||
                item === 'actualDateDispatch' || item === 'plannedIssueDate' ? 'agDateColumnFilter' :              
                item === 'rating' || item === 'dateLoad' || item.includes('buh_amountMoreAccountNumber') || item.includes('buh_amountMoreAmount') || 
                item.includes('buh_amountMoreComment') || item.includes('buh_amountMoreDate') || item === 'payers' ? false :
                item.includes('buh_') || item === 'balance' ? 'agNumberColumnFilter' : 
                true,
              filterParams: {
                filterOptions: item === 'timeLoad' || item === 'customerApplicationNumber'
                || item === 'scheduledIssueTime' || item === 'od'
                || item === 'shipment' || item === 'warming' || item === 'tracking'
                || item === 'additionalServices' || item === 'cargoCode' || item === 'instructionsDelivery'
                || item === 'releases' || item === 'numberContainer'
                || item === 'numberSeal' || item === 'timeIssue'
                || item === 'timofeevo' || item === 'timurovo'
                || item === 'additionalLoadingAddress' || item === 'customerAccountNumber'
                || item === 'carrierAccount' || item === 'accountsAutoServices'
                || item === 'exportAgentAccount' || item === 'containerAgentAccount'
                || item === 'moversAccountOnLoading' || item === 'moversAccountOnUnloading'
                || item === 'accountSecuringCargo' || item === 'billCargoWeatherization' ? ['equals'] : ['equals', 'inRange'],
                suppressAndOrCondition: true,
                resetButton: true,
                clearButton: true,
                applyButton: true,
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  const dateAsString = cellValue;
                  if (dateAsString == null) return 0;
                  const dateParts = dateAsString.split("-");
                  const day = Number(dateParts[0]);
                  const month = Number(dateParts[1]) - 1;
                  const year = Number(dateParts[2]);
                  const cellDate = new Date(year, month, day);
                  if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                  } else if (cellDate > filterLocalDateAtMidnight) {
                      return 1;
                  } else {
                      return 0;
                  }
                },
                browserDatePicker: true,
              },
              keyCreator:
                item === 'payer' || item === 'shipper' || item === 'consignee' || item === 'railCarrier' || item === 'agentAutoLoad' ||
                item === 'ownerContainer' || item === 'agentAutoCall' || item === 'exportAgentEurosib' || item === 'stationDeparture' ||
                item === 'destinationCity' || item === 'stationDestination' ||
                item === 'loadActual' || item === 'loadDocumented' || item === 'driver' || item === 'companyMoversUnloading' ||
                item === 'companyMoversLoading' || item === 'companyNameForwarder' || item === 'car' || item === 'trailer' ||
                item === 'terminalStaging' || item === 'company' || item === 'manager' || item === 'warmingCompany' || item === 'companyMovers' ? props.keyCreatorObject :
                item === 'addressLoading' || item === 'addressUnloading' ? props.keyCreatorObjectAddress :
                item === 'payers' ? props.keyCreatorArrayObjects :
                item === 'stopSheet' || item === 'companyAmixgroup' || item === 'railCarrierStatus' || item === 'agentAutoCallStatus' || item === 'security' ||
                item === 'forwarderLoading' || item === 'forwarderUnloading' || item === 'veterinaryCertificate' || item === 'loadFastening' || item === 'railwayInvoices' ||
                item === 'loading' || item === 'loadingTN' || item === 'unloadingTN' || item === 'act' || item === 'moversOnLoading' || item === 'moversAtUnloading' || item === 'agentDocuments' ? props.keyCreatorBoolean :
                props.keyCreator,
              width: props.getWidthColumn(item),
              cellRenderer: item === 'rating'
                ? 'rating'
                : item === 'stopSheet' || item === 'companyAmixgroup'
                || item === 'veterinaryCertificate' || item === 'loadFastening'
                || item === 'agentDocuments' || item === 'forwarderLoading'
                || item === 'forwarderUnloading' || item === 'moversOnLoading'
                || item === 'railCarrierStatus' || item === 'moversAtUnloading'
                || item === 'security' || item === 'agentAutoCallStatus'
                || item === 'act' || item === 'railwayInvoices'
                || item === 'loading' || item === 'loadingTN'
                || item === 'unloadingTN'
                ? 'iconYesNo'
                : null,
              editable: (
                item === 'tracking'
                || (item === 'dateIssue' && props.user.roleRightName === ROLES_NAME.provider)
                || (item === 'timeIssue' && props.user.roleRightName === ROLES_NAME.provider)
              )
                ? true
                : false,
              cellEditor: (item === 'dateArrival' || item === 'dateIssue')
                ? 'inputDate'
                : item === 'timeIssue'
                ? 'inputTime'
                : null,
              cellStyle(params) {
                const styles = {};
                if (params.data && params.data.buh_total !== undefined && params.data.buh_total !== null && params.data.buh_total < 0) {
                  styles.backgroundColor = colors.pink_light;
                };
                if (params.data && params.data.stopSheet) {
                  styles.backgroundColor = colors.purple_light;
                }
                if (params.colDef.field === 'buh_amount') {
                  if (params.data && params.data.paymentState) {
                    styles.backgroundColor = colors.green_light;
                  } else {
                    styles.backgroundColor = colors.pink_light;
                  };
                };
                if (params.colDef.field === 'agentAutoCall' && params.data && params.data.agentAutoCallStatus) {
                  styles.backgroundColor = colors.green_light;
                }
                if (params.colDef.field === 'railCarrier' && params.data && params.data.railCarrierStatus) {
                  styles.backgroundColor = colors.green_light;
                } else if (props.access.requests.statusSendForm
                    ? (params.colDef.field === 'railCarrier' && params.data && params.data.statusSendForm)
                    : false) {
                  styles.backgroundColor = colors.green_light;
                }
                if (params.colDef.field === BUH_TOTAL_INPUT) {
                  styles.fontWeight = 'bolder';
                };
                if (params.colDef.field === 'customerAccountNumber'
                    && params.data && params.data.customerAccountNumberStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'carrierAccount'
                    && params.data && params.data.carrierAccountStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'accountsAutoServices'
                    && params.data && params.data.accountsAutoServicesStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'containerAgentAccount'
                    && params.data && params.data.containerAgentAccountStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'exportAgentAccount'
                    && params.data && params.data.exportAgentAccountStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'moversAccountOnLoading'
                    && params.data && params.data.moversAccountOnLoadingStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'moversAccountOnUnloading'
                    && params.data && params.data.moversAccountOnUnloadingStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'accountSecuringCargo'
                    && params.data && params.data.accountSecuringCargoStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'billCargoWeatherization'
                    && params.data && params.data.billCargoWeatherizationStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'accountStorageAtDestinationStation'
                    && params.data && params.data.accountStorageAtDestinationStationStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'accountSimpleToUnload'
                    && params.data && params.data.accountSimpleToUnloadStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'accountOverload'
                    && params.data && params.data.accountOverloadStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                if (params.colDef.field === 'accountAboveTheNorm'
                    && params.data && params.data.accountAboveTheNormStatus) {
                  styles.backgroundColor = colors.green_light;
                };
                for (let i = 1; i <= countColumnsAmountMore; i++) {       
                  if (params.colDef.field === `buh_amountMoreAmount${i}` && params.data.buh_amountMore.length > 0
                      && params.data.buh_amountMore[i - 1] && params.data.buh_amountMore[i - 1].status) {
                    styles.backgroundColor = colors.green_light;
                  }
                }
                return styles;
              },
              valueFormatter(params) {
                if (Array.isArray(params.value)) {
                  let value = '';
                  if (params.value.length > 0) {
                    params.value.forEach((item, index) => {
                      value += item.name;
                      value += index === params.value.length - 1 ? '' : ', ';
                    })
                  }
                  return value;
                }
                switch (typeof params.value) {
                  // case 'boolean':
                  //   return params.value ? '✔️' : '❌';
                  case 'number':
                    return numberWithCommas(params.value);
                  case 'object':
                    if (params.value) {
                      if (params.colDef.field === 'addressLoading' || params.colDef.field === 'addressUnloading') {
                        return params.value.title ? params.value.title : '';
                      } else {
                        return params.value.name ? params.value.name : '';
                      }
                    } else {
                      return '';
                    };
                  default:
                    return params.value;
                };
              },
            }))
          ],
          () => {
            return;
          });
        })
        .catch(error => {
          console.warn('[INIT HEADER]', error);
          throw new Error(error);
        });
    },
    initData: props => () => {
      return props.getData(1)
        .then(res => {
          if (res.success) {
            const rowsData = props.getAdditionalData(res.rows);
            console.warn(rowsData)
            props.setTableData(rowsData);
            props.setPage(2, () => {
              return;
            })
          } else {
            return;
          };
        });
    },
    initDataWithLastIndexRow: props => () => {
      const countRecords = Math.ceil(props.lastIndexRowRequest / 100) * 100;
      const countPages = countRecords / 100 + 1;
      props.setCountRecords(countRecords);
      return props.getData(1)
        .then(res => {
          props.setCountRecords(100);
          if (res.success) {
            const rowsData = props.getAdditionalData(res.rows);
            props.setTableData(rowsData);
            props.setPage(countPages, () => {
              return;
            })
          } else {
            return;
          };
        });
    },
  }),
  //Функции фильтрации
  withHandlers({
    applyFilters: props => () => {
      props.setFiltersActive(!props.filtersActive, () => {
        props.setFiltersActivePoint(!props.filtersActivePoint);
        props.initData();
      });
    },
    deleteFilter: props => field => {
      const state = props.filters;
      delete state[field];
      props.setFilters(state, () => {
        if (props.filtersActive) {
          props.initData();
        };
        if (Object.keys(props.filters).length === 0) {
          props.setFiltersActive(false);
          props.setFiltersActivePoint(false);
          const elementTable = document.querySelector('.ag-theme-balham');
          const elementSideBar = document.querySelector('.side-bar-primary');
          elementTable.style.height = '';
          elementSideBar.style.paddingTop = '';
        };
      });
    },
    changeFilters: props => (field, value) => {
      const state = props.filters;
      const currentTypeFields = props.typeFields;
      const currentShowAutocompleteValues = props.showAutocompleteValues;
      const currentAutocompleteValues = props.autocompleteValues;
      if (props.typeFields[field].type === 'input') {
        if (props.typeFields[field].inputType === 'number') {
          state[field] = Object.assign(state[field], value);
        } else {
          state[field] = value;
        };
      } else {
        state[field] = value;
      };
      if (currentTypeFields[field].type === 'autocomplete') {
        state[field] = value;
        props.typeFields[field].service.getItems(1, {search: value})
          .then(data => {
            currentAutocompleteValues[field] = data.data;
            currentShowAutocompleteValues[field] = true;
            props.setAutocompleteValues(currentAutocompleteValues);
            props.setShowAutocompleteValues(currentShowAutocompleteValues);
            props.setFilters(state, () => {
              if (props.filtersActive) {
                props.initData();
              };
            });
          })
          .catch(error => {
            console.warn('[API]-[GET ITEMS FILTERS FROM API]', error);
            currentShowAutocompleteValues[field] = true;
            props.setAutocompleteValues(currentAutocompleteValues);
            props.setShowAutocompleteValues(currentShowAutocompleteValues);
            props.setFilters(state, () => {
              if (props.filtersActive) {
                props.initData();
              };
            });
          });
      };
    },
    selectedFiltersValue: props => (field, value) => {
      const state = props.filters;
      const currentShowAutocompleteValues = props.showAutocompleteValues;
      state[field] = value;
      currentShowAutocompleteValues[field] = false;
      props.setShowAutocompleteValues(currentShowAutocompleteValues);
      props.setFilters(state, () => {
        props.initData();
      });
    },
    search: props => value => {
      if (props.timerState) {
        clearTimeout(props.timerState);
      };
      const timer = setTimeout(() => {
        props.setSearchValue(value, () => {
          props.initData();
        });
      }, 500);
      props.setTimerState(timer);
    },
    selectStatus: props => value => {
      props.setSelectedStatus(value, () => {
        props.initData();
      })
    },
    clickCalendar: props => () => {
      if (props.viewCalendar) {
        props.setViewCalendar(false);
        return;
      };
      if (props.dateFilters === '') {
        props.setViewCalendar(true);
      } else {
        props.setDateFiltersActive(!props.dateFiltersActive, () => {
          props.initData();
        });
      };
    },
    changeDateRange: props => dates => {
      if (dates) {
        props.setViewCalendar(false);
        const dateRange = [
          `${dates[0]._d.getFullYear()}-${dates[0]._d.getMonth() + 1}-${dates[0]._d.getDate()}`,
          `${dates[1]._d.getFullYear()}-${dates[1]._d.getMonth() + 1}-${dates[1]._d.getDate()}`,
        ];
        try {
          const dateFilters = JSON.stringify(dateRange);
          props.setDateFilters(dateFilters, () => {
            if (props.dateFiltersActive) {
              props.initData();
            };
          });
        } catch (err) {
          console.warn(err);
        };
      } else {
        props.setDateFilters('');
      };
    },
    deleteDateFilter: props => () => {
      props.setDateFiltersActive(false, () => {
        props.setDateFilters('', () => {
          props.initData();
        });
      });
    },
    cancel: props => () => {
      props.setSelectedRequests([], () => {
        props.setFilters({}, () => {
          props.setFiltersActive(false, () => {
            props.setFiltersActivePoint(false);
            props.tableParams.api.deselectAll();
            return props.initData();
          });
        });
      });
      const elementSideBar = document.querySelector('.side-bar-primary');
      elementSideBar.style.paddingTop = '';
    },
  }),
  // Функции с данными
  withHandlers({
    getExcel: props => (debet = false) => {
      props.setLoadingExcel(true);
      const sorting = props.tableParams.api.getSortModel();
      const filters = props.tableParams.api.getFilterModel();
      const query = {};
      // check type report
      if (debet) {
        query.typereport = 'debet';
      }
      // check selected requests
      if (props.selectedRequests.length) {
        query.id = JSON.stringify(props.selectedRequests);
      }
      // check filters
      if (Object.keys(filters).length) {
        Object.keys(filters).forEach((key) => {
          const { values } = filters[key];
          switch (key) {
            case 'typeSend':
            case 'typeContainer':
            case 'typeElivery':
            case 'weight':
            case 'numberSeats':
            case 'containerAccessory':
            case 'returnOfDocumentsFromUnloading':
            case 'documentStatus':
            case 'payer':
            case 'shipper':
            case 'consignee':
            case 'addressLoading':
            case 'addressUnloading':
            case 'destinationCity':
            case 'loadActual':
            case 'manager':
            case 'railCarrier':
            case 'ownerContainer':
            case 'terminalStaging':
            case 'stationDeparture':
            case 'stationDestination':
            case 'loadDocumented':
            case 'warmingCompany':
            case 'companyNameForwarder':
            case 'companyMoversLoading':
            case 'agentAutoLoad':
            case 'driver':
            case 'car':
            case 'trailer':
            case 'agentAutoCall':
            case 'exportAgentEurosib':
            case 'companyMoversUnloading':
            case 'payers': {
              const parameter = values.includes('Не заполнено') ? [...values, null] : values;
              query[key] = JSON.stringify(parameter);
              break;
            }
            case 'dateArrival':
            case 'plannedIssueDate':
            case 'plannedDispatchDate':
            case 'actualDateDispatch':
            case 'dateIssue':
            case 'customerAccountDate': {
              query[key] = filters[key].type === 'inRange'
                ? JSON.stringify([filters[key].dateFrom, filters[key].dateTo])
                : JSON.stringify(filters[key].dateFrom);
              break;
            }
            case 'timeLoad':
            case 'customerApplicationNumber':
            case 'scheduledIssueTime':
            case 'od':
            case 'shipment':
            case 'warming':
            case 'tracking':
            case 'additionalServices':
            case 'cargoCode':
            case 'instructionsDelivery':
            case 'releases':
            case 'numberContainer':
            case 'numberSeal':
            case 'timeIssue':
            case 'timofeevo':
            case 'timurovo':
            case 'additionalLoadingAddress':
            case 'customerAccountNumber':
            case 'carrierAccount':
            case 'accountsAutoServices':
            case 'containerAgentAccount':
            case 'exportAgentAccount':
            case 'moversAccountOnLoading':
            case 'moversAccountOnUnloading':
            case 'accountSecuringCargo':
            case 'billCargoWeatherization': {
              query[key] = JSON.stringify(filters[key].filter);
              break;
            }
            case 'buh_amount':
            case 'buh_carrier':
            case 'buh_autoBeforeLoading':
            case 'buh_forMKAD_rate':
            case 'buh_forMKAD_km':
            case 'buh_forMKAD':
            case 'buh_containerAgent':
            case 'buh_aboveTheNorm_rate':
            case 'buh_aboveTheNorm_day':
            case 'buh_aboveTheNorm':
            case 'buh_exportAgent':
            case 'buh_overload_rate':
            case 'buh_overload_ton':
            case 'buh_overload':
            case 'buh_simpleToUnload':
            case 'buh_simpleToUnload_rate':
            case 'buh_simpleToUnload_hour':
            case 'buh_plain':
            case 'buh_plain_rate':
            case 'buh_plain_hour':
            case 'buh_storageAtTheDestinationStation_rate':
            case 'buh_storageAtTheDestinationStation_day':
            case 'buh_storageAtTheDestinationStation':
            case 'buh_moversAtUnloading':
            case 'buh_forwarderOnLoading':
            case 'buh_moversOnLoading':
            case 'buh_veterinaryCertificate':
            case 'buh_loadFastening':
            case 'buh_warming':
            case 'buh_gasketMaterial':
            case 'buh_additionalServices':
            case 'buh_seal':
            case 'buh_bills':
            case 'buh_agentToClient':
            case 'buh_managerial':
            case 'buh_agentSuppliers':
            case 'buh_OBN':
            case 'buh_total':
            case 'balance':
            case 'buh_pay':
            case 'buh_containerAgentPayment': {
              query[key] = filters[key].type === 'inRange'
                ? JSON.stringify([filters[key].filter, filters[key].filterTo])
                : JSON.stringify(filters[key].filter);
              break;
            }
            default:
              query[key] = values[0] === 'Да';
              break;
          }
        });
      }
      // check filter of dataLoad
      if (props.dateFiltersActive) {
        query.daterange = props.dateFilters;
      }
      // check filter of status
      if (props.selectedStatus) {
        query.state = JSON.stringify(props.selectedStatus);
      }
      // check search value
      if (props.searchValue) {
        query.search = props.searchValue;
      }
      // check sorting params
      if (sorting.length) {
        query.sort = '';
        sorting.forEach(item => query.sort += `${item.colId}:${item.sort === 'asc' ? 'up' : 'dn'};`);
      }
      // get excel from API
      return RequestsService.getRequestsExcel(query)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.xlsx');
          document.body.appendChild(link);
          link.click();
          props.setLoadingExcel(false);
        })
        .catch((error) => {
          console.warn('[API]-[GET EXCEL REQUESTS FROM API]', error);
          props.setLoadingExcel(false);
        });
    },
    addRequest: props => () => {
      props.history.push('/dashboard/requests/create');
    },
    openRequest: props => id => {
      return props.history.push(`/dashboard/requests/view/${id}`);
    },
    deleteRequests: props => (id = null) => {
      const deleteRequests = [];
      const listIds = [];
      if (id) {
        deleteRequests.push(RequestsService.deleteRequest(id));
        listIds.push(id);
      } else {
        props.selectedRequests.forEach(element => {
          deleteRequests.push(RequestsService.deleteRequest(element));
          listIds.push(element);
        });
      };
      return Promise.all(deleteRequests)
        .then(() => {
          props.setSelectedRequests([]);
          props.tableParams.api.deselectAll();
          return props.deleteRows(listIds);
        })
        .catch(error => {
          console.warn('[API]-[DELETE REQUESTS FROM API]', error);
          props.setSelectedRequests([]);
          props.tableParams.api.deselectAll();
          return props.initData();
        });
    },
    copyRequest: props => (id, position) => {
      return RequestsService.copyRequest(id)
        .then(data => props.addRow(data.result._id, position))
        .catch(error => console.warn('[API]-[COPY REQUESTS FROM API]', error));
    },
    editRequest: props => (id, field, value) => {
      return RequestsService.updateRequest(id, {[field]: value})
        .then(() => props.replacementRow(id))
        .catch(error => {
          console.warn('[API]-[UPDATA REQUESTS FROM API]', error);
          return;
        });
    },
    amountSuccessfull: props => data => {
      if (data.value) {
        data.value = false;
      } else {
        data.value = true;
      };
      RequestsService.updateRequest(data.id, {paymentState: data.value})
        .then(() => props.replacementRow(data.id))
        .catch(error => props.functionCheckTime('Error', error.message));
    },
    postRequest: props => () => {
      props.setIsEmailsSending(true);
      return RequestsService.postRequest(props.selectedRequests, props.stringEmails.split(','))
        .catch(error => props.functionCheckTime('Error', error.message))
        .finally(() => props.closeEmailsModal());
    },
  }),
  //Функции попаб меню
  withHandlers({
    removeHandler: props => data => {
      props.setNumberD(data.number, () =>
        props.setIdDelete(data.id, () =>
          props.setDelPop(true),
        ),
      );
    },
    removeHandlerClear: props => () => {
      props.setNumberD('', () =>
        props.setIdDelete('', () =>
          props.setDelPop(false),
        ),
      );
    },
    copyHandler: props => data => {
      props.setNumberCopy(data.number, () =>
        props.setIdCopy(data.id, () =>
          props.setPositionCopy(data.position, () =>
            props.setCopyH(true),
          ),
        ),
      );
    },
    copyHandlerClear: props => () => {
      props.setNumberCopy('', () =>
        props.setIdCopy('', () =>
          props.setPositionCopy(0, () =>
            props.setCopyH(false),
          ),
        ),
      );
    },
  }),
  //События таблицы AG-GRID
  withHandlers({
    onGridReady: props => params => {
      props.setTableParams(params);
      if (props.lastIndexRowRequest) {
        params.api.ensureIndexVisible(props.lastIndexRowRequest, 'middle');
      }
    },
    onCellClicked: props => params => {
      if (params.colDef.field === 'number') {
        props.setLastIndexRowRequest(params.node.rowIndex);
        props.openRequest(params.data.id);
      };
    },
    onCellDoubleClicked: props => params => {
      switch (params.colDef.field) {
        case 'stopSheet':
        case 'companyAmixgroup':
        case 'railCarrierStatus':
        case 'agentAutoCallStatus':
        case 'security':
        case 'forwarderLoading':
        case 'forwarderUnloading':
        case 'veterinaryCertificate':
        case 'loadFastening':
        case 'railwayInvoices':
        case 'loading':
        case 'loadingTN':
        case 'unloadingTN':
        case 'moversOnLoading':
        case 'moversAtUnloading':
        case 'act':
        case 'agentDocuments':
          props.editRequest(params.node.data.id, params.colDef.field, !params.value);
          break;
        case 'dateLoad':
          if (params.value) {
            const dayString = params.value.split('-').reverse().join('-');
            const dayMoment = moment(dayString);
            const dateFilters = JSON.stringify([dayString, dayString]);
            props.setRangeDate([dayMoment, dayMoment]);
            props.setDateFilters(dateFilters, () => {
              props.setDateFiltersActive(!props.dateFiltersActive, () => {
                props.initData();
              });
            });
          }
          break;
      }
    },
    onRowSelected: props => params => {
      const state = props.selectedRequests;
      const index = state.indexOf(params.data.id);
      const selected = params.node.isSelected();
      if (!selected && !state.length) return;
      if (index === -1) {
        state.push(params.data.id);
      } else {
        state.splice(index, 1);
      };
      props.setSelectedRequests(state, () => {
        return;
      });
    },
    getContextMenuItems: props => params => {
      const result = [
        {
          name: 'Копировать заявку',
          action: () => {
            if (params.node) {
              return props.copyHandler({
                id: params.node.data.id,
                number: params.node.data.number,
                position: params.node.rowIndex + 1,
              });
            } else {
              return;
            };
          },
        },
        {
          name: 'Удалить заявку',
          action: () => {
            if (params.node) {
              return props.removeHandler({
                id: params.node.data.id,
                number: params.node.data.number,
              });
            } else {
              return;
            };
          },
        },
        {
          name: 'Сменить статус',
          action: () => {
            if (params.node) {
              return props.amountSuccessfull({
                id: params.node.data.id,
                value: params.node.data.paymentState,
              });
            } else {
              return;
            };
          },
        },
      ];
      return result;
    },
    onCellValueChanged: props => params => {
      if (params.oldValue !== params.newValue) {
        return props.editRequest(params.node.data.id, params.colDef.field, params.newValue);
      };
      return;
    },
    onBodyScroll: props => params => {
      if (params.api.rowRenderer.lastRenderedRow >= (props.page - 1) * props.countRecords / 2 && !props.isDownload) {
        props.setIsDownload(true);
        props.getData(props.page)
          .then(res => {
            if (res.success) {
              props.tableParams.api.updateRowData({add: res.rows});
              props.setPage(props.page + 1, () => {
                props.setIsDownload(false);
                return;
              });
            } else {
              props.setIsDownload(false);
              return;
            };
          })
      };
    },
    onDragStopped: props => async params => {
      const orderColumns = params.columnApi.columnController.gridColumns
        .map(item => item.colId)
        .filter(item => item !== 'number' && !item.includes('buh_amountMore'));
      const orderColumnsString = orderColumns.join(',');
      if (orderColumnsString === props.orderColumnsString) {
        return;
      } else {
        props.setOrderColumnsString(orderColumnsString);
      }
      try {
        const result = await UserService.updateUser(props.user._id, { fieldsRequests: orderColumns });
        if (result.status === 200) {
          props.functionCheckTime('Success', 'Сохранено');
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
  }),
  //Функции рейтинга
  withHandlers({
    setRating: props => (request, rating) => {
      if (rating < 5) {
        props.setRequestToRating({
          id: request,
          rating,
        });
        // props.changeShowRatingModal(true);
      } else {
        RequestsService.updateRequest(request, { rating })
          .catch((error) => {
            props.checkStatusPopup({
              statusCheck: 'Error',
              messageBox: error.message,
              statusTime: true,
            });
            setTimeout(() => {
              props.checkStatusPopup({
                statusCheck: 'Error',
                messageBox: error.message,
                statusTime: false,
              });
            }, 2000);
          });
      };
    },
    // sendReview: props => () => {
    //   if (props.requestToRating) {
    //     if (!props.ratingReview) {
    //       props.checkStatusPopup({
    //         statusCheck: 'Error',
    //         messageBox: 'Вы не указали причину своей оценки',
    //         statusTime: true,
    //       });
    //       setTimeout(() => {
    //         props.checkStatusPopup({
    //           statusCheck: 'Error',
    //           messageBox: 'Вы не указали причину своей оценки',
    //           statusTime: false,
    //         });
    //       }, 2000);
    //       return;
    //     };
    //     props.setIsReviewSending(true);
    //     RequestsService.updateRequest(props.requestToRating.id, {
    //       rating: props.requestToRating.rating,
    //       review: props.ratingReview,
    //     })
    //       .then(() => {
    //         props.closeReviewModal();
    //         props.checkStatusPopup({
    //           statusCheck: 'Success',
    //           messageBox: 'Сообщение отправлено руководству Амикс Групп',
    //           statusTime: true,
    //         });
    //         setTimeout(() => {
    //           props.checkStatusPopup({
    //             statusCheck: 'Success',
    //             messageBox: 'Сообщение отправлено руководству Амикс Групп',
    //             statusTime: false,
    //           });
    //         }, 2000);
    //       })
    //       .catch(error => {
    //         props.closeReviewModal();
    //         props.checkStatusPopup({
    //           statusCheck: 'Error',
    //           messageBox: error.message,
    //           statusTime: true,
    //         });
    //         setTimeout(() => {
    //           props.checkStatusPopup({
    //             statusCheck: 'Error',
    //             messageBox: error.message,
    //             statusTime: false,
    //           });
    //         }, 2000);
    //       });
    //   };
    // },
    readOnlyRating: props => () => {
      return props.user.roleRightName !== ROLES_NAME.clients;
    },
  }),
  lifecycle({
    componentDidMount() {
      socketConnection();
      this.props.setActiveSidebarItem('requests');
      this.props.setSecondaryBarItems([]);
      this.props.initHeader()
        .then(() => this.props.lastIndexRowRequest ? this.props.initDataWithLastIndexRow() : this.props.initData())
        .then(() => this.props.getRight())
        .then(() => this.props.setIsLoading(false))
        .catch(() => console.warn('[INIT ERROR]'));
      //сокет, прослушиваем изменения в таблице
      socket.on('table', ({ event, _id }) => {
        if (event === 'create') {
          setTimeout(() => this.props.addRow(_id), 5000);
        } else if (event === 'update') {
          this.props.replacementRow(_id);
        } else if (event === 'delete') {
          this.props.deleteRows([_id]);
        };
      });
    },
    componentWillUnmount() {
      socket.close();
      const element = document.querySelector('.main-dashboard');
      const scrollArea = document.querySelector('.scroll-area');
      const elementForm = document.querySelector('.main-dashboard-form');
      if (element && elementForm && element.classList.contains('hidden')) {
        if (element && elementForm) {
          element.classList.remove('hidden');
          elementForm.classList.remove('hidden');
        }
        if (scrollArea) {
          scrollArea.style.height = `${window.innerHeight - 225}px`;
        }
      }
      this.props.setExpand(false);
    },
  }),
)(RequestTableView);
