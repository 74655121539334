import { compose } from 'recompose';
import { connect } from 'react-redux';

import LinkedItemView from './LinkedItemView';
import { setNeedPadding } from '../../store/actions/dashboard';

export default compose(
  connect(
    null,
    dispatch => ({
      setNeedPadding: state => dispatch(setNeedPadding(state)),
    }),
  ),
)(LinkedItemView);
