import jwtDecode from 'jwt-decode';

import { apiPost } from '../ApiService';

import { BASE_URL, DEFAUT_AVATAR } from '../../constants';

export default class AuthService {
  login(login, password) {
    return apiPost('auth', { login, password })
      .then((response) => {
        const { token, refreshToken, accesses } = response.data;

        this.setToken(token);
        this.setRefreshToken(refreshToken);

        const decoded = jwtDecode(token);

        return {
          userData: {
            ...decoded,
            id: decoded._id,
            avatar: decoded.avatar ? BASE_URL + decoded.avatar : DEFAUT_AVATAR,
          },
          accesses,
        };
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  }

  loggedIn() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken && !this.isTokenExpired(refreshToken)) {
      return true;
    }
    return false;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

  isTokenExpired(token) {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setRefreshToken(token) {
    localStorage.setItem('refreshToken', token);
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }
}
