import * as actionTypes from '../actions/actionTypes';

const initialState = {
  showRatingModal: false,
  lastIndexRowRequest: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_RATING_MODAL:
      return {
        ...state,
        showRatingModal: action.payload,
      };
    case actionTypes.SET_LAST_INDEX_ROW_REQUEST:
      return {
        ...state,
        lastIndexRowRequest: action.payload,
      };
    default:
      return state;
  }
}
