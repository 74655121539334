import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white_smoke};
  border-bottom: 1px solid #e1e1e1;
  border-radius: 8px;
  ${props => props.loginPage && css`
    padding: 0px;
    border-radius: 0px;
  `};
  border: none;
  border-radius: ${base / 2}px;
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 100%;
  }
`;

export const InputComponent = styled.input`
  box-sizing: border-box;
  border-radius: 8px;
  height: 42px;
  padding: 0 12px;
  font-size: 14px;
  color: ${colors.black};
  outline: none;
  width: 100%;
  background: #ffffff;
  border: none;
  overflow: hidden;
  &::placeholder {
    color: ${colors.light_gray};
  }
  ${props => !props.valid && css`
    border: 1px solid ${colors.primary_color};
    border-radius: ${base / 2}px;
  `};
  ${props => props.readOnly && css`
    cursor: pointer;
  `};
  ${props => props.search && css`
    position: relative;
    padding-left: 48px;
  `}
`;

export const Form = styled.div`
  ${props => props.search && css`
    display: flex;
  `};
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 13px;
  left: 19px;
  width: 15px;
  height: 15px;
  background-image: url(${require('../../assets/icons/searchsvg.svg')});
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
`;
