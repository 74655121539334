import * as actionTypes from './actionTypes';

export function saveUserData(data) {
  return {
    type: actionTypes.SAVE_USER_DATA,
    payload: data,
  };
}

export function saveUserAccesses(data) {
  return {
    type: actionTypes.SAVE_USER_ACCESSES,
    payload: data,
  };
}

export function cleanUserData() {
  return {
    type: actionTypes.CLEAN_USER_DATA,
  };
}
