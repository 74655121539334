/**
 * @flow
 */
import React from 'react';

import {
  RequestForm,
} from '../../../components';

type RequestFromPropsType = {
  accesses: Object,
  edit: boolean,
  autocompleteValues: Object,
  fieldsValue: Object,
  total: Number,
  setTotal: () => void,
  payments: Array<Object>,
  addedPaymentsField: Array<any>,
  changeField: (string, string, string) => void,
  selectedFieldValue: (string, string) => void,
  saveHandler: (Object, string) => void,
  updateHandler: (Object, string) => void,
  clearAutocompleteValues: () => void,
  changePaymentFields: (string, string, string) => void,
  setPayments: (Array<Object>) => void,
  changePayersField: () => void,
  selectPayersField: () => void,
};

export default function RequestFormView(props: RequestFromPropsType) {
  return (
    <div>
      <RequestForm
        edit={props.edit}
        accesses={props.accesses}
        autocompleteValues={props.autocompleteValues}
        changeField={props.changeField}
        changePayersField={props.changePayersField}
        selectPayersField={props.selectPayersField}
        fieldsValue={props.fieldsValue}
        total={props.total}
        setTotal={props.setTotal}
        setFieldsValue={props.setFieldsValue}
        activePayersField={props.activePayersField}
        selectedFieldValue={props.selectedFieldValue}
        saveHandler={props.saveHandler}
        updateHandler={(body, bodyPayment) => props.updateHandler(body, bodyPayment)}
        clearAutocompleteValues={props.clearAutocompleteValues}
        payments={props.payments}
        changePaymentFields={props.changePaymentFields}
        setPayments={props.setPayments}
        containerAgentPaymentCounter={props.containerAgentPaymentCounter}
        paymentCounterAmount={props.paymentCounterAmount}
        setContainerAgentPaymentCounter={props.setContainerAgentPaymentCounter}
        setPaymentCounterAmount={props.setPaymentCounterAmount}
        addedPaymentsField={props.addedPaymentsField}
        autoCreate={props.autoCreate}
        // autoValuest={props.autocompleteValues}
        buhCounterAmountMore={props.buhCounterAmountMore}
        setBuhCounterAmountMore={props.setBuhCounterAmountMore}
        changeBuhAmountMoreField={props.changeBuhAmountMoreField}
        fieldsWithErrors={props.fieldsWithErrors}
        setFieldsWithErrors={props.setFieldsWithErrors}
      />
    </div>
  );
}
