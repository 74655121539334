/**
 * @flow
 */
import React from 'react';

import {
  Input,
  Button,
} from '../index';
import {
  Form,
  InputContainer,
  ButtonContainer,
  FormHeading,
  InputMargin,
  LoginP,
} from './LoginFormStyled';

type LoginFormViewPropsType = {
  setLogin: (any) => void,
  setPassword: (any) => void,
  submitForm: (any) => void,
  submitEvent: (any) => void,
};

export default function LoginFormView(props: LoginFormViewPropsType) {
  return (
    <Form onKeyDown={props.submitEvent}>
      <InputContainer>
        <FormHeading>
          Пожалуйста, войдите:
        </FormHeading>
        <LoginP>
         Логин
        </LoginP>
        <InputMargin>
          <Input
            type="text"
            placeholder="Введите логин"
            changed={props.setLogin}
            login
            autoComplete
          />
        </InputMargin>
        <LoginP>
         Пароль
        </LoginP>
        <Input
          type="password"
          placeholder="Введите пароль"
          changed={props.setPassword}
          login
          autoComplete
        />
        <ButtonContainer>
          <Button
            clicked={props.submitForm}
          >
            Войти
          </Button>
        </ButtonContainer>
      </InputContainer>
    </Form>
  );
}
