import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Cross = styled.div`
  left: 550px;
  top: 50%;
  transform: translateY(-50%);
  zoom: 0.65;
  position: absolute;
  overflow: hidden;
  opacity: 0.5;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }

  ${props => props.isActive && css`
    &
      display: none;
      opacity: 0;
  `}
`;

export const PayerForm = styled.div`
  height: 56px;
  width: 100%;
  padding-left: 20px;
  background: ${colors.white};
  font-weight: 400;
  display: flex;
  flex-direction:column;
  justify-content: center;
  font-size: 14px;
`;

export const Payer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  &::before {
    content: '';
    content: '';
    height: 20px;
    width: 20px;
    background-color: #fff;
    position: absolute;
    left: -10px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%)
  }
  &:nth-child(2n){
    ${PayerForm} {
      background: ${colors.primary_gray};
    };
    &::before {
      background-color: ${colors.primary_gray};
    }
    ${props => props.isActive && css`
      &::before {
        background-color: ${colors.primary_color};
      }
    `};
  };
  ${props => props.isActive && css`
    &>button{
      display: none;
    }
  `}
  ${props => props.isActive && css`
    &::before {
      background-color: ${colors.primary_color};
    }
  `};
`;

export const NameCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CountersCompany = styled.div`
  display: flex;
  font-weight: normal;
  margin-top: 6px;
  font-size: 10px;
`;

export const Counter = styled.p`
  margin: 0;
  margin-right: 24px;
  color: #878787;
`;

export const Name = styled.p`
  margin: 0;
`;
