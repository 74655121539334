import React from 'react';

import { Container, ContainerImg, Img } from './IconYesNoStyled';

const IconYesNoView = props => {
  return (
    <Container>
      <ContainerImg>
        {props.value ? (
          <Img src={require('../../assets/icons/yes.svg')} alt="yes"></Img>
        ) : (
          <Img src={require('../../assets/icons/no.svg')} alt="no"></Img>
        )}
      </ContainerImg>
    </Container>
  );
};

export default IconYesNoView;
