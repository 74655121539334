import {
    compose,
    withState,
    lifecycle,
  } from 'recompose';
  
  import InputMaskAndCalendarView from './InputMaskAndCalendarView';
  
  export default compose(
    withState('paymentDateCalendar', 'setPaymentDateCalendar', false),
  )(InputMaskAndCalendarView);
  