import {
  compose,
  withHandlers,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import axios from 'axios';

// actions
import { cleanUserData } from '../../store/actions/auth';

// services
import AuthService from '../../services/AuthService';

import {
  checkStatusPopup,
  setToggleDrop,
} from '../../store/actions/dashboard';

// views
import DashboardMainView from './DashboardMainView';

let intervalId = null;

export default compose(
  connect(
    state => ({
      user: state.auth.currentUser,
      checkStatus: state.dashboard.checkStatusPopup,
      toggleDrop: state.dashboard.toggleDrop,
    }),
    dispatch => ({
      cleanUserData: () => dispatch(cleanUserData()),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
      setToggleDrop: item => dispatch(setToggleDrop(item)),
      // setActiveItem: item => dispatch(setActiveSidebarItem(item)),
      // setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
    }),
  ),
  withHandlers({
    logoutHandler: props => () => {
      const auth = new AuthService();
      auth.logout();
      props.history.push('/');
      props.cleanUserData();
    },
    openProfileHandler: props => () => {
      props.history.replace('/dashboard/profile/personalInfo');
    },
    openDashboardLogo: props => () => {
      props.history.replace('/dashboard');
    },
    openRequestFormHandler: props => () => {
      props.history.push('/dashboard/requests/create');
      document.location.reload();
    },
  }),
  lifecycle({
    async componentDidMount() {
      let token = localStorage.getItem('token');
      let refreshToken = localStorage.getItem('token');

      const auth = new AuthService();

      const axiosInstance = axios.create({
        /* / heroku server
          baseURL: 'https://amixgroup-backend.herokuapp.com/',
        // */
        //* / test server
        baseURL: 'http://localhost:8081/',
        // */
        headers: { 'Content-Type': 'application/json' },
      });

      axiosInstance.interceptors.request.use(
        (config) => {
          token = localStorage.getItem('token');
          if (token) {
            config.headers.Authorization = token;
          }
          return config;
        },
      );

      if (auth.isTokenExpired(token)) {
        if (auth.isTokenExpired(refreshToken)) {
          auth.logout();
          window.location.replace('/');
        } else {
          const response = await axiosInstance.post('autoauth', JSON.stringify({ refreshToken }));
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
        }
      }

      intervalId = setInterval(() => {
        refreshToken = localStorage.getItem('refreshToken');
        axiosInstance.post('autoauth', JSON.stringify({ refreshToken }))
          .then((response) => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('refreshToken', response.data.refreshToken);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              auth.logout();
              window.location.replace('/');
            }
          });
      }, 6000000);
    },
    componentWillUnmount() {
      clearInterval(intervalId);
    },
  }),
)(DashboardMainView);
