/* eslint-disable no-underscore-dangle */
import {
  compose,
  withState,
  withHandlers,
} from 'recompose';

import HistoryView from './HistoryView';

export default compose(
  withState('viewCalendar', 'setViewCalendar', false),
  withState('date', 'setDate', []),
  withState('dateRange', 'setDateRange', ''),
  withHandlers({
    changeDateRangeForHistory: props => (dates) => {
      if (dates) {
        let dateRange = [
          `${dates[0]._d.getFullYear()}-${dates[0]._d.getMonth() + 1}-${dates[0]._d.getDate()}`,
          `${dates[1]._d.getFullYear()}-${dates[1]._d.getMonth() + 1}-${dates[1]._d.getDate()}`,
        ];
        try {
          props.setViewCalendar(false);
          dateRange = JSON.stringify(dateRange);
          props.changeDateHistory(dateRange);
        } catch (err) {
          throw new Error(err);
        }
      } else {
        props.changeDateHistory(null);
      }
    },
    selectDate: props => (dates) => {
      if (dates) {
        props.setDate(dates);
        const dateRange = [
          `${dates[0]._d.getFullYear()}-${dates[0]._d.getMonth() + 1}-${dates[0]._d.getDate()}`,
          `${dates[1]._d.getFullYear()}-${dates[1]._d.getMonth() + 1}-${dates[1]._d.getDate()}`,
        ];
        props.setDateRange(`${dateRange[0].split('-').reverse().join('.')}-${dateRange[1].split('-').reverse().join('.')}`);
      } else {
        props.setDate([]);
        props.setDateRange('');
        props.setViewCalendar(false);
      }
    },
  }),
)(HistoryView);
