import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from '../ApiService';

export default {
  getApiName() {
    return 'stations';
  },

  getItems(p = 1, params) {
    return apiGet(`/stations?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/station/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          relations: [],
          inputs: [],
          checkboxes: [],
        };

        result.inputs.push({
          title: 'Название',
          name: 'name',
          value: response.data.name,
        });

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem() {
    return null;
  },

  addLinkedItem() {
    return null;
  },

  deleteItem(id) {
    return apiDelete(`/station/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [],
      inputs: [
        {
          title: 'Название',
          name: 'name',
        },
      ],
      checkboxes: [],
    };
  },

  updateItem(id, body) {
    return apiPut(`/station/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/station', body)
      .then(response => ({
        status: response.status,
        data: {
          id: response.data._id,
          ...response.data,
        },
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/stations?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
