import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../../styles';

import '../../../styles/slick/slick.css';

import '../../../styles/slick/slick-theme.css';

export const Item = styled.div`
  margin-right: 38px;
  font-size: 32px;
  outline: none;
  ${props => props.active && css`
    color: ${colors.primary_color};
  `}
`;

export const Heading = styled.h3`
  text-align: center;
  margin: auto;
  cursor: pointer;

  @media (max-width: ${screens.screen_xs_max}) {
    font-size: 18px;
  }

`;
