import styled from 'styled-components';

import { base, colors } from '../../../styles';

export const Item = styled.div`
  text-align: left;
  color: ${colors.black};
  font-size: 12px;
  padding: 12px 18px;
  transition: all .3s ease-in-out;
  position: relative;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary_color};
    &:last-child {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: ${colors.primary_gray}
  }

  &:last-child:after{
    content: none;
  }
`;
