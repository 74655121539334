import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const Box = styled.form`
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 32px;
`;

export const Top = styled.div`
  display: flex;
  margin-left: -40px;
  padding-left: 40px;
  border-left: 5px solid ${colors.primary_red};
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  input{
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 6px;
  }
  p {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 6px;
  }
`;

export const Bottom = styled.div`
  margin-top: 24px;
  flex-direction: row;
  display: flex;
  button {
    display: inline;
    margin-top: 12px;
  }
`;

export const UserAvatar = styled.div`
  width: ${base * 4}px;
  height: ${base * 4}px;
  border-radius: 50%;
  background: url(${props => props.avatar});
  background-size: cover;
  background-color: ${colors.primary_gray};
  background-position: 50%;
  @media (max-width: ${screens.screen_xs_max}) {
    width: ${base * 6}px;
    height: ${base * 6}px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 38px;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-left: 8px;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  font-size: 18px;

  ${props => props.firstname && css`
    font-size: 24px;
    font-weight: bold;
  `}

  ${props => props.lastname && css`
    color: ${colors.primary_red};
    font-size: 24px;
    font-weight: bold;
  `}

  ${props => props.position && css`
    color: ${colors.light_gray};
    font-size: 18px;
    margin-top: ${base / 4}px;
  `}

  ${props => props.smallParagraph && css`
    color: ${colors.light_gray};
  `}
`;
