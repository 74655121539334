/**
 * @flow
 */

import React from 'react';

import {
  ThemeProvider,
} from 'styled-components';
import {
  Button,
} from '../index';


import {
  Box,
  Top,
  Bottom,
  Info,
  Paragraph,
  Content,
} from './PayerInfoStyled';

type PayerInfoViewPropsType = {
  edit: any;
  name?: any,
  // itemToAdd: string,
  // setEdit: (boolean) => void,
  closeButton: () => void,
  children: any,
  create?: boolean,
  needUpdateButton?: boolean,
  updateAction: () => void,
  addAction: () => void,
  removeAction: () => void,
  createAction: () => void,
  createLinkedItem: () => void,
  showAddButton?: boolean,
  needPadding: any,
  modaleWindow?: Boolean,
};

export default function PayerInfoView(props: PayerInfoViewPropsType) {
  const theme = {
    needPadding: props.needPadding,
  };
  return (
    <ThemeProvider
      theme={theme}
    >
      <Box modaleWindow={props.modaleWindow}>
        <Content>
          <Top>
            <Info>
              {props.name
                ? <Paragraph>{props.name}</Paragraph>
                : <Paragraph>Новый</Paragraph>
            }
            </Info>
          </Top>
          {props.children}
        </Content>
        {
        !props.create && (
          <Bottom
            needUpdateButton={props.needUpdateButton}
          >
            {
              (props.needUpdateButton && !props.showAddButton && props.activeSecondaryItem && props.access.directory[props.activeSecondaryItem.url] && props.access.directory[props.activeSecondaryItem.url].update)
                ? (
                  <Button clicked={() => props.updateAction()}>Обновить</Button>
                ) : null
            }
            {props.edit
              ? (props.showAddButton
                && (
                  <Button
                    clicked={() => {
                      props.addAction();
                    }}
                    maxWidth
                    mode="red"
                  >
                    Добавить
                  </Button>
                ))
              : (<Button maxWidth mode="red" clicked={() => props.closeButton()}>Закрыть</Button>)
            }
            {props.edit
              ? <Button maxWidth mode="white" clicked={() => props.closeButton()}>Отменить</Button>
              : (props.activeSecondaryItem && props.access.directory[props.activeSecondaryItem.url] && props.access.directory[props.activeSecondaryItem.url].delete)
                ? (
                  <Button maxWidth mode="white" clicked={() => props.removeAction()}>
                    Удалить из базы
                  </Button>
                ) : null
            }
          </Bottom>
        )
      }

        {
          props.create && (
            <Bottom>
              {props.edit
                ? (props.showAddButton
                  && (
                    <Button clicked={() => {
                      props.createLinkedItem();
                    }}
                    >
                      Добавить
                    </Button>
                  ))
                : <Button clicked={() => props.createAction()}>Создать</Button>
              }
              <Button secondary clicked={() => props.closeButton()}>Отменить</Button>
            </Bottom>
          )
        }
      </Box>
    </ThemeProvider>
  );
}

PayerInfoView.defaultProps = {
  name: '',
  create: false,
  needUpdateButton: false,
  showAddButton: false,
  modaleWindow: false,
};
