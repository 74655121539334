/**
 * @flow
 */
import React from 'react';

import {
  Button,
} from './ButtonStyled';

type ButtonViewPropsType = {
  clicked: (any) => void,
  children: any,
  circle?: boolean,
  rectangle?: boolean,
  cross?: boolean,
  secondary?: boolean,
  crossHover?: boolean,
  mode?: string,
  maxWidth?: boolean,
  title?: string,
  disabled?: boolean,
  plus?: boolean,
};

export default function ButtonView(props: ButtonViewPropsType) {
  return (
    <Button
      type="button"
      onClick={props.clicked}
      circle={props.circle}
      rectangle={props.rectangle}
      cross={props.cross}
      secondary={props.secondary}
      crossHover={props.crossHover}
      mode={props.mode}
      maxWidth={props.maxWidth}
      title={props.title}
      plus={props.plus}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
}

ButtonView.defaultProps = {
  circle: false,
  rectangle: false,
  cross: false,
  secondary: false,
  crossHover: false,
  mode: '',
  maxWidth: false,
  title: '',
  disabled: false,
  plus: false,
};
