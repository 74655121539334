import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  refreshToken: null,
  currentUser: null,
  userAccesses: null,
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAN_USER_DATA:
      return {
        ...initialState,
      };
    case actionTypes.SAVE_USER_DATA:
      return {
        ...state,
        currentUser: action.payload,
      };
    case actionTypes.SAVE_USER_ACCESSES:
      return {
        ...state,
        userAccesses: action.payload,
      };
    default:
      return state;
  }
}
