import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

// view
import MainView from './MainView';

// actions
import {
  setActiveSidebarItem,
  setSecondaryBarItems,
} from '../../../store/actions/dashboard';

// services
import {
  UserService,
} from '../../../services';

import {
  saveUserAccesses,
} from '../../../store/actions/auth';

export default compose(
  connect(
    state => ({
      user: state.auth.currentUser,
      areaData: [
          {
            name: '01.01.2020',
            uv: 4000,
            pv: 2400,
            amt: 2400,
          },
          {
            name: '10.01.2020',
            uv: 3000,
            pv: 1398,
            amt: 2210,
          },
          {
            name: '20.01.2020',
            uv: 2000,
            pv: 9800,
            amt: 2290,
          },
          {
            name: '30.01.2020',
            uv: 2780,
            pv: 3908,
            amt: 2000,
          },
          {
            name: '09.02.2020',
            uv: 1890,
            pv: 4800,
            amt: 2181,
          },
          {
            name: '19.02.2020',
            uv: 2390,
            pv: 3800,
            amt: 2500,
          },
          {
            name: '29.02.2020',
            uv: 3490,
            pv: 4300,
            amt: 2100,
          },
        ],
    }),
    dispatch => ({
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      saveUserAccesses: data => dispatch(saveUserAccesses(data)),
    }),
  ),

  lifecycle({
    componentDidMount() {
      this.props.setActiveSidebarItem('');
      this.props.setSecondaryBarItems([]);
      UserService.getRight()
        .then((item) => {
          this.props.saveUserAccesses({
            directory: item.result.directories,
            requests: item.result.requests,
          });
        })
        .catch(err => console.log('err', err));
    },
  }),
)(MainView);
