import {
  apiPost,
  apiGet,
  apiDelete,
} from '../ApiService';

export default {
  getApiName() {
    return 'userpayers';
  },

  addUserToDirectory(user, payer) {
    return apiPost('/userpayers', { user, payer })
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getLinkedUsers({ payer, rolename }) {
    let query = '?';
    if (payer) {
      query += `payer=${payer}&`;
    };
    if (rolename) {
      query += `rolename=${rolename}`;
    };
    return apiGet(`/userpayers${query}`)
      .then(response => response.data.docs.map(item => ({
        ...item.userId,
        name: `${item.userId.firstName} ${item.userId.lastName}`,
        id: item.userId._id,
      })))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  deleteLinkedUser(user, payer) {
    return apiDelete('/userpayers', { user, payer })
      .then(response => response)
      .catch(error => console.log('error', error));
  },
};
