/**
 * @flow
 */

import React from 'react';

import {
  Popup,
  Loader,
} from './PopupStyled';

type PopupViewPropsType = {
  message: any;
  status: any;
  children: any;
};

export default function PopupView(props: PopupViewPropsType) {
  return (
    <Popup status={props.status}>
      {props.message}
      {props.children}
    </Popup>
  );
}
