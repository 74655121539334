import styled from 'styled-components';

import { base } from '../../styles';
import '../../styles/slick/slick.css';

import '../../styles/slick/slick-theme.css';

export const MobileContent = styled.div`
  width: ${base * 62.5}px;
  font-weight: normal;
`;
