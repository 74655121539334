import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from '../ApiService';

export default {
  getApiName() {
    return 'trailers';
  },

  getItems(p = 1, params) {
    return apiGet(`/trailers?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(trailer => ({
          id: trailer._id,
          name: `Номер: ${trailer.number}`,
          ...trailer,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/trailer/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.number,
          relations: [],
          inputs: [],
          checkboxes: [],
        };

        result.inputs.push({
          title: 'Номер прицепа',
          name: 'number',
          value: response.data.number,
        });

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem() {
    return null;
  },

  addLinkedItem() {
    return null;
  },

  deleteItem(id) {
    return apiDelete(`/trailer/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [],
      inputs: [
        {
          title: 'Номер прицепа',
          name: 'number',
        },
      ],
      checkboxes: [],
    };
  },

  updateItem(id, body) {
    return apiPut(`/trailer/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/trailer', body)
      .then(response => ({
        status: response.status,
        data: {
          id: response.data._id,
          name: `Номер: ${response.data.number}`,
          ...response.data,
        },
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/trailers?search=${body.search}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        name: item.number,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
