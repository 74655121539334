/**
 * @flow
 */
import React from 'react';

import { StyledTabContent } from './TabContentStyled';

type TabContentPropsType = {
  children: any,
  activeTabId: number,
};

const TabContentView = (props: TabContentPropsType) => {
  const tabs = props.children.map(item => ({
    ...item,
    props: {
      ...item.props,
      activeTabId: props.activeTabId,
    },
  }));

  return (
    <StyledTabContent>
      {tabs}
    </StyledTabContent>
  );
};

export default TabContentView;
