import {
  apiGet,
} from '../ApiService';

export default {
  getApiName() {
    return 'requestsHistory';
  },

  getAllRequestsHistory(params) {
    return apiGet('/requestshistory', { params })
      .then(response => ({
        history: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        pages: response.data.pages.all,
      }))
      .catch(error => console.log('Get all requests history error', error));
  },

  getAllRequestsHistoryWithPages(params) {
    return apiGet('/requestshistory', { params })
      .then(response => ({
        history: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        currentPage: response.data.pages.current,
        pages: response.data.pages.all,
        records: response.data.pages.allRecords,
      }))
      .catch(error => console.log('Get all requests history error', error));
  },
};
