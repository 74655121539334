import styled from 'styled-components';
import { colors, screens, base } from '../../styles';

export const Form = styled.div`
  padding: 20px 10px 10px 0;
  background: ${colors.white};
  border-radius: 6px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleText = styled.div`
  padding-left: 20px;
  font-size: 20px;
  font-weight: bold;
`;

export const ExportButton = styled.div`
  display: block;
  margin-left: 40px;

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const EditButton = styled.div`
  display: block;
  margin-left: 10px;

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const DeleteButton = styled.div`
  display: block;
  margin-left: 10px;

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const MobileButtons = styled.div`
  display: none;

  @media (max-width: ${screens.screen_xs_max}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const MobileButton = styled.div`
  margin-left: 10px;
`;

export const FieldContainer = styled.div`
  margin: 0 0 15px 30px;
`;

export const FieldTitle = styled.div`
  color: ${colors.black};
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
`;

export const FieldValue = styled.div`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 400;
`;
