/**
 * @flow
 */

import React from 'react';
import validator from 'validator';

import {
  Input,
  Button,
  SelectDropDown,
  SelectDropDownItem,
} from '../index';

import {
  Box,
  Top,
  Middle,
  Bottom,
  UserAvatar,
  Info,
  Paragraph,
} from './UserCardInfoStyled';

type UserCardInfoViewPropsType = {
  edit: boolean,
  firstName: string,
  lastName: string,
  position: string,
  login: string,
  password: string,
  repeatedPassword: string,
  phoneNumber: string,
  email: string,
  avatar?: string,
  saveAction: (any) => void,
  changeFirstName: (string) => void,
  changeLastName: (string) => void,
  changeLogin: (string) => void,
  changeNewPassword: (string) => void,
  changeNewPasswordRepeated: (string) => void,
  changePhoneNumber: (string) => void,
  changeEmail: (string) => void,
  deleteAction: () => void,
  addAction: () => void,
  cancelAction: () => void,
  roles: () => void,
  setRoleId: () => void,
};

export default function UserCardInfoView(props: UserCardInfoViewPropsType) {
  return (
    <Box>
      <Top>
        <UserAvatar avatar={props.avatar} />
        <Info>
          {props.firstName ? <Paragraph firstname>{props.firstName}</Paragraph>
            : <Paragraph firstname>Новый</Paragraph>}
          {props.lastName ? <Paragraph lastname>{props.lastName}</Paragraph>
            : <Paragraph lastname>Пользователь</Paragraph>}
          {props.position ? <Paragraph position>{props.position}</Paragraph>
            : <Paragraph position>{props.position}</Paragraph>}
        </Info>
      </Top>
      <Middle>
        <Paragraph smallParagraph>Имя</Paragraph>
        <Input
          type="text"
          placeholder="Имя работника"
          value={props.firstName}
          changed={(value) => {
            if (value.length <= 30) {
              props.changeFirstName(value);
            }
          }}
        />
        <Paragraph smallParagraph>Фамилия</Paragraph>
        <Input
          type="text"
          placeholder="Фамилия работника"
          value={props.lastName}
          changed={(value) => {
            if (value.length <= 30) {
              props.changeLastName(value);
            }
          }}
        />
        <Paragraph smallParagraph>Логин</Paragraph>
        <Input
          type="text"
          placeholder="Укажите логин"
          value={props.login}
          changed={(value) => { props.changeLogin(value); }}
        />
        <Paragraph smallParagraph>Новый пароль</Paragraph>
        <Input
          type="password"
          placeholder={props.edit ? '∗∗∗∗∗∗∗∗∗∗∗∗∗' : 'Укажите пароль'}
          value={props.password}
          changed={value => props.changeNewPassword(value)}
        />
        <Paragraph smallParagraph>Повторите пароль</Paragraph>
        <Input
          type="password"
          placeholder={props.edit ? '∗∗∗∗∗∗∗∗∗∗∗∗∗' : 'Укажите пароль'}
          value={props.repeatedPassword}
          changed={value => props.changeNewPasswordRepeated(value)}
        />
        <Paragraph smallParagraph>Телефон</Paragraph>
        <Input
          type="text"
          placeholder="Телефон работника"
          value={props.phoneNumber}
          changed={(value) => { props.changePhoneNumber(value); }}
        />
        <Paragraph smallParagraph>Электронная почта</Paragraph>
        <Input
          type="text"
          placeholder="Почта работника"
          value={props.email}
          changed={(value) => { props.changeEmail(value); }}
        />
        {props.edit
          ? (
            <div>
              <Paragraph smallParagraph>Должность</Paragraph>
              <SelectDropDown
                placeholder="Выберите должность"
                selectedValue={props.position}
                clicked={() => {
                  console.log('click');
                }}
              >
                {
                  props.roles.map(item => (
                    <SelectDropDownItem
                      key={item.id}
                      title={item.name}
                      selectOption={() => props.setRoleId(item.id)}
                    />
                  ))
                }
              </SelectDropDown>
            </div>
          )
          : null
        }
      </Middle>
      <Bottom>
        {props.edit
          ? ((props.edit && props.access.directory.users.update)
            ? (
              <Button maxWidth mode="red" clicked={() => props.saveAction()}>Сохранить</Button>
            ) : null
          )
          : <Button maxWidth mode="red" clicked={() => props.addAction()}>Добавить</Button>
        }
        {(props.edit && props.access.directory.users.delete)
          ? (
            <Button mode="white" maxWidth clicked={() => props.deleteAction()}>Удалить</Button>
          )
          : null
        }
        <Button mode="white" maxWidth clicked={() => props.cancelAction()}>Отменить</Button>
      </Bottom>
    </Box>
  );
}

UserCardInfoView.defaultProps = {
  avatar: require('../../assets/images/default_avatar.png'),
};
