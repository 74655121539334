import styled from 'styled-components';

import { base, screens } from '../../../styles';

export const ContentPage = styled.div`
  width: 100%;
`;

export const FormCardInfo = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  margin-top: 37px;
  width: 400px;
  padding-top: 32px;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
  }
`;

export const ContentGlobal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  button{
    margin: 0 32px 0 24px;
  }
`;

export const MobileAddButton = styled.div`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    position: fixed;
    bottom: 8px;
    right: 8px;
  }
`;

export const Loader = styled.div`
  display: none;
  transform: translateZ(1px);
  margin-left: 20px;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #2E83EC;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  opacity: 0.65;
  @keyframes lds-circle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }
`;
