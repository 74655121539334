import styled, { css } from 'styled-components';

import { base, colors } from '../../../styles';

export const SideBarItemWrapper = styled.div`
  color: #747474;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 210px;
  word-break: break-word;
  height: 60px;
  ${props => props.active && css`
    background-color: #f4f4f4;
    color: ${colors.primary_color};
  `}
  ${props => props.toggleLoad && css`
    pointer-events: none;
  `}
`;

export const IconSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 34px;
`;

export const TextSection = styled.div`
  text-align: left;
  margin: 0 32px;
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  display: block;
`;

export const Name = styled.p`
  font-size: 12px;
  height: auto;
`;
