import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../styles';

export const Container = styled.div`
  position: relative;
  margin: 0 12px 12px 0;
  width: 100%;
`;

export const Input = styled.input`
  font-size: 12px;
  height: 42px;
  width: 250px;
  border: 0px;
  padding: 0 12px;
  border-radius: ${base - 4}px;
  box-sizing: border-box;
  background-color: #f2f2f2;


  &::placeholder {
    color: #8e8e8e;
  }
  &:focus {
    outline: none;
  ${({ autocomplete }) =>
      !autocomplete &&
        css`
          {
            caret-color: transparent;
          }
      `}
  }
  ${({ autocomplete }) =>
    !autocomplete &&
      css`
        {
          background-color: transparent;
          border: 1px solid ${colors.primary_white_gray};
        }
    `}
  ${({ itemName, fieldsWithErrors }) =>
  fieldsWithErrors && fieldsWithErrors.includes(itemName) &&
    css`
        {
          border: 1px solid ${colors.primary_red};
        }
  `} 
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }  
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 192px;
  }
`;

export const Button = styled.button`
  height: ${base * 3.6}px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: ${({ isOpen }) => (isOpen ? `url(${require('../../assets/icons/drop-down-up.svg')})` : `url(${require('../../assets/icons/drop-down.svg')})`)};
  background-position: center right 19px;
  background-repeat: no-repeat;
  background-size: 9px;
  @media (max-width: ${screens.screen_2xs_max}) {
    right: 90px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    right: 0;
  }
`;

export const List = styled.div`
  position: absolute;
  top: calc(${base * 3.6}px + 8px);
  box-sizing: border-box;
  box-shadow: 0 10px 20px #efefef;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px 10px;
  z-index: 8;
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 192px;
  }
`;

export const Item = styled.div`
  padding: 3px 0;
  color: #9b9b9b;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
