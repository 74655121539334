export const numberWithCommas = (num, crop=2) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  if (parts[1]) {
    parts[1] = parts[1].slice(0, crop);
  }
  return parts.join('.');
};

export const roundingNumber = (num, degree = 2) => {
   if (num && !isNaN(num)) {
    return (+(+num).toFixed(degree + 1)).toFixed(degree);
   }
   return num;
};
