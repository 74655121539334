import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Button = styled.button`
  width: auto;
  background-color: ${colors.primary_color};
  color: ${colors.white};
  border: none;
  border-radius: 8px;
  font-size: 14px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => {
    switch (props.mode) {
      case 'red':
        return css`
          width: auto;
        `;
      case 'white':
        return css`
          background-color: ${colors.white};
          color: ${colors.primary_color};
        `;
      case 'plus':
        return css`
          width: auto;
          height: ${base * 3.6}px;
        `;
      default:
        return css``;
    }
  }}
  ${props => props.maxWidth && css`
    width: 100%;
  `}
  ${props => props.circle && css`
    padding: 0;
    justify-content: center;
    width: 128px;
    height: 32px;
    line-height: 24px;
    border-radius: 8px;
    font-size: 20px;

    & > span{
      text-align: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
    }
  `}
  ${props => props.plus && css`
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 10px;
      height: 10px;
      background-image: url(${require('../../assets/icons/close-white.svg')});
      background-position: center;
      background-repeat: no-repeat;
    }
  `}
  ${props => props.secondary && css`
    background-color: white;
    border: 1px solid ${colors.primary_color};
    color: ${colors.primary_color};
  `}

  ${props => props.cross && css`
    width: 100%;
    background-color: transparent;
    justify-content: center;
    background-image: url(${require('../../assets/icons/close-default.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
    margin: 12px 12px;
  `}

  ${props => props.crossHover && css`
    width: 100%;
    background-color: transparent;
    justify-content: center;
    background: url(${require('../../assets/icons/close-hover.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
  `}

  ${props => props.rectangle && css`
    height: 32px;
    width: 140px;
    padding: 0;
    background-color: ${colors.white};
    color: ${colors.black};
  `}
`;
