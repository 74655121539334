/**
 * @flow
 */
import React from 'react';

import {
  Button,
  Input,
  Popup,
} from '../../../../components';

import {
  ContentProfile,
  PagePasswordTitle,
  Header,
  InputPassword,
  UpBlock,
  DownBlock,
  TitlePassword,
  NewPassword,
  NewRepeatPassword,
  PopupTitle,
  GlobalContent,
} from './ChangePasswordStyled';

type ChangePasswordPropsType = {
    setFvalue: (any) => void,
    setSvalue: (any, any) => void,
    setTvalue: (any, any) => void,
    setSaveFPassowrd: (any) => void,
    setSaveSPassowrd: (any) => void,
    setSaveTPassowrd: (any) => void,
    toggleButton: (any) => void,
    statusCheck: (any) => void,
    buttonState: any,
    // dataSaved: any,
    wrongState: any,
    wrongText: any,
};

export default function ChangePassword(props: ChangePasswordPropsType) {
  return (
    <GlobalContent>
      <ContentProfile>
        <Header>
          <PagePasswordTitle>
            <span>Смена</span>
            <span>пароля</span>
          </PagePasswordTitle>
          {props.buttonState && (
          <Button clicked={() => {
            props.statusCheck();
          }}
          >Сохранить
          </Button>
          )}
        </Header>
        <InputPassword>
          <UpBlock>
            <TitlePassword>Старый пароль</TitlePassword>
            <Input
              type="password"
              changed={(value) => {
                props.setSaveFPassowrd(value);
                props.setFvalue(value.length);
                setTimeout(() => {
                  props.toggleButton();
                }, 0);
              }
                }
            />
            <NewPassword>
              <TitlePassword><span>Новый пароль</span><span>Минимум 6 символов</span></TitlePassword>
              <Input
                type="password"
                changed={(value) => {
                  props.setSaveSPassowrd(value);
                  props.setSvalue(value.length, props.toggleButton);
                }
                }
              />
            </NewPassword>
          </UpBlock>
          <DownBlock>
            <NewRepeatPassword>
              <TitlePassword>Повторите пароль</TitlePassword>
              <Input
                type="password"
                changed={(value) => {
                  props.setSaveTPassowrd(value);
                  props.setTvalue(value.length, props.toggleButton);
                }}
              />
            </NewRepeatPassword>
          </DownBlock>
        </InputPassword>
      </ContentProfile>
      <PopupTitle>
        {props.wrongState && (<Popup status="Error">{props.wrongText}</Popup>)}
      </PopupTitle>
    </GlobalContent>
  );
}
