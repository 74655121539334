/**
 * @flow
 */
import React from 'react';

import {
  InfoBlockTotalWrapper,
  InfoBlockTotalNumber,
  InfoBlockTotalDate,
  InfoBlockTotalAmount,
  InfoBlockTotalComment,
} from './InfoBlockTotalStyled';

type InfoBlockTotalViewType = {
  number: string,
  date: String,
  amount: number,
  comment?: String,
};

export default function InfoBlockTotalView(props: InfoBlockTotalViewType) {
  return (
    <InfoBlockTotalWrapper>
      <InfoBlockTotalNumber>{props.number}</InfoBlockTotalNumber>
      <InfoBlockTotalDate>{props.date}</InfoBlockTotalDate>
      <InfoBlockTotalAmount>Сумма: {(props.amount ? props.amount.toLocaleString() : 0)}</InfoBlockTotalAmount>
      {props.comment && <InfoBlockTotalComment>Комментарий: {props.comment}</InfoBlockTotalComment>}
    </InfoBlockTotalWrapper>
  );
}
