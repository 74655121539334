import * as actionTypes from './actionTypes';

export function changeShowRatingModal(data) {
  return {
    type: actionTypes.SHOW_RATING_MODAL,
    payload: data,
  };
}

export function setLastIndexRowRequest(data) {
  return {
    type: actionTypes.SET_LAST_INDEX_ROW_REQUEST,
    payload: data,
  };
}
