import {
  compose,
  withState,
  lifecycle,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import ModaleWindowPayerInfoView from './ModaleWindowPayerInfoView';
import { cleanUserData } from '../../store/actions/auth';

import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
  checkStatusPopup,
} from '../../store/actions/dashboard';

import {
  AuthService,
  DirectoryService,
  UserService,
  directoryServiceMap,
} from '../../services';

export default compose(
  connect(
    state => ({
      user: state.auth.currentUser,
      secondaryBarItems: state.dashboard.secondaryBarItems,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      checkStatus: state.dashboard.checkStatusPopup,
    }),
    dispatch => ({
      cleanUserData: () => dispatch(cleanUserData()),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
    }),
  ),
  // список сущностей на странице
  withState('payerList', 'setPayerList', []),
  // withState('payerCardDefault', 'setPayerCardDefault', []),
  // withState('valueSelection', 'setValueSelection', null),

  // отображает правый сайдбар
  withState('toggleOn', 'toggle', false),

  // активный таб в сайдбаре. Нужно когда у сущности есть привязки
  withState('activeID', 'setActiveID', ''),

  // управляет попапом для удаления
  withState('delPopup', 'setPopupVisible', false),

  // сохраняет ид сущности для удаления при нажатии на крестик
  // после использует этот ид когда будет положительный ответ попапа
  withState('toRemove', 'setToRemove', null),

  // data for sidebar
  // withState('entityName', 'setEntityName', ''),

  // содержит в себе данные для отображения в правом сайдбаре
  // инпуты, чекбоксы, линки на другие сущности
  withState('blockData', 'setBlockData', {}),

  // нужно для карточки с привяками
  // когда edit === true, то отображается таб бар
  // у таб бара с ид 0 табе мы можем сделать привязку с уже созданными сущностями
  // у таб бара с ид 1 видна форма для создания новой сущности и мновенной привязке
  withState('edit', 'setEdit', false),

  // состояни для привязки пользователей к сущности
  // отличается тем, что открывается форма со списком пользователей
  // без таб баров и соответсвенно создания пользователя сразу из карточки сущности
  withState('linkUser', 'setLinkUser', false),

  // опять, когда у сущности есть привязки,
  // то нам надо знать с какой сущностью мы связаны
  withState('linkedService', 'setLinkedService', null),

  // список существующих сущностей
  withState('itemsForLink', 'setItemsForLink', []),

  // настройки, в режиме редактирования показывает назвнаие
  // редактируемой сущности
  withState('forLinkTitle', 'setForLinkTitle', null),

  // структура формы сущности для привязки
  withState('linkedServiceForm', 'setLinkedServiceForm', {}),

  // структура формы основной сущности
  withState('serviceForm', 'setServiceForm', {}),

  // когда в карточке создаем новую сущность для привязке,
  // то здесь мы храним значения инпутов которые ввели
  withState('linkedServiceInputState', 'setLinkedServiceInputState', {}),

  // хуй знает)))
  withState('showCreateForm', 'setShowCreateForm', false),

  // следим за инпутами в новой карточке
  withState('newItemData', 'setNewItemData', {}),

  // активный таб
  withState('activeTab', 'setActiveTab', 0),

  // следим за инпутами в карточке с данными
  // бля, зачем выше тогда отдельный для новой?
  withState('inputs', 'setInputs', {}),

  withState('isLoading', 'setIsLoading', false),
  withState('isLoadMore', 'setIsLoadMore', false),
  withState('recordsState', 'setRecordsState', 0),
  withState('hasMore', 'setHasMore', true),


  withHandlers({
    // выход
    logoutHandler: props => () => {
      const auth = new AuthService();
      auth.logout();
      props.history.push('/');
      props.cleanUserData();
    },
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),

  withHandlers({
    // для бесконечного скролла
    loadMore: props => async (p) => {
      try {
        props.setIsLoadMore(true);
        const list = [...props.payerList];
        const result = await props.service.getItems(p);

        props.setRecordsState(result.records);
        props.setPayerList(list.concat(result.data));
        props.setIsLoadMore(false);
        if (result.data.length === 0) {
          props.setHasMore(false);
          return;
        }

        if (result.records === result.data.length) {
          props.setHasMore(false);
          return;
        }

        props.setHasMore(true);
      } catch (error) {
        console.log('error', error);
      }
    },
    // для поиска элементов
    searchItem: props => async (value) => {
      props.setIsLoading(true);
      const body = {
        search: value,
      };
      try {
        const result = await props.service.searchItem(body);
        props.setPayerList(result);
        props.setRecordsState(result.length);
        if (value) {
          props.setHasMore(false);
        } else {
          props.setHasMore(true);
        }
        props.setIsLoading(false);
      } catch (error) {
        props.setIsLoading(false);
      }
    },
  }),

  withHandlers({
    // открывает и закрывает правй сайдбар
    toggleCurrent: props => async (item) => {
      try {
        const result = await props.service.getById(item.id);
        props.setBlockData(result);
        props.setServiceForm(props.service.getFields());
        const inputsStructure = {};
        if (result.inputs && result.inputs.length > 0) {
          result.inputs.forEach((input) => {
            inputsStructure[input.name] = input.value;
          });
        }

        if (result.checkboxes && result.checkboxes.length > 0) {
          result.checkboxes.forEach((checkbox) => {
            inputsStructure[checkbox.name] = checkbox.value;
          });
        }

        props.setInputs(inputsStructure);
        props.toggle(true);
        props.setActiveID(item._id);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // если у сущность есть параметр canAddInput,
    // то добавит этот инпут при нажатии на кнопку
    addAdditionalLinkedInput: props => () => {
      const linkedServiceForm = { ...props.linkedServiceForm };
      const number = props.linkedServiceForm.inputs.length;
      const newInput = {
        ...props.linkedServiceForm.canAddInput,
        name: `${props.linkedServiceForm.canAddInput.name}-${number + 1}`,
      };
      linkedServiceForm.inputs.push(newInput);
      props.setLinkedServiceForm(linkedServiceForm);
    },
    addAdditionalInput: props => () => {
      const serviceForm = { ...props.serviceForm };
      const fields = props.service.getFields();
      const number = serviceForm.inputs.length;
      const newInput = {
        ...fields.canAddInput,
        name: `${fields.canAddInput.name}-${number + 1}`,
      };
      serviceForm.inputs.push(newInput);
      props.setServiceForm(serviceForm);
    },
  }),

  withHandlers({
    // в правом сайдбаре когда мы видим список привязанных элементов,
    // то любой из них мы можем удалить
    removeLinkedItem: props => async (id, item, itemId) => {
      try {
        const result = await props.service.removeLinkedItem(id, item, itemId);
        if (result.status === 200) {
          const updateBlockData = await props.service.getById(id);
          props.setBlockData(updateBlockData);
          const updateItem = await props.service.getItem(id);
          const itemList = props.payerList;
          itemList.forEach((value, index) => {
            if (value.id === updateItem.id) {
              itemList[index].counters = updateItem.counters;
              props.setPayerList(itemList);
            }
          });
          props.functionCheckTime('Success', 'Удалено');
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // привязывает элемент
    addLinkedItem: props => async (id, item, itemId) => {
      try {
        const result = await props.service.addLinkedItem(id, item, itemId);
        if (result.status === 200) {
          props.setEdit(false);
          const updateBlockData = await props.service.getById(id);
          props.setBlockData(updateBlockData);
          const updateItem = await props.service.getItem(id);
          const itemList = props.payerList;
          itemList.forEach((value, index) => {
            if (value.id === updateItem.id) {
              itemList[index].counters = updateItem.counters;
              props.setPayerList(itemList);
            }
          });
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    addUserToDirectory: props => async (entityId, userId) => {
      try {
        const { linkedUserService } = props.forLinkTitle;
        await linkedUserService.addUserToDirectory(userId, entityId);
        const updateBlockData = await props.service.getById(entityId);
        props.setBlockData(updateBlockData);
        props.setLinkUser(false);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    removeUserFromDirectory: props => async (service, entityId, userId) => {
      try {
        await service.deleteLinkedUser(userId, entityId);
        const updateBlockData = await props.service.getById(entityId);
        props.setBlockData(updateBlockData);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
  }),

  withHandlers({
    // открывает чиступю форму для создания
    openFormForCreate: props => () => {
      const fieldStructure = {};
      props.service.getFields().inputs.forEach((input) => {
        fieldStructure[input.name] = '';
      });

      props.service.getFields().relations.forEach((relation) => {
        fieldStructure[relation.name] = [];
      });

      props.service.getFields().checkboxes.forEach((checkbox) => {
        fieldStructure[checkbox.name] = false;
      });

      if (props.service.getFields().selection) {
        props.service.getFields().selection.forEach((select) => {
          fieldStructure[select.name] = 'Экодор';
        });
      }
      props.setServiceForm(props.service.getFields());

      props.setNewItemData(fieldStructure);
    },
    // грузит список сущностей для привязок
    // и создает структуру инпутов если откроем вкладку для создания нового
    showLinkedBar: props => async (linkedService, title, name) => {
      try {
        props.setEdit(true);
        props.setForLinkTitle({ title, name });
        props.setLinkedService(linkedService);
        const itemsForLink = await linkedService.getItems();
        const fields = await linkedService.getFields();
        props.setLinkedServiceForm(fields);
        const inputState = {};
        fields.inputs.forEach((field) => {
          inputState[field.name] = '';
        });
        fields.checkboxes.forEach((field) => {
          inputState[field.name] = false;
        });

        props.setLinkedServiceInputState(inputState);
        props.setItemsForLink(itemsForLink.data);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // в боковом меню отобразит список пользователей для привязки
    showUsersForLink: props => async (linkedUserService, title, roleName) => {
      try {
        const itemsForLink = await UserService.getUsersByRoleName(roleName);
        props.setForLinkTitle({ title, roleName, linkedUserService });
        props.setItemsForLink(itemsForLink);
        props.setLinkUser(true);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // действие для добавления новой сущности
    // висит внизу на кнопке Добавить
    addedAction: props => async () => {
      const { id } = props.blockData;
      let result = null;
      if (Object.prototype.hasOwnProperty.call(props.linkedServiceInputState, 'createaddress')) {
        try {
          result = await props.service.updateItem(id, props.linkedServiceInputState);
          const updateItem = await props.service.getItem(id);
          const itemList = props.payerList;
          itemList.forEach((value, index) => {
            if (value.id === updateItem.id) {
              itemList[index].counters = updateItem.counters;
              props.setPayerList(itemList);
            }
          });
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      } else {
        try {
          result = await props.linkedService.createItem(props.linkedServiceInputState);
          if (result.status === 200) {
            const item = props.linkedService.getApiName();
            const itemId = result.data.id;
            await props.addLinkedItem(id, item, itemId);
          }
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      }
      if (result && result.status === 200) {
        const updateBlockData = await props.service.getById(id);
        props.setBlockData(updateBlockData);
        props.setEdit(false);
      }
    },
    // удаляет сущность
    // висит внизу на кнопке Удалить
    removeActoin: props => async (id) => {
      try {
        await props.service.deleteItem(id);
        props.setEdit(false);
        props.setPopupVisible(false);
        const itemList = await props.service.getItems();
        props.setPayerList(itemList.data);
        props.skip();
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },

    // создает новую сущность из чистой карточки
    createAction: props => async () => {
      try {
        const result = {
          name: props.newItemData.name,
        };
        Object.keys(props.newItemData).forEach((key) => {
          if (Array.isArray(props.newItemData[key])) {
            result[key] = props.newItemData[key].map(item => item.id);
          } else {
            result[key] = props.newItemData[key];
          }
        });
        if (result.emails && !/.+@.+\..+/i.test(result.emails)) {
          props.functionCheckTime('Error', 'Невалидный адрес электронной почты');
          return;
        }
        const createItem = await props.service.createItem(result);
        props.setShowCreateForm(false);
        props.createActionModal({ ...createItem.data, id: createItem.data._id }, props.nameForm);
        const itemList = await props.service.getItems();
        props.setPayerList(itemList.data);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // действие создает новую сущность для привязки
    createLinkedItem: props => async () => {
      try {
        const result = await props.linkedService.createItem(props.linkedServiceInputState);
        if (result.status === 200) {
          const newItemData = { ...props.newItemData };
          const entity = props.linkedService.getApiName();
          newItemData[entity] = [...newItemData[entity], result.data];
          props.setNewItemData(newItemData);
          props.setEdit(false);
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // обновляет данные
    updateAction: props => async () => {
      try {
        if (props.inputs.emails && !/.+@.+\..+/i.test(props.inputs.emails)) {
          props.functionCheckTime('Error', 'Невалидный адрес электронной почты');
          return;
        }
        await props.service.updateItem(props.blockData.id, props.inputs);
        const itemList = await props.service.getItems();
        props.setPayerList(itemList.data);
        props.skip();
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // ищет элементы для связки
    searchLinkedItems: props => async (search) => {
      try {
        const result = await props.linkedService.searchItem({ search });
        props.setItemsForLink(result);
      } catch (error) {
        console.log('error', error);
      }
    },
  }),

  withHandlers({
    getDirectoryNameFromUrl: props => () => props.location.pathname
      .split('/')
      .filter(el => el !== '/' && el !== '')
      .pop(),
    loadDirectoryItems: props => async (directory) => {
      props.secondaryBarItems.forEach(item => item.url === directory && props.setActiveSecondarySidebarItem(item));
      props.setShowCreateForm(false);
      props.toggle(false);
      props.setIsLoading(true);
      props.setHasMore(true);
      props.setPopupVisible(false);
      props.setActiveID('');
      props.setToRemove(null);
      props.setBlockData({});
      props.setEdit(false);
      props.setService(null);
      props.setLinkedService(null);
      props.setItemsForLink([]);
      props.setForLinkTitle(null);
      props.setLinkedServiceForm({});
      props.setLinkedServiceInputState({});
      props.setNewItemData({});
      props.setInputs({});

      try {
        if (directory in directoryServiceMap) {
          const result = await directoryServiceMap[directory].getItems();
          props.initData(directoryServiceMap[directory], result.data, result.records);
        } else {
          const result = await directoryServiceMap.payers.getItems();
          props.initData(directoryServiceMap.payers, result.data, result.records);
          props.secondaryBarItems.forEach(item => item.url === 'payers' && props.setActiveSecondarySidebarItem(item));
        }
      } catch (error) {
        console.log('error', error);
      }
      props.setIsLoading(false);
    },
  }),

  lifecycle({
    async componentDidMount() {
      try {
        //   console.log('s', this.props.service);
        this.props.openFormForCreate();
      } catch (error) {
        console.log('error', error);
      }
    },
    componentWillUnmount() {
      if (this.listener) {
        this.listener();
      }
    },
  }),
)(ModaleWindowPayerInfoView);
