import styled from 'styled-components';

import { base } from '../../../styles';

export const TimeZoneContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${base * 0.3}px 0;
  width: 50%;
  box-sizing: border-box;
  padding-right: 100px;
`;

export const CityName = styled.span`
  color: #747474;
`;

export const CityTime = styled.span`
  color: #000;
  font-weight: 700;
`;
