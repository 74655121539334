import * as actionTypes from '../actions/actionTypes';

const initialState = {
  activeItem: null,
  activeSecondaryItem: null,
  secondaryBarItems: [],
  checkStatusPopup: {
    statusCheck: '',
    messageBox: '',
    statusTime: false,
  },
  needPadding: false,
  expand: false,
  showNewRequest: true,
  toggleDrop: false,
  toggleLoad: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.payload,
      };
    case actionTypes.SET_ACTIVE_SECONDARY_SIDEBAR_ITEM:
      return {
        ...state,
        activeSecondaryItem: action.payload,
      };
    case actionTypes.SET_SECONDARY_BAR_ITEMS:
      return {
        ...state,
        secondaryBarItems: action.payload,
      };
    case actionTypes.CHECK_STATUS_POPUP:
      return {
        ...state,
        checkStatusPopup: action.payload,
      };
    case actionTypes.NEED_PADDING:
      return {
        ...state,
        needPadding: action.payload,
      };
    case actionTypes.SET_EXPAND:
      return {
        ...state,
        expand: action.payload,
      };
    case actionTypes.SHOW_NEW_REQUEST:
      return {
        ...state,
        showNewRequest: action.payload,
      };
    case actionTypes.TOGGLE_DROP:
      return {
        ...state,
        toggleDrop: action.payload,
      };
    case actionTypes.TOGGLE_LOAD:
      return {
        ...state,
        toggleLoad: action.payload,
      };
    default:
      return state;
  }
}
