import {
  compose,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { saveUserAccesses } from '../../store/actions/auth';

import RequestFormView from './RequestFormView';

import { directoryServiceMap, UserService } from '../../services';

import {
  checkStatusPopup,
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
} from '../../store/actions/dashboard';

import { REQUEST_FIELDS } from '../../constants';

const containerDeparts = [];
const showErrorsFields = (fieldsWithErrors, fieldsValue, functionCheckTime) => {
  const requestFields = {};
  REQUEST_FIELDS.forEach(item => item.items.forEach(elem => requestFields[elem.name] = elem.title));
  fieldsValue.payers.forEach((item, index) => requestFields[`payers${item.id}`] = `Плательщик ${index + 1}`);
  const listFieldsErrors = fieldsWithErrors.map(elem => requestFields[elem]);

  const element = document.querySelector(`#${fieldsWithErrors[0]}`);
  if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' });

  functionCheckTime('Error', `Есть неверно заполненные поля: ${listFieldsErrors.join(', ')}`);
};

export default compose(
  connect(
    state => ({
      user: state.auth.currentUser,
      checkStatus: state.dashboard.checkStatusPopup,
      activeSidebarItem: state.dashboard.activeItem,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      access: state.auth.userAccesses,
    }),
    dispatch => ({
      saveUserAccesses: data => dispatch(saveUserAccesses(data)),
      checkStatusPopup: item => dispatch(checkStatusPopup(item)),
      setSecondaryBarItems: items => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: item => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: item => dispatch(setActiveSecondarySidebarItem(item)),
      setActiveSecondaryItem: item => dispatch(setActiveSecondarySidebarItem(item)),
    }),
  ),
  withRouter,
  withState('state', 'setState', ''),
  withState('intervalRequest', 'setIntervalRequest', null),
  withState('intervalCreateRequest', 'setIntervalCreateRequest', null),
  withState('stateClaim', 'setStateClaim', 'Предварительное'),
  withState('showAutocompleteValues', 'setShowAutocompleteValues', {}),
  withState('statusLoadCalendar', 'setStatusLoadCalendar', false),
  withState('statusArrivalCalendar', 'setStatusArrivalCalendar', false),
  withState('statusIssueCalendar', 'setStatusIssueCalendar', false),
  withState('statusCustomerAccountDateCalendar', 'setStatusCustomerAccountDateCalendar', false),
  withState('statusPlannedIssueDateCalendar', 'setStatusPlannedIssueDateCalendar', false),
  withState('paymentDateCalendar', 'setPaymentDateCalendar', false),
  withState('plannedDispatchDateCalendar', 'setPlannedDispatchDateCalendar', false),
  withState('actualDispatchDateCalendar', 'setActualDispatchDateCalendar', false),
  withState('toggleComp', 'setToggleComp', false),
  withState('amountComp', 'setAmountComp', {}),
  withState('statusWindow', 'setStatusWindow', false),

  // устанавливае какой сервис будет текущим
  withState('service', 'setService', null),

  // хуй знает)))
  withState('showCreateForm', 'setShowCreateForm', false),
  withState('hasMore', 'setHasMore', true),

  // активный таб в сайдбаре. Нужно когда у сущности есть привязки
  withState('activeID', 'setActiveID', ''),

  // содержит в себе данные для отображения в правом сайдбаре
  // инпуты, чекбоксы, линки на другие сущности
  withState('blockData', 'setBlockData', {}),


  // опять, когда у сущности есть привязки,
  // то нам надо знать с какой сущностью мы связаны
  withState('linkedService', 'setLinkedService', null),


  // список существующих сущностей
  withState('itemsForLink', 'setItemsForLink', []),

  // структура формы сущности для привязки
  withState('linkedServiceForm', 'setLinkedServiceForm', {}),

  // когда в карточке создаем новую сущность для привязке,
  // то здесь мы храним значения инпутов которые ввели
  withState('linkedServiceInputState', 'setLinkedServiceInputState', {}),

  // следим за инпутами в новой карточке
  withState('newItemData', 'setNewItemData', {}),

  // следим за инпутами в карточке с данными
  // бля, зачем выше тогда отдельный для новой?
  withState('inputs', 'setInputs', {}),

  withState('nameForm', 'setNameForm', ''),

  withState('pathname', 'setPathName', false),

  withState('activeDatePaymentField', 'setActiveDatePaymentField', null),
  withState('hasCustomerservice', 'setHasCustomerservice', false),
  withState('hasShipmentdepartment', 'setHasShipmentdepartment', false),
  withState('hasAutodepartment', 'setHasAutodepartment', false),
  withState('hasDocumentdepartment', 'setHasDocumentdepartment', false),
  withState('hasFinancialdepartment', 'setHasFinancialdepartment', false),
  withState('hasBillnumbers', 'setHasBillnumbers', false),
  withState('buhAmountMoreDateCalendar', 'setBuhAmountMoreDateCalendar', false),
  withState('activeDateBuhAmountMoreField', 'setActiveDateBuhAmountMoreField', null),

  withHandlers({
    functionCheckTime: props => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    convertCalendarDate: () => date => {
      if (!date || date.indexOf('_') !== -1) {
        return moment();
      } else {
        return moment(date);
      }
    },
    calendaeSelectHandler: props => (field, value, id, type) => {
      if (!value) return;
      if (field !== 'date') {
        props.changeField(field, value.format('YYYY-MM-DD'), 'input-date');
      } else {
        props.changePaymentFields(field, value.format('YYYY-MM-DD'), id, type);
      }
      switch (field) {
        case 'dateLoad':
          props.setStatusLoadCalendar(false);
          break;
        case 'plannedIssueDate':
          props.setStatusPlannedIssueDateCalendar(false);
          break;
        case 'dateArrival':
          props.setStatusArrivalCalendar(false);
          break;
        case 'dateIssue':
          props.setStatusIssueCalendar(false);
          break;
        case 'customerAccountDate':
          props.setStatusCustomerAccountDateCalendar(false);
          break;
        case 'date':
          props.setPaymentDateCalendar(false);
          break;
        case 'plannedDispatchDate':
          props.setPlannedDispatchDateCalendar(false);
          break;
        case 'actualDateDispatch':
          props.setActualDispatchDateCalendar(false);
          break;
        default:
      }
    },
  }),
  withHandlers({
    update: props => () => {
      const saveFields = {};
      saveFields.state = (props.state !== '') ? props.state : 'Предварительное';
      Object.keys(props.fieldsValue).forEach(key => {
        if (props.fieldsValue[key].name === null || key === 'rating') return;
        switch (key) {
          case 'payer':
          case 'consignee':
          case 'shipper':
          case 'railCarrier':
          case 'agentAutoLoad':
          case 'agentAutoCall':
          case 'exportAgentEurosib':
          case 'ownerContainer':
          case 'company':
          case 'companyMovers':
          case 'addressLoading':
          case 'addressUnloading':
          case 'stationDeparture':
          case 'stationDestination':
          case 'driver':
          case 'car':
          case 'trailer':
          case 'terminalStaging':
          case 'manager':
          case 'loadActual':
          case 'loadDocumented':
          case 'warmingCompany':
          case 'companyNameForwarder':
          case 'companyMoversUnloading':
          case 'companyMoversLoading':
          case 'destinationCity':
            if ('id' in props.fieldsValue[key]) {
              if (props.fieldsValue[key].name === '') {
               saveFields[key] = '';
                return;
             };
              saveFields[key] = props.fieldsValue[key].id;
            };
            break;
          case 'dateLoad':
          case 'dateArrival':
          case 'dateIssue':
          case 'customerAccountDate':
          case 'plannedIssueDate':
          case 'plannedDispatchDate':
          case 'actualDateDispatch':
            if (props.fieldsValue[key].name) {
              if (props.fieldsValue[key].name.indexOf('-') < 4) {
                saveFields[key] = props.fieldsValue[key].name.split('-').reverse().join('-');
              } else {
                saveFields[key] = props.fieldsValue[key].name;
              };
            }
            else {
              saveFields[key] = "";
            }
            break;
          case 'payers':
            const data = props.fieldsValue[key]
              .map(item => item.name ? item.data : '')
              .filter(item => item !== '');
            saveFields[key] = data;
            break;
          case 'numberContainer':
            if (props.fieldsValue[key].name.split('_').length !== 12) {
              saveFields[key] = props.fieldsValue[key].name;
            } else {
              saveFields[key] = '';
            }
            break;
          case 'buh_amountMore':
            saveFields[key] = props.fieldsValue[key].filter(item => item.amount && item.amount > 0);
            break;
          default:
            saveFields[key] = props.fieldsValue[key].name;
          break;
        };
      })

      if (props.fieldsWithErrors.length) {
        showErrorsFields(props.fieldsWithErrors, props.fieldsValue, props.functionCheckTime);
        return;
      }

      props.updateHandler(saveFields)
        .then(() => props.functionCheckTime('Success', 'Данные обновлены'))
        .catch(error => props.functionCheckTime('Error', error.message));
    },
  }),
  withHandlers({
    returnToTheLastPage: props => () => {
      props.history.push('/dashboard/requests/');
    },
    save: props => (autoSave = false) => {
      const saveFields = {};
      saveFields.state = (props.state !== '') ? props.state : 'Новая';
      Object.keys(props.fieldsValue).forEach((key) => {
        if (Object.entries(props.fieldsValue[key]).length === 0) return;
        switch (key) {
          case 'payer':
          case 'consignee':
          case 'shipper':
          case 'railCarrier':
          case 'agentAutoLoad':
          case 'agentAutoCall':
          case 'exportAgentEurosib':
          case 'ownerContainer':
          case 'company':
          case 'companyMovers':
          case 'addressLoading':
          case 'addressUnloading':
          case 'stationDeparture':
          case 'stationDestination':
          case 'driver':
          case 'car':
          case 'trailer':
          case 'terminalStaging':
          case 'manager':
          case 'loadActual':
          case 'loadDocumented':
          case 'warmingCompany':
          case 'companyNameForwarder':
          case 'companyMoversUnloading':
          case 'companyMoversLoading':
          case 'destinationCity':
            if ('id' in props.fieldsValue[key]) {
              saveFields[key] = props.fieldsValue[key].id;
            };
            break;
          case 'dateLoad':
          case 'dateArrival':
          case 'dateIssue':
          case 'customerAccountDate':
          case 'plannedIssueDate':
          case 'plannedDispatchDate':
          case 'actualDateDispatch':
            if (props.fieldsValue[key].name) {
              if (props.fieldsValue[key].name.indexOf('-') < 4) {
                saveFields[key] = props.fieldsValue[key].name.split('-').reverse().join('-');
              } else {
                saveFields[key] = props.fieldsValue[key].name;
              };
            };
            break;
          case 'payers':
            const data = props.fieldsValue[key]
              .map(item => item.name ? item.data : '')
              .filter(item => item !== '');
            saveFields[key] = data;
            break;
          case 'numberContainer':
            if (props.fieldsValue[key].name.split('_').length !== 12) {
              saveFields[key] = props.fieldsValue[key].name;
            }
            break;
          case 'buh_amountMore':
            saveFields[key] = props.fieldsValue[key].filter(item => item.amount && item.amount > 0);
            break;
          default:
            if (props.fieldsValue[key].name !== '') {
              saveFields[key] = props.fieldsValue[key].name;
            };
            break;
        };
      });

      if (props.fieldsWithErrors.length) {
        showErrorsFields(props.fieldsWithErrors, props.fieldsValue, props.functionCheckTime);
        return;
      }

      props.saveHandler(saveFields, autoSave)
        .then(() => {
          clearInterval(props.intervalCreateRequest);
          if (autoSave) {
            props.setIntervalRequest(setInterval(() => props.update(), 15000));
          };
          return props.functionCheckTime('Success', 'Успешно сохранено');
        })
        .catch(error => props.functionCheckTime('Error', error.message));
    },
    scrollEvent: props => () => {
      containerDeparts.forEach((item) => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        const hashDeparts = item.data.url.split('#');
        if (hashDeparts.length === 2) {
          const hash = hashDeparts.pop();
          const element = document.querySelector(`#${hash}`);
          if (element) {
            if (scrollTop + clientHeight / 2 > element.offsetTop) {
              props.setActiveSecondaryItem(item.data);
            }
          };
        };
      });
    },
    createFormModal: props => (service, name) => {
      props.setStatusWindow(true);
      props.setNameForm(name);
      props.setService(directoryServiceMap[service]);
    },
    scrollAndSetActiveSectionMouseAndKeyboard: props => (idDepartment, isScroll = false) => {
      containerDeparts.forEach((item) => {
        const hashDeparts = item.data.url.split('#');
        if (hashDeparts.length === 2) {
          const hash = hashDeparts.pop();
          if (idDepartment === hash) {
            const element = document.querySelector(`#${hash}`);
            if (isScroll) element.scrollIntoView({ block: 'start', behavior: 'smooth' });
            props.setActiveSecondaryItem(item.data);
          }
        };
      });
    },
  }),
  lifecycle({
    componentWillUnmount() {
      clearInterval(this.props.intervalRequest);
      clearInterval(this.props.intervalCreateRequest);
    },
    componentDidMount() {
      UserService.getRight()
        .then(item => {
          this.props.saveUserAccesses({
            directory: item.result.directories,
            requests: item.result.requests,
          });
        })
        .catch(error => this.props.functionCheckTime('Error', error.message));
      const pathname = this.props.location.pathname.split('/');
      if (pathname[3] === 'edit') {
        this.props.setIntervalRequest(setInterval(() => this.props.update(), 15000));
      } else if (pathname[3] === 'create') {
        this.props.setIntervalCreateRequest(setInterval(() => this.props.save(true), 60000));
      };

      if (this.props.values) {
        Object.keys(this.props.values).forEach((key) => {
          if (key !== 'state') {
            this.props.fieldsValue[key] = this.props.values[key];
          } else {
            this.props.setState(this.props.values[key]);
          };
        });
      };

      const requests = this.props.access.requests;

      const customerservice = REQUEST_FIELDS[0].items.map(item => item.name);
      const shipmentdepartment = REQUEST_FIELDS[1].items.map(item => item.name);
      const autodepartment = REQUEST_FIELDS[2].items.map(item => item.name);
      const documentdepartment = REQUEST_FIELDS[3].items.map(item => item.name);
      const financialdepartment = REQUEST_FIELDS[4].items.map(item => item.name);
      const billnumbers = REQUEST_FIELDS[5].items.map(item => item.name);

      const hasCustomerservice = customerservice.some(item => requests[item]);
      const hasShipmentdepartment = shipmentdepartment.some(item => requests[item]);
      const hasAutodepartment = autodepartment.some(item => requests[item]);
      const hasDocumentdepartment = documentdepartment.some(item => requests[item]);
      const hasFinancialdepartment = financialdepartment.some(item => requests[item]);
      const hasBillnumbers = billnumbers.some(item => requests[item]);

      this.props.setHasCustomerservice(hasCustomerservice);
      this.props.setHasShipmentdepartment(hasShipmentdepartment);
      this.props.setHasAutodepartment(hasAutodepartment);
      this.props.setHasDocumentdepartment(hasDocumentdepartment);
      this.props.setHasFinancialdepartment(hasFinancialdepartment);
      this.props.setHasBillnumbers(hasBillnumbers);

      let LIST_OF_DEPARTMENTS = [];

      if (hasCustomerservice) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1001',
          name: 'Отдел обслуживания клиентов',
          url: `${pathname[4]}#customerservice`,
        }];
      }
      if (hasShipmentdepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1002',
          name: 'Отдел отправки',
          url: `${pathname[4]}#shipmentdepartment`,
        }];
      }
      if (hasAutodepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1003',
          name: 'Автоотдел',
          url: `${pathname[4]}#autodepartment`,
        }];
      }
      if (hasDocumentdepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1004',
          name: 'Отдел вывоз в регионы/документы',
          url: `${pathname[4]}#documentdepartment`,
        }];
      }
      if (hasFinancialdepartment) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1005',
          name: 'Финансовый отдел',
          url: `${pathname[4]}#financialdepartment`,
        }];
      }
      if (hasBillnumbers) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1006',
          name: 'Номера счетов',
          url: `${pathname[4]}#billnumbers`,
        }];
      }
      if (this.props.access.directory.payments.findAll) {
        LIST_OF_DEPARTMENTS = [...LIST_OF_DEPARTMENTS, {
          id: '1007',
          name: 'Приход',
          url: `${pathname[4]}#coming`,
        }];
      }

      this.props.setSecondaryBarItems(LIST_OF_DEPARTMENTS);

      LIST_OF_DEPARTMENTS.forEach(item => {
        const hashDeparts = item.url.split('#');
        if (hashDeparts.length === 2) {
          const hash = hashDeparts.pop();
          const element = document.querySelector(`#${hash}`);
          if (element) {
            containerDeparts.push({
              data: item,
              lenghtTop: element.offsetTop,
            });
          };
        };
      });

      this.props.setActiveSecondarySidebarItem({
        id: '1001',
        name: 'Основная информация',
        url: `${pathname[4]}#basic`,
      });

      if (this.props.user.roleRightName === 'salesman' || this.props.user.roleRightName === 'transactionSalesman') {
        const { id, lastName, firstName } = this.props.user;
        setTimeout(() => {
          this.props.selectedFieldValue('manager', {
            id,
            name: `${firstName} ${lastName}`,
          });
        }, 2000);
      };
      if (this.props.user.roleRightName === 'admin'){
        this.props.setPathName(true);
      };
    },
    componentWillReceiveProps(nextProps) {
      if (this.props.fieldsValue.state.name !== nextProps.fieldsValue.state.name) {
        this.props.setStateClaim(nextProps.fieldsValue.state.name);
      };
    },
  }),
)(RequestFormView);
