import { createStore, applyMiddleware, compose } from 'redux';
// eslint-disable-next-line import/no-unresolved
import { persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-unresolved
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['dashboard'],
};

export default function configureStore() {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable no-underscore-dangle */

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
}
