import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 0 0;
`;

export const ContainerImg = styled.div`

`;

export const Img = styled.img`
  width: 12px;
`;
