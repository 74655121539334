import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from '../ApiService';

import AddressService from '../AddressService';

export default {
  getApiName() {
    return 'shippers';
  },

  getItems(p = 1, params) {
    return apiGet(`/shippers?page=${p}`, { params })
      .then(response => ({
        data: response.data.docs.map(item => ({
          id: item._id,
          ...item,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getItem(id) {
    return apiGet(`/shipper/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          counters: [],
        };
        const lengthAddresses = response.data.addresses.length;
        if (lengthAddresses > 0) {
          switch (lengthAddresses) {
            case 11:
              result.counters.push(`${lengthAddresses} адресов`);
              break;
            case 1:
            case Number(`${Math.floor(lengthAddresses / 10)}1`):
              result.counters.push(`${lengthAddresses} адрес`);
              break;
            case 2:
            case 3:
            case 4:
              result.counters.push(`${lengthAddresses} адреса`);
              break;
            default:
              result.counters.push(`${lengthAddresses} адресов`);
              break;
          }
        }
        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/shipper/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          relations: [{
            title: 'Адреса',
            name: 'addresses',
            items: null,
            linkedService: AddressService,
          }],
          inputs: [],
          checkboxes: [],
        };

        if (response.data.addresses.length > 0) {
          result.relations[0].items = response.data.addresses.map((address) => {
            if (!address) return null;
            return {
              id: address._id,
              name: address.title,
            };
          }).filter(item => item !== null);
        }

        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'addresses') {
      result = apiPut(`/shipper/${id}?deladdress=${itemId}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  addLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'addresses') {
      result = apiPut(`/shipper/${id}?addaddresses=["${itemId}"]`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  deleteItem(id) {
    return apiDelete(`/shipper/${id}`)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [],
      inputs: [
        {
          title: 'Наименование',
          name: 'name',
        },
        {
          title: 'Адрес',
          name: 'address',
        },
      ],
      checkboxes: [],
      canAddInput: {
        title: 'Адрес',
        name: 'address',
      },
    };
  },

  updateItem(id, body) {
    if (Object.prototype.hasOwnProperty.call(body, 'createaddress')) {
      return apiPut(`/shipper/${id}?createaddress=${body.createaddress}`)
        .then(response => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }
    return apiPut(`/shipper/${id}`, body)
      .then(response => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/shipper', body)
      .then(response => ({
        status: response.status,
        data: {
          id: response.data._id,
          name: response.data.name,
          addresses: response.data.addresses,
        },
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    const { search = '', limit = 10 } = body;
    return apiGet(`/shippers?search=${search}&records=${limit}`)
      .then(response => response.data.docs.map(item => ({
        id: item._id,
        ...item,
      })))
      .catch(error => console.log('Not Search', error));
  },
};
